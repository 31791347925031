.a-fueru-madani{
    position:relative;
    max-width: 800px;
    margin: auto;
    &:after{content:"";clear:both;display:block;}
    &__present,
    &__arrow,
    &__future{
        width: 33.3%;
        float: left;
        @include query(700){
            width: auto;
            float: none;
        }
    }
    &__arrow{
        height:214px;
        position: relative;
        img{
            @include img_center;
            @include query(700){
                transform: rotate(90deg) scale(.8);
            }
        }
        &-txt{
            color: #fff;
            font-size: 1.54em;
            line-height: 1;
            height: 1em;
            text-shadow:
            2px 0 2px #fe6620,
            -2px 0 2px #fe6620,
            0 2px 2px #fe6620,
            0 -2px 2px #fe6620,
            2px 2px 2px #fe6620,
            -2px -2px 2px #fe6620,
            2px -2px 2px #fe6620,
            -2px 2px 2px #fe6620;
            @include img_center;
            @include query(700){
                transform: translateY(-1em);
            }
        }
    }
    &__madani1{
        font-size: 0;
        height:214px;
        @include query(700){
            height:154px;
        }
        position: relative;
        img{@include img_center;}
    }
    &__madani500{
        width: 210px;
        height: 217px;
        overflow: hidden;
        margin: auto;
        font-size: 0;
        img{
            backface-visibility: hidden;
            transition: margin-top 3s;
            margin-top: 100%;
        }
        &.js-animation img{
            margin-top: 0;
        }
    }
    &__caption{
        font-size: 1.71em;
        margin-top: 1em;
        margin-bottom: 0;
        &--count{
            font-size: 2.8em;
            text-align: right;
            padding-right: 20%;
            @include query(1060){
                padding-right: 25%;
            }
            margin-bottom: 0;
            @include query(700){
                width: 210px;
                padding-right:1.4em;
            }
            @include query(375){
                padding-right:1.6em;
            }
        }
    }
}
