.navi{
    width: 848px;
    margin: 0 auto 90/1000*100+%;
    line-height: 1.6;
    &:after{content:"";clear:both;display:block;}
    @include query(1060){width: 848/1000*100+%;}
    @include query(660){width: 100%;}
    @include query(560){margin-bottom: 90px;}
    &__box{
        /* mixin */
        @mixin __box{
            width: 395px;
            float: left;
            margin-bottom: 40px;
            &:nth-child(odd){margin-right: 58/1000*100+%;}
            @include query(1060){
                width: 395/848*100+%;
                margin-bottom: 40/848*100+%;
                &:nth-child(odd){margin-right: 58/848*100+%;}
            }
            @include query(560){
                float: none;
                width: 100%;
                margin-bottom: 3em;
            }
        }
        @include __box;
        &-coming{
            @include __box;
            position: relative;
            &:after{
                content: "";
                display: block;
                width: 150/395*100+%;
                height: 150/395*100+%;
                background-image: url(../svg/coming.svg);
                background-size: contain;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 1;
            }
        }
        &_img{
            width: 100%;
            height: 280px;
            margin-bottom: 1em;
            position: relative;
            .navi__box-coming &{opacity: .5;}
            @include query(1060){
                height: auto;
                &:before{
                    content: "";
                    display: block;
                    padding-top: 280/350*100+%;
                }
            }
            @include query(660){
                height: 280/848*100+%;
                &:before{display: none;}
            }
            @include query(560){margin-bottom: 1em;}
            img{
                @include img_center;
                @include query(660){position: relative;}
                @include query(560){width: 75%;}
            }
        }
        &_txt{
            margin-bottom: 1em;
            .navi__box-coming &{opacity: .5;}
            &-1line{
                margin-bottom: 1em;
                line-height: 1;
                padding-top: 1em;
                .navi__box-coming &{opacity: .5;}
                @include query(560){
                    line-height: auto;
                    margin-bottom: 0;
                    padding-top: 0;
                }
            }
        }
    }
}
