.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-nomiact{
        line-height: 0;
        font-size: 0;
        @media (max-width: 687px){display: none;}
        position: relative;
        &__base{}
        &__nomi{
            position: absolute;
            top: 48%;
            left: 17%;
            display: inline-block;
            width: 12.8%;
            animation: nomiact 1s steps(1) infinite normal;
        }
        &__dani{
            position: absolute;
            top: 66%;
            left: 68%;
            display: inline-block;
            width: 8.5%;
            animation: daniact 4s steps(1) infinite normal;
        }
        &__leaf{
            position: absolute;
            top: 84%;
            left: 64.2%;
            display: inline-block;
            width: 17.7%;
        }
    }
    &-nomiactsp{
        line-height: 0;
        font-size: 0;
        @media (min-width: 687px){display: none;}
        display: block;
        position: relative;
        &__base{}
        &__nomi{
            position: absolute;
            top: 48%;
            left: 21%;
            display: inline-block;
            width: 12.8%;
            animation: nomiact 1s steps(1) infinite normal;
        }
        &__dani{
            position: absolute;
            top: 67.6%;
            left: 72.6%;
            display: inline-block;
            width: 10.1%;
            animation: daniact 4s steps(1) infinite normal;
        }
        &__leaf{
            position: absolute;
            top: 84.4%;
            left: 73.9%;
            display: inline-block;
            width: 20.3%;
        }
    }
}

@keyframes nomiact{
    50%{transform: translate(0,0) rotate(0deg) scale(-1, 1);}
}
@keyframes daniact{
    50%{transform: translate(20%,0) rotate(0deg) scale(-1, 1);}
}
