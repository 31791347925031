.totop{
    display: block;
    background: url(../svg/totop_dog.svg) no-repeat;
    width: 140px;
    height: 140px;
    position: fixed;
    right: -140px;
    bottom: 0;
    z-index: 1;
    transition: bottom 0 linear;
    background-position: 0 0;
    backface-visibility: hidden;
    transition: bottom 25ms ease;
    @include query(768){
        width: 100px;
        height: 100px;
        right: -100px;
        background-size: cover;
    }
    @include query(560){
        width: 60px;
        height: 60px;
        right: -60px;
        transition: right 500ms ease;
    }
    &-walk{
        animation: walk 0.3s steps(2) infinite;
        transition: bottom 1000ms ease;
        @include query(768){animation: walk2 0.2s steps(2) infinite;}
        @include query(560){
            animation: walk3 0.3s steps(2) infinite;
            transition: right 1000ms ease;
        }
    }
    &-on{
        right: 30px;
        @include query(768){right: 10px;}
        @include query(560){right: 5px;}
    }
    &:after{
        content: "";
        display: block;
        width: 43px;
        height: 43px;
        background-image: url(../svg/totop_arrow.svg);
        background-size: contain;
        position: absolute;
        top: -30%;
        left: 50%;
        transform: translateX(-50%);
        @include query(768){width: 33px; height: 33px;}
        @include query(560){width: 24px; height: 24px; top: -10%; left: 70%;}
    }
}

@keyframes walk{
  to {background-position: 0 -280px;}
}
@keyframes walk2{
  to {background-position: 0 -200px;}
}
@keyframes walk3{
  to {background-position: 0 -120px;}
}
