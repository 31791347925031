.box{
    margin-bottom: 60/$baseSize+em;
    /* mixin */
    @mixin __head{
        font-size: 25/$baseSize+em;
        line-height: 1.2;
        color: #fff;
        padding: 1em;
        background-color: $keyColor_orng;
        border-radius: 1em 1em 0 0;
        width: 465/1000*100+%;
        margin: 100/$baseSize+em auto 0;
        .box+.box &{margin: 140/$baseSize+em auto 0;}
        span{display: inline-block;}
        @include query(768){
            width: 70%;
            margin: 100/$baseSize+em auto 0;
        }
        @include query(700){border-radius: 0.75em 0.75em 0 0;}
    }
    &__head{
        @include __head;
        &-ruby{
            @include __head;
            padding: 1.25em 1em 0.75em;
        }
    }
    /* mixin */
    @mixin __area{
        padding: 3em 4em;
        background-color: #fff;
        border-radius: 2.5em;
        position: relative;
        @include query(768){padding: 3em;}
        @include query(700){border-radius: 1.5em;}
    }
    &__area{
        @include __area;
        &-medicine{
            @include __area;
            padding: 3em 4em 3em 5em;
            @include query(768){padding: 3em 3em 3em 4em;}
            @include query(700){padding: 3em;}
            @include query(560){padding: 2.5em;}
            @include query(375){padding: 1.5em;}
        }
        &-form{
            @include __area;
            padding: 3em 5.5em;
            @include query(560){padding: 2em 1.5em;}
        }
        &-confirm{
            @include __area;
            padding: 3em 5.5em;
            @include query(560){padding: 2em 1.5em;}
        }
        &-thanx{
            @include __area;
            padding: 7em 5.5em 3em;
            @include query(768){padding: 5em 2em 2em;}
        }
        &_multicol{
            &:after{content:"";clear:both;display:block;}
            &-addrhospital{
                font-size: 0;
                &:after{content:"";clear:both;display:block;}
            }
        }
        &_patient{
            position: absolute;
            top: 1.5em;
            right: 1.5em;
            line-height: 1;
            width: 30%;
            @include query(800){width: 15%;}
            @include query(700){
                position: relative;
                text-align: center;
                top: auto;
                right: auto;
                width: auto;
                margin-bottom: 1em;
                overflow: hidden;
                &:after{content:"";clear:both;display:block;}
                ul{
                    @include query(700){
                        float: left;
                        left: 50%;
                        position: relative;
                    }
                }
            }
            li{
                float: right;
                margin-bottom: 0.75em;
                @include query(700){
                    float: left;
                    left: -50%;
                    position: relative;
                }
                &~li{
                    margin-right: 0.5em;
                    @include query(800){margin-right: 0;}
                    @include query(700){
                        float: left;
                        margin-left: 0.5em;
                        left: -50%;
                        position: relative;
                    }
                }
            }
            &_icon{
                display: block;
                width: 82px;
                height: 82px;
                background-color: $keyColor_yel;
                border-radius: 50%;
                position: relative;
                img{@include img_center;}
                @include query(768){
                    width: 62px;
                    height: 62px;
                    position: relative;
                    img{width: 65%;}
                }
                @include query(700){
                    width: 52px;
                    height: 52px;
                }
            }
            &_txt{font-size: 12/$baseSize+em;}
        }
        &_content{
            &-2column{
                height: 100%;
                &:after{content:"";clear:both;display:block;}
            }
            &_img{
                padding-top: 2em;
                margin-bottom: 3em;
                @include query(768){
                    img{
                        max-height: 200px;
                        &.madani_bkyoki_adj1{width: 70%;}
                    }
                }
                @include query(700){
                    margin-bottom: 2em;
                    img{
                        &.madani_bkyoki_adj1{width: 75%;}
                        &.madani_bkyoki_adj2{width: 40%;}
                    }
                }
                &-left{
                    width: 40%;
                    float: left;
                    @include query(700){
                        width: 550/1000*100+%;
                        float: none;
                        margin: 0 auto 2em;
                    }
                }
                &-right{
                    width: 40%;
                    float: right;
                    @include query(700){
                        width: 700/1000*100+%;
                        float: none;
                        margin: auto;
                    }
                    .adj{
                        margin-top: 15%;
                        @include query(700){margin: auto;}
                    }
                }
            }
            &_headdeco{
                width: 50%;
                letter-spacing: 0;
                @include query(700){
                    width: 100%;
                }
                @include query(700){width: 100%;}
            }
            /* -- mixin -- */
            @mixin _head{
                margin: 0 auto 1em;
                span{
                    display: inline-block;
                    color: $keyColor_orng;
                    border: 3px solid $keyColor_orng;
                    border-radius: 2em;
                    margin: auto;
                    padding: 0.25em 1em;
                    width: 100%;
                    @include query(768){
                        width: 100%;
                        min-width: auto;
                    }
                }
            }
            @mixin _head-left-right{
                width: 50%;
                margin: 0 0 2em 0;
                @include query(700){
                    float: none;
                    width: auto;
                    margin: 0 auto 1em;
                }
            }
            &_head{
                @include _head;
                width: 360/1000*100+%;
                min-width: 360px;
                @include query(700){
                    width: auto;
                    min-width: auto;
                }
                &-left{
                    @include _head;
                    @include _head-left-right;
                    span{max-width: 298px;}
                    float: left;
                }
                &-right{
                    @include _head;
                    @include _head-left-right;
                    float: right;
                }
            }
            /* -- mixin -- */
            @mixin -left-right{
                width: 50%;
                text-align: justify;
                margin-bottom: 1em;
                p{
                    margin: 0 auto 1.5em;
                    &:last-child{margin: auto;}
                }
                @include query(700){
                    float: none;
                    width: auto;
                }
            }
            &_txt{
                margin-bottom: 2em;
                @include query(700){
                    text-align: justify;
                    .pc{display: none;}
                }
                &-left{
                    float: left;
                    @include -left-right;
                }
                &-right{
                    float: right;
                    @include -left-right;
                }
            }
            &_list{
                text-align: left;
                padding-left: 1em;
                text-indent: -1em;
                line-height: 1.6;
                margin-bottom: 1em;
                li{
                    margin-bottom: 0.5em;
                    &:before{
                        content: "・";
                        font-feature-settings: normal;
                    }
                }
            }
        }
    }
}
