.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-nomilife{
        @include fig;
        position: relative;
        &__img{
            display: inline-block;
            width: 76.1%;
            margin: auto;
            img{-webkit-tap-highlight-color:rgba(0,0,0,0);}
            area{outline: none;}
            @include query(560){width: 100%;}
        }
        &__info{
            font-size: 15/$baseSize+em;
            position: absolute;
            top: 0;
            right: 0;
            padding: 1em 2em;
            border: 2px dotted $keyColor_gray;
            border-radius: 1em;
            img{
                vertical-align: middle;
                margin-right: 0.25em;
                width: 2em;
            }
            @include query(560){
                position: relative;
                display: inline-block;
                margin: 2em auto 0;
                br{display: none;}
            }
        }
    }
}
