.menu{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &__btn_wrap{
        max-width: 1280px;
        margin: auto;
        padding: 35px 100px 0 0;
        &:after{content:"";clear:both;display:block;}
        @include query(1060){padding: 25px 40px 0 0;}
        @include query(560){padding: 20px 30px 0 0;}
        @include query(375){padding: 15px 20px 0 0;}
    }
    &__btn{
        float: right;
        width: 60px;
        height: 60px;
        border-radius: 20%;
        background-color: $keyColor_orng;
        position: relative;
        transition: background-color 200ms ease;
        z-index: 3;
        &:hover{background-color: $keyColor_orng_light;}
        cursor: pointer;
        @include query(1060){width: 50px;height: 50px;}
        @include query(560){width: 40px;height: 40px;}
        &_line{
            display: inline-block;
            position: absolute;
            left: 50%;
            margin-left: -30%;
            width: 60%;
            height: 4px;
            background-color: #fff;
            border-radius: 2px;
            transition: all 300ms ease;
            &:nth-of-type(1){top: 14px;}
            &:nth-of-type(2){top: 28px;}
            &:nth-of-type(3){bottom: 14px;}
            @include query(1060){
                height: 3px;
                &:nth-of-type(1){top: 12px;}
                &:nth-of-type(2){top: 24px;}
                &:nth-of-type(3){bottom: 12px;}
            }
            @include query(560){
                height: 3px;
                &:nth-of-type(1){top: 9px;}
                &:nth-of-type(2){top: 18px;}
                &:nth-of-type(3){bottom: 10px;}
            }
            //btn-on
            .menu__btn-on &{
                &:nth-of-type(1){transform: translateY(14px) rotate(-45deg);}
                &:nth-of-type(2){opacity: 0;}
                &:nth-of-type(3){transform: translateY(-14px) rotate(45deg);}
                @include query(1060){
                    &:nth-of-type(1){transform: translateY(12px) rotate(-45deg);}
                    &:nth-of-type(3){transform: translateY(-12px) rotate(45deg);}
                }
                @include query(560){
                    &:nth-of-type(1){transform: translateY(9px) rotate(-45deg);}
                    &:nth-of-type(3){transform: translateY(-9px) rotate(45deg);}
                }
            }
        }
    }
    .sp{
      @include query(670){
      display: none;
    }
    }
    &__item{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        height: 100vh;
        background-color: rgba(255,255,255,.85);
        line-height: 1.4;
        z-index: 2;
        display: none;
        overflow-y: auto;
        .inner__menu{
        float: none;
        margin: auto;
        width: 100%;
          @include query(1060){
            float: none;
            margin: auto;
          }
          @include query(670){
            float: none;
            margin: auto;
            position: inherit;
          }
          @include query(560){
            float: none;
            margin: auto;
            position: inherit;
          }
        }

        &-bnr{
            display: inline-block;
            width: 294px;
            float: right;
            margin: -1.25em 1em 0 0;
            position: relative;
            z-index: 1;
            a{
                transition: opacity 200ms ease;
                &:hover{opacity: .8;}
            }
            img{width: 100%;}
            span{
                color: $keyColor_navy;
                display: block;
                font-size: 12/$baseSize+em;
                letter-spacing: 0;
            }
            @include query(1060){
                width: 30%;
                min-width: 210px;
            }
            @include query(670){
                position: absolute;
                right: 25px;
                bottom: -80px;
                float: none;
            }
            @include query(560){
                right: 15px;
                bottom: -75px;
            }
            @include query(375){
                right: 10px;
                bottom: -120%;
                width: 55%;
                min-width: auto;
                span{
                    line-height: 1.5;
                    font-size: 13/$baseSize+em;
                }
            }
        }
        &-page{
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 52%;
            transform: translate(-50%, -50%);
            &>li{margin-bottom: 1.25em;}
            a{
                color: $keyColor_orng;
                font-size: 24/$baseSize+em;
                &:hover{color: $keyColor_orng_light;}
            }
        }
        &-sns{
            margin: 4em 0 0 30px;
            @include query(1060){margin: 4em 0 0 0;}
            @include query(768){margin: 3em 0 0 0;}
            li{
                display: inline-block;
                margin: 0 14px;
                @include query(1060){
                    width: 60px;
                    margin: 0 10px;
                }
                @include query(768){
                    width: 45px;
                    margin: 0 7px;
                }
                @include query(375){
                    width: 40px;
                    margin: 0 3px;
                }
            }
            &-line{
                transform: scale(1.75) translate(-8px,-3px);
                @include query(1060){transform: scale(1.5) translate(-3px,-1px);}
                @include query(768){transform: scale(1.15) translate(-3px,4px);}
                @include query(375){transform: scale(1.03) translate(-3px,5px);}
            }
        }
    }
}
