.links{
    margin-bottom: 3em;
    padding: 3em;
    background-color: #fff;
    border-radius: 2.5em;
    text-align: justify;
    @include query(560){
        padding: 2em;
        border-radius: 1.5em;
    }
    &__item{
        padding: 2em 0;
        &:first-of-type{padding: 0 0 2em;}
        border-bottom: 4px dotted $keyColor_gray;
        &:last-of-type{border-bottom: none;}
        &:after{content:"";clear:both;display:block;}
        @include query(560){
            border-bottom: 2px dotted $keyColor_gray;
        }
        &_img{
            line-height: 1;
            width: 30%;
            float: left;
            text-align: center;
            a{
                transition: opacity 200ms ease;
                &:hover{opacity: .8;}
            }
            @include query(560){
                width: 60%;
                float: none;
                margin: 0 auto 1.5em;
            }
            @include query(375){
                width: 80%;
            }
            &-linkfree{
                text-align: center;
                margin: 3em auto;
                @include query(560){margin-bottom: 2em;}
            }
        }
        &_head{
            font-size: 18/$baseSize+em;
            width: 67.5%;
            float: right;
            line-height: 1.4;
            margin-bottom: 1em;
            span{display: inline-block;}
            @include query(560){
                width: 100%;
                float: none;
                text-align: center;
                font-size: 1.25em;
                margin-bottom: 0.75em;
            }
            &-linkfree{
                text-align: center;
                margin-bottom: 1em;
            }
        }
        /* mixin */
        @mixin _txt{
            font-size: 14/$baseSize+em;
            font-weight: normal;
        }
        &_txt{
            @include _txt;
            width: 67.5%;
            float: right;
            @include query(560){
                width: 100%;
                float: none;
                font-size: 1em;
            }
            &-linkfree{
                @include _txt;
                margin-bottom: 1em;
            }
        }
        &_code{
            &-linkfree{
                textarea{
                    color: $keyColor_gray;
                    width: 100%;
                    padding: 1em;
                    font-size: 16/$baseSize+em;
                    overflow: scroll;
                    word-break:break-all;
                }
            }
        }
    }
}
