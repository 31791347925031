@charset "UTF-8";
/* -- keyColor -- */
/* -- fontsize_base -- */
/* -- common_mixin -- */
/* -- webfont -- */
.comfortaa {
  font-family: 'Comfortaa', cursive;
  -webkit-text-stroke: 1px #776c66; }

/* -- import -- */
/* font-family */
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100; }

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200; }

body {
  font-family: "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif; }

/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: Verdana, Meiryo, sans-serif; } }

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  list-style: none;
  background-repeat: no-repeat;
  box-sizing: inherit;
  vertical-align: inherit; }

html, body {
  height: 100%; }
  html.menu-on, body.menu-on {
    overflow: hidden; }

body {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #776c66;
  font-feature-settings: "palt";
  background-image: url(../img/bg.png);
  background-repeat: repeat;
  background-position: center 50px;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffe613; }
  @media all and (max-width: 1060px) {
    body {
      font-size: 16px;
      background-size: 150px;
      background-position: center 50px; } }
  @media all and (max-width: 768px) {
    body {
      font-size: 14px; } }
  @media all and (max-width: 560px) {
    body {
      background-position: center 18px; } }
  @media all and (max-width: 375px) {
    body {
      font-size: 12px;
      background-size: 75px;
      background-position: center 34px; } }

a, a img, button {
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #776c66; }

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto; }

a:hover, button:hover {
  outline: none; }

a:disabled, button:disabled, input:disabled {
  pointer-events: none; }

@media all and (max-width: 1060px) {
  section {
    padding: 0 20px; } }

h2 {
  font-size: 1.31579em;
  color: #fff;
  line-height: 1;
  margin: 8em auto 2.5em;
  padding: 1em;
  width: 100%;
  background-color: #005366;
  border-radius: 2em; }
  .second h2 {
    max-width: 650px; }
  @media all and (max-width: 768px) {
    h2 {
      margin: 6em auto 2.5em; } }

p {
  margin: 0 auto 3em; }

strong {
  color: #ff671f; }

.medicine-prlink__midashi {
  color: #005366;
  font-size: 1em;
  margin: 8em auto 1em;
  line-height: 1.4; }
  .medicine-prlink__midashi span {
    display: inline-block; }

.btn {
  /* -- mixin -- */
  /* -- elements -- */ }
  .btn_link {
    color: #fff;
    font-weight: bold;
    font-size: 1em;
    display: inline-block;
    line-height: 1.2;
    height: 83px;
    padding: 1.5em 3.25em 1.5em 1.5em;
    background-color: #ff671f;
    border-radius: 1em;
    position: relative;
    transition: background-color 200ms ease; }
    @media all and (max-width: 1060px) {
      .btn_link {
        height: auto; } }
    .btn_link:hover {
      background-color: #ff854c; }
      .btn_link:hover:after {
        background-color: #337585; }
    .btn_link:after {
      content: "";
      display: block;
      width: 46px;
      height: 46px;
      background-color: #005366;
      border-radius: 25%;
      background-image: url(../svg/btn_link_arrow.svg);
      background-position: center center;
      position: absolute;
      top: 50%;
      right: 1em;
      margin-top: -23px;
      transition: background-color 200ms ease; }
      @media all and (max-width: 1060px) {
        .btn_link:after {
          width: 36px;
          height: 36px;
          margin-top: -18px;
          background-size: 30%; } }
      @media all and (max-width: 768px) {
        .btn_link:after {
          width: 26px;
          height: 26px;
          margin-top: -13px;
          background-size: 30%; } }
    .contents .btn_link {
      width: 400px; }
      @media all and (max-width: 560px) {
        .contents .btn_link {
          width: 100%; } }
    .paging .btn_link {
      width: 400px; }
      @media all and (max-width: 560px) {
        .paging .btn_link {
          width: 100%; } }
    .mapIndex .btn_link {
      width: 400px; }
      @media all and (max-width: 450px) {
        .mapIndex .btn_link {
          width: 100%; } }
    .conversion .btn_link {
      width: 400px; }
      @media all and (max-width: 450px) {
        .conversion .btn_link {
          width: 100%; } }
    .medicinemodal__window_link .btn_link {
      width: 400px; }
      @media all and (max-width: 768px) {
        .medicinemodal__window_link .btn_link {
          width: 100%; } }
      @media all and (max-width: 430px) {
        .medicinemodal__window_link .btn_link {
          font-size: 0.89474em;
          padding: 1.5em 4em 1.5em 1.5em; } }
    .box__area_content_txt-left .btn_link,
    .box__area_content_txt-right .btn_link {
      text-align: center;
      width: 100%; }
    .navi .btn_link {
      width: 100%; }
    .map-search__area_map-btns .btn_link {
      font-size: 0.84211em;
      line-height: 1;
      width: 100%;
      height: 100%;
      display: table; }
      @media all and (max-width: 768px) {
        .map-search__area_map-btns .btn_link {
          font-size: 1em; } }
      .map-search__area_map-btns .btn_link span {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle; }
      .map-search__area_map-btns .btn_link:after {
        width: 38px;
        height: 38px;
        margin-top: -19px;
        background-size: 25%; }
        @media all and (max-width: 1060px) {
          .map-search__area_map-btns .btn_link:after {
            width: 28px;
            height: 28px;
            margin-top: -14px; } }
        @media all and (max-width: 768px) {
          .map-search__area_map-btns .btn_link:after {
            width: 22px;
            height: 22px;
            margin-top: -11px; } }
    .mapmodal__window_btn .btn_link {
      width: 100%;
      height: 100%;
      padding: 0;
      display: table-cell;
      vertical-align: middle; }
      .mapmodal__window_btn .btn_link:after {
        display: none; }
    .formArea .btn_link {
      color: #fff; }
    .formArea-login .btn_link {
      color: #fff; }
    .box__area-form .btn_link {
      margin: 3em auto; }
      @media all and (max-width: 560px) {
        .box__area-form .btn_link {
          width: 75%; } }
    .paging-form .btn_link {
      margin: auto;
      width: 100%; }
    .formArea-thanx .btn_link {
      color: #fff; }
    @media all and (max-width: 560px) {
      .btn_link {
        padding: 1.5em; } }
    @media all and (max-width: 375px) {
      .btn_link {
        height: auto; } }
    .btn_link-prev {
      color: #fff;
      font-weight: bold;
      font-size: 1em;
      display: inline-block;
      line-height: 1.2;
      height: 83px;
      padding: 1.5em 3.25em 1.5em 1.5em;
      background-color: #ff671f;
      border-radius: 1em;
      position: relative;
      transition: background-color 200ms ease;
      padding: 1.5em 1.5em 1.5em 3.25em; }
      @media all and (max-width: 1060px) {
        .btn_link-prev {
          height: auto; } }
      .btn_link-prev:hover {
        background-color: #ff854c; }
        .btn_link-prev:hover:after {
          background-color: #337585; }
      .btn_link-prev:after {
        content: "";
        display: block;
        width: 46px;
        height: 46px;
        background-color: #005366;
        border-radius: 25%;
        background-image: url(../svg/btn_link_arrow.svg);
        background-position: center center;
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -23px;
        transition: background-color 200ms ease; }
        @media all and (max-width: 1060px) {
          .btn_link-prev:after {
            width: 36px;
            height: 36px;
            margin-top: -18px;
            background-size: 30%; } }
        @media all and (max-width: 768px) {
          .btn_link-prev:after {
            width: 26px;
            height: 26px;
            margin-top: -13px;
            background-size: 30%; } }
      .btn_link-prev:after {
        right: auto;
        left: 1em;
        transform: rotate(180deg); }
      .contents .btn_link-prev {
        width: 400px; }
        @media all and (max-width: 560px) {
          .contents .btn_link-prev {
            width: 100%; } }
      .paging .btn_link-prev {
        width: 400px; }
        @media all and (max-width: 560px) {
          .paging .btn_link-prev {
            width: 100%; } }
      .mapIndex .btn_link-prev {
        width: 400px; }
        @media all and (max-width: 450px) {
          .mapIndex .btn_link-prev {
            width: 100%; } }
      .conversion .btn_link-prev {
        width: 400px; }
        @media all and (max-width: 450px) {
          .conversion .btn_link-prev {
            width: 100%; } }
      .medicinemodal__window_link .btn_link-prev {
        width: 400px; }
        @media all and (max-width: 768px) {
          .medicinemodal__window_link .btn_link-prev {
            width: 100%; } }
        @media all and (max-width: 430px) {
          .medicinemodal__window_link .btn_link-prev {
            font-size: 0.89474em;
            padding: 1.5em 4em 1.5em 1.5em; } }
      .box__area_content_txt-left .btn_link-prev,
      .box__area_content_txt-right .btn_link-prev {
        text-align: center;
        width: 100%; }
      .navi .btn_link-prev {
        width: 100%; }
      .map-search__area_map-btns .btn_link-prev {
        font-size: 0.84211em;
        line-height: 1;
        width: 100%;
        height: 100%;
        display: table; }
        @media all and (max-width: 768px) {
          .map-search__area_map-btns .btn_link-prev {
            font-size: 1em; } }
        .map-search__area_map-btns .btn_link-prev span {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle; }
        .map-search__area_map-btns .btn_link-prev:after {
          width: 38px;
          height: 38px;
          margin-top: -19px;
          background-size: 25%; }
          @media all and (max-width: 1060px) {
            .map-search__area_map-btns .btn_link-prev:after {
              width: 28px;
              height: 28px;
              margin-top: -14px; } }
          @media all and (max-width: 768px) {
            .map-search__area_map-btns .btn_link-prev:after {
              width: 22px;
              height: 22px;
              margin-top: -11px; } }
      .mapmodal__window_btn .btn_link-prev {
        width: 100%;
        height: 100%;
        padding: 0;
        display: table-cell;
        vertical-align: middle; }
        .mapmodal__window_btn .btn_link-prev:after {
          display: none; }
      .formArea .btn_link-prev {
        color: #fff; }
      .formArea-login .btn_link-prev {
        color: #fff; }
      .box__area-form .btn_link-prev {
        margin: 3em auto; }
        @media all and (max-width: 560px) {
          .box__area-form .btn_link-prev {
            width: 75%; } }
      .paging-form .btn_link-prev {
        margin: auto;
        width: 100%; }
      .formArea-thanx .btn_link-prev {
        color: #fff; }
      .btn_link-prev-coming {
        color: #fff;
        font-weight: bold;
        font-size: 1em;
        display: inline-block;
        line-height: 1.2;
        height: 83px;
        padding: 1.5em 3.25em 1.5em 1.5em;
        background-color: #ff671f;
        border-radius: 1em;
        position: relative;
        transition: background-color 200ms ease;
        cursor: auto; }
        @media all and (max-width: 1060px) {
          .btn_link-prev-coming {
            height: auto; } }
        .btn_link-prev-coming:hover {
          background-color: #ff854c; }
          .btn_link-prev-coming:hover:after {
            background-color: #337585; }
        .btn_link-prev-coming:after {
          content: "";
          display: block;
          width: 46px;
          height: 46px;
          background-color: #005366;
          border-radius: 25%;
          background-image: url(../svg/btn_link_arrow.svg);
          background-position: center center;
          position: absolute;
          top: 50%;
          right: 1em;
          margin-top: -23px;
          transition: background-color 200ms ease; }
          @media all and (max-width: 1060px) {
            .btn_link-prev-coming:after {
              width: 36px;
              height: 36px;
              margin-top: -18px;
              background-size: 30%; } }
          @media all and (max-width: 768px) {
            .btn_link-prev-coming:after {
              width: 26px;
              height: 26px;
              margin-top: -13px;
              background-size: 30%; } }
        .contents .btn_link-prev-coming {
          width: 400px; }
          @media all and (max-width: 560px) {
            .contents .btn_link-prev-coming {
              width: 100%; } }
        .paging .btn_link-prev-coming {
          width: 400px; }
          @media all and (max-width: 560px) {
            .paging .btn_link-prev-coming {
              width: 100%; } }
        .mapIndex .btn_link-prev-coming {
          width: 400px; }
          @media all and (max-width: 450px) {
            .mapIndex .btn_link-prev-coming {
              width: 100%; } }
        .conversion .btn_link-prev-coming {
          width: 400px; }
          @media all and (max-width: 450px) {
            .conversion .btn_link-prev-coming {
              width: 100%; } }
        .medicinemodal__window_link .btn_link-prev-coming {
          width: 400px; }
          @media all and (max-width: 768px) {
            .medicinemodal__window_link .btn_link-prev-coming {
              width: 100%; } }
          @media all and (max-width: 430px) {
            .medicinemodal__window_link .btn_link-prev-coming {
              font-size: 0.89474em;
              padding: 1.5em 4em 1.5em 1.5em; } }
        .box__area_content_txt-left .btn_link-prev-coming,
        .box__area_content_txt-right .btn_link-prev-coming {
          text-align: center;
          width: 100%; }
        .navi .btn_link-prev-coming {
          width: 100%; }
        .map-search__area_map-btns .btn_link-prev-coming {
          font-size: 0.84211em;
          line-height: 1;
          width: 100%;
          height: 100%;
          display: table; }
          @media all and (max-width: 768px) {
            .map-search__area_map-btns .btn_link-prev-coming {
              font-size: 1em; } }
          .map-search__area_map-btns .btn_link-prev-coming span {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle; }
          .map-search__area_map-btns .btn_link-prev-coming:after {
            width: 38px;
            height: 38px;
            margin-top: -19px;
            background-size: 25%; }
            @media all and (max-width: 1060px) {
              .map-search__area_map-btns .btn_link-prev-coming:after {
                width: 28px;
                height: 28px;
                margin-top: -14px; } }
            @media all and (max-width: 768px) {
              .map-search__area_map-btns .btn_link-prev-coming:after {
                width: 22px;
                height: 22px;
                margin-top: -11px; } }
        .mapmodal__window_btn .btn_link-prev-coming {
          width: 100%;
          height: 100%;
          padding: 0;
          display: table-cell;
          vertical-align: middle; }
          .mapmodal__window_btn .btn_link-prev-coming:after {
            display: none; }
        .formArea .btn_link-prev-coming {
          color: #fff; }
        .formArea-login .btn_link-prev-coming {
          color: #fff; }
        .box__area-form .btn_link-prev-coming {
          margin: 3em auto; }
          @media all and (max-width: 560px) {
            .box__area-form .btn_link-prev-coming {
              width: 75%; } }
        .paging-form .btn_link-prev-coming {
          margin: auto;
          width: 100%; }
        .formArea-thanx .btn_link-prev-coming {
          color: #fff; }
        @media all and (max-width: 560px) {
          .btn_link-prev-coming {
            padding: 1.5em; } }
        @media all and (max-width: 375px) {
          .btn_link-prev-coming {
            height: auto; } }
        .btn_link-prev-coming:hover {
          background-color: #ff671f; }
          .btn_link-prev-coming:hover:after {
            background-color: #005366; }
    .btn_link-coming {
      color: #fff;
      font-weight: bold;
      font-size: 1em;
      display: inline-block;
      line-height: 1.2;
      height: 83px;
      padding: 1.5em 3.25em 1.5em 1.5em;
      background-color: #ff671f;
      border-radius: 1em;
      position: relative;
      transition: background-color 200ms ease;
      cursor: auto; }
      @media all and (max-width: 1060px) {
        .btn_link-coming {
          height: auto; } }
      .btn_link-coming:hover {
        background-color: #ff854c; }
        .btn_link-coming:hover:after {
          background-color: #337585; }
      .btn_link-coming:after {
        content: "";
        display: block;
        width: 46px;
        height: 46px;
        background-color: #005366;
        border-radius: 25%;
        background-image: url(../svg/btn_link_arrow.svg);
        background-position: center center;
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -23px;
        transition: background-color 200ms ease; }
        @media all and (max-width: 1060px) {
          .btn_link-coming:after {
            width: 36px;
            height: 36px;
            margin-top: -18px;
            background-size: 30%; } }
        @media all and (max-width: 768px) {
          .btn_link-coming:after {
            width: 26px;
            height: 26px;
            margin-top: -13px;
            background-size: 30%; } }
      .contents .btn_link-coming {
        width: 400px; }
        @media all and (max-width: 560px) {
          .contents .btn_link-coming {
            width: 100%; } }
      .paging .btn_link-coming {
        width: 400px; }
        @media all and (max-width: 560px) {
          .paging .btn_link-coming {
            width: 100%; } }
      .mapIndex .btn_link-coming {
        width: 400px; }
        @media all and (max-width: 450px) {
          .mapIndex .btn_link-coming {
            width: 100%; } }
      .conversion .btn_link-coming {
        width: 400px; }
        @media all and (max-width: 450px) {
          .conversion .btn_link-coming {
            width: 100%; } }
      .medicinemodal__window_link .btn_link-coming {
        width: 400px; }
        @media all and (max-width: 768px) {
          .medicinemodal__window_link .btn_link-coming {
            width: 100%; } }
        @media all and (max-width: 430px) {
          .medicinemodal__window_link .btn_link-coming {
            font-size: 0.89474em;
            padding: 1.5em 4em 1.5em 1.5em; } }
      .box__area_content_txt-left .btn_link-coming,
      .box__area_content_txt-right .btn_link-coming {
        text-align: center;
        width: 100%; }
      .navi .btn_link-coming {
        width: 100%; }
      .map-search__area_map-btns .btn_link-coming {
        font-size: 0.84211em;
        line-height: 1;
        width: 100%;
        height: 100%;
        display: table; }
        @media all and (max-width: 768px) {
          .map-search__area_map-btns .btn_link-coming {
            font-size: 1em; } }
        .map-search__area_map-btns .btn_link-coming span {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle; }
        .map-search__area_map-btns .btn_link-coming:after {
          width: 38px;
          height: 38px;
          margin-top: -19px;
          background-size: 25%; }
          @media all and (max-width: 1060px) {
            .map-search__area_map-btns .btn_link-coming:after {
              width: 28px;
              height: 28px;
              margin-top: -14px; } }
          @media all and (max-width: 768px) {
            .map-search__area_map-btns .btn_link-coming:after {
              width: 22px;
              height: 22px;
              margin-top: -11px; } }
      .mapmodal__window_btn .btn_link-coming {
        width: 100%;
        height: 100%;
        padding: 0;
        display: table-cell;
        vertical-align: middle; }
        .mapmodal__window_btn .btn_link-coming:after {
          display: none; }
      .formArea .btn_link-coming {
        color: #fff; }
      .formArea-login .btn_link-coming {
        color: #fff; }
      .box__area-form .btn_link-coming {
        margin: 3em auto; }
        @media all and (max-width: 560px) {
          .box__area-form .btn_link-coming {
            width: 75%; } }
      .paging-form .btn_link-coming {
        margin: auto;
        width: 100%; }
      .formArea-thanx .btn_link-coming {
        color: #fff; }
      @media all and (max-width: 560px) {
        .btn_link-coming {
          padding: 1.5em; } }
      @media all and (max-width: 375px) {
        .btn_link-coming {
          height: auto; } }
      .btn_link-coming:hover {
        background-color: #ff671f; }
        .btn_link-coming:hover:after {
          background-color: #005366; }
    .btn_link-2line {
      /* mixin */
      color: #fff;
      font-weight: bold;
      font-size: 1em;
      display: inline-block;
      line-height: 1.2;
      height: 83px;
      padding: 1.5em 3.25em 1.5em 1.5em;
      background-color: #ff671f;
      border-radius: 1em;
      position: relative;
      transition: background-color 200ms ease;
      text-align: left;
      padding: 0.9em 3.25em 0.9em 20%; }
      @media all and (max-width: 1060px) {
        .btn_link-2line {
          height: auto; } }
      .btn_link-2line:hover {
        background-color: #ff854c; }
        .btn_link-2line:hover:after {
          background-color: #337585; }
      .btn_link-2line:after {
        content: "";
        display: block;
        width: 46px;
        height: 46px;
        background-color: #005366;
        border-radius: 25%;
        background-image: url(../svg/btn_link_arrow.svg);
        background-position: center center;
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -23px;
        transition: background-color 200ms ease; }
        @media all and (max-width: 1060px) {
          .btn_link-2line:after {
            width: 36px;
            height: 36px;
            margin-top: -18px;
            background-size: 30%; } }
        @media all and (max-width: 768px) {
          .btn_link-2line:after {
            width: 26px;
            height: 26px;
            margin-top: -13px;
            background-size: 30%; } }
      .contents .btn_link-2line {
        width: 400px; }
        @media all and (max-width: 560px) {
          .contents .btn_link-2line {
            width: 100%; } }
      .paging .btn_link-2line {
        width: 400px; }
        @media all and (max-width: 560px) {
          .paging .btn_link-2line {
            width: 100%; } }
      .mapIndex .btn_link-2line {
        width: 400px; }
        @media all and (max-width: 450px) {
          .mapIndex .btn_link-2line {
            width: 100%; } }
      .conversion .btn_link-2line {
        width: 400px; }
        @media all and (max-width: 450px) {
          .conversion .btn_link-2line {
            width: 100%; } }
      .medicinemodal__window_link .btn_link-2line {
        width: 400px; }
        @media all and (max-width: 768px) {
          .medicinemodal__window_link .btn_link-2line {
            width: 100%; } }
        @media all and (max-width: 430px) {
          .medicinemodal__window_link .btn_link-2line {
            font-size: 0.89474em;
            padding: 1.5em 4em 1.5em 1.5em; } }
      .box__area_content_txt-left .btn_link-2line,
      .box__area_content_txt-right .btn_link-2line {
        text-align: center;
        width: 100%; }
      .navi .btn_link-2line {
        width: 100%; }
      .map-search__area_map-btns .btn_link-2line {
        font-size: 0.84211em;
        line-height: 1;
        width: 100%;
        height: 100%;
        display: table; }
        @media all and (max-width: 768px) {
          .map-search__area_map-btns .btn_link-2line {
            font-size: 1em; } }
        .map-search__area_map-btns .btn_link-2line span {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle; }
        .map-search__area_map-btns .btn_link-2line:after {
          width: 38px;
          height: 38px;
          margin-top: -19px;
          background-size: 25%; }
          @media all and (max-width: 1060px) {
            .map-search__area_map-btns .btn_link-2line:after {
              width: 28px;
              height: 28px;
              margin-top: -14px; } }
          @media all and (max-width: 768px) {
            .map-search__area_map-btns .btn_link-2line:after {
              width: 22px;
              height: 22px;
              margin-top: -11px; } }
      .mapmodal__window_btn .btn_link-2line {
        width: 100%;
        height: 100%;
        padding: 0;
        display: table-cell;
        vertical-align: middle; }
        .mapmodal__window_btn .btn_link-2line:after {
          display: none; }
      .formArea .btn_link-2line {
        color: #fff; }
      .formArea-login .btn_link-2line {
        color: #fff; }
      .box__area-form .btn_link-2line {
        margin: 3em auto; }
        @media all and (max-width: 560px) {
          .box__area-form .btn_link-2line {
            width: 75%; } }
      .paging-form .btn_link-2line {
        margin: auto;
        width: 100%; }
      .formArea-thanx .btn_link-2line {
        color: #fff; }
      @media all and (max-width: 768px) {
        .btn_link-2line {
          padding: 0.9em 3em 1em 20%; } }
      @media all and (max-width: 560px) {
        .btn_link-2line {
          text-align: center;
          padding: 1.5em; }
          .btn_link-2line br {
            display: none; } }
      @media all and (max-width: 375px) {
        .btn_link-2line {
          height: auto; } }
      .btn_link-2line-coming {
        color: #fff;
        font-weight: bold;
        font-size: 1em;
        display: inline-block;
        line-height: 1.2;
        height: 83px;
        padding: 1.5em 3.25em 1.5em 1.5em;
        background-color: #ff671f;
        border-radius: 1em;
        position: relative;
        transition: background-color 200ms ease;
        text-align: left;
        padding: 0.9em 3.25em 0.9em 20%;
        cursor: auto; }
        @media all and (max-width: 1060px) {
          .btn_link-2line-coming {
            height: auto; } }
        .btn_link-2line-coming:hover {
          background-color: #ff854c; }
          .btn_link-2line-coming:hover:after {
            background-color: #337585; }
        .btn_link-2line-coming:after {
          content: "";
          display: block;
          width: 46px;
          height: 46px;
          background-color: #005366;
          border-radius: 25%;
          background-image: url(../svg/btn_link_arrow.svg);
          background-position: center center;
          position: absolute;
          top: 50%;
          right: 1em;
          margin-top: -23px;
          transition: background-color 200ms ease; }
          @media all and (max-width: 1060px) {
            .btn_link-2line-coming:after {
              width: 36px;
              height: 36px;
              margin-top: -18px;
              background-size: 30%; } }
          @media all and (max-width: 768px) {
            .btn_link-2line-coming:after {
              width: 26px;
              height: 26px;
              margin-top: -13px;
              background-size: 30%; } }
        .contents .btn_link-2line-coming {
          width: 400px; }
          @media all and (max-width: 560px) {
            .contents .btn_link-2line-coming {
              width: 100%; } }
        .paging .btn_link-2line-coming {
          width: 400px; }
          @media all and (max-width: 560px) {
            .paging .btn_link-2line-coming {
              width: 100%; } }
        .mapIndex .btn_link-2line-coming {
          width: 400px; }
          @media all and (max-width: 450px) {
            .mapIndex .btn_link-2line-coming {
              width: 100%; } }
        .conversion .btn_link-2line-coming {
          width: 400px; }
          @media all and (max-width: 450px) {
            .conversion .btn_link-2line-coming {
              width: 100%; } }
        .medicinemodal__window_link .btn_link-2line-coming {
          width: 400px; }
          @media all and (max-width: 768px) {
            .medicinemodal__window_link .btn_link-2line-coming {
              width: 100%; } }
          @media all and (max-width: 430px) {
            .medicinemodal__window_link .btn_link-2line-coming {
              font-size: 0.89474em;
              padding: 1.5em 4em 1.5em 1.5em; } }
        .box__area_content_txt-left .btn_link-2line-coming,
        .box__area_content_txt-right .btn_link-2line-coming {
          text-align: center;
          width: 100%; }
        .navi .btn_link-2line-coming {
          width: 100%; }
        .map-search__area_map-btns .btn_link-2line-coming {
          font-size: 0.84211em;
          line-height: 1;
          width: 100%;
          height: 100%;
          display: table; }
          @media all and (max-width: 768px) {
            .map-search__area_map-btns .btn_link-2line-coming {
              font-size: 1em; } }
          .map-search__area_map-btns .btn_link-2line-coming span {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle; }
          .map-search__area_map-btns .btn_link-2line-coming:after {
            width: 38px;
            height: 38px;
            margin-top: -19px;
            background-size: 25%; }
            @media all and (max-width: 1060px) {
              .map-search__area_map-btns .btn_link-2line-coming:after {
                width: 28px;
                height: 28px;
                margin-top: -14px; } }
            @media all and (max-width: 768px) {
              .map-search__area_map-btns .btn_link-2line-coming:after {
                width: 22px;
                height: 22px;
                margin-top: -11px; } }
        .mapmodal__window_btn .btn_link-2line-coming {
          width: 100%;
          height: 100%;
          padding: 0;
          display: table-cell;
          vertical-align: middle; }
          .mapmodal__window_btn .btn_link-2line-coming:after {
            display: none; }
        .formArea .btn_link-2line-coming {
          color: #fff; }
        .formArea-login .btn_link-2line-coming {
          color: #fff; }
        .box__area-form .btn_link-2line-coming {
          margin: 3em auto; }
          @media all and (max-width: 560px) {
            .box__area-form .btn_link-2line-coming {
              width: 75%; } }
        .paging-form .btn_link-2line-coming {
          margin: auto;
          width: 100%; }
        .formArea-thanx .btn_link-2line-coming {
          color: #fff; }
        @media all and (max-width: 768px) {
          .btn_link-2line-coming {
            padding: 0.9em 3em 1em 20%; } }
        @media all and (max-width: 560px) {
          .btn_link-2line-coming {
            text-align: center;
            padding: 1.5em; }
            .btn_link-2line-coming br {
              display: none; } }
        @media all and (max-width: 375px) {
          .btn_link-2line-coming {
            height: auto; } }
        .btn_link-2line-coming:hover {
          background-color: #ff671f; }
          .btn_link-2line-coming:hover:after {
            background-color: #005366; }
  .btn_form {
    color: #fff;
    font-weight: bold;
    font-size: 0.84211em;
    display: inline-block;
    line-height: 1;
    margin: 0 1em;
    padding: 1em;
    background-color: #005366;
    border-radius: 0.5em;
    position: relative;
    transition: background-color 200ms ease; }
    @media all and (max-width: 768px) {
      .btn_form {
        margin-right: 0; } }
    .btn_form:hover {
      background-color: #337585; }
    .btn_form:focus {
      box-shadow: 0px 0px 5px 1px #ff671f; }
  .btn_coming {
    /* mixin */
    color: #fff;
    font-weight: bold;
    font-size: 1em;
    display: inline-block;
    line-height: 1.2;
    height: 83px;
    padding: 1.5em 3.25em 1.5em 1.5em;
    background-color: #ff671f;
    border-radius: 1em;
    position: relative;
    transition: background-color 200ms ease;
    border-radius: 0.4em;
    padding: 0.5em;
    height: auto;
    line-height: 1;
    vertical-align: middle;
    background-color: #fff;
    text-align: center;
    color: #005366;
    font-size: 2.63158em;
    cursor: auto; }
    @media all and (max-width: 1060px) {
      .btn_coming {
        height: auto; } }
    .btn_coming:hover {
      background-color: #ff854c; }
      .btn_coming:hover:after {
        background-color: #337585; }
    .btn_coming:after {
      content: "";
      display: block;
      width: 46px;
      height: 46px;
      background-color: #005366;
      border-radius: 25%;
      background-image: url(../svg/btn_link_arrow.svg);
      background-position: center center;
      position: absolute;
      top: 50%;
      right: 1em;
      margin-top: -23px;
      transition: background-color 200ms ease; }
      @media all and (max-width: 1060px) {
        .btn_coming:after {
          width: 36px;
          height: 36px;
          margin-top: -18px;
          background-size: 30%; } }
      @media all and (max-width: 768px) {
        .btn_coming:after {
          width: 26px;
          height: 26px;
          margin-top: -13px;
          background-size: 30%; } }
    .conversion .btn_coming {
      width: 590px; }
      @media all and (max-width: 640px) {
        .conversion .btn_coming {
          width: 100%; } }
    .btn_coming:after {
      display: none; }
    @media all and (max-width: 560px) {
      .btn_coming {
        font-size: 1.84211em; } }
    .btn_coming:hover {
      background-color: #fff; }

.note {
  /* mixin */ }
  .note-hospital {
    text-align: justify;
    font-size: 0.84211em;
    font-weight: normal;
    line-height: 1.6; }

.header {
  background-color: #fff;
  position: relative; }
  .header:after {
    content: "";
    display: block;
    width: 100%;
    height: 32px;
    background-image: url(../img/head_bg.png);
    background-repeat: repeat-x;
    background-position: center center;
    position: absolute;
    left: 0;
    bottom: -32px; }
    @media all and (max-width: 1060px) {
      .header:after {
        background-size: 150px;
        height: 28px;
        bottom: -25px; } }
    @media all and (max-width: 375px) {
      .header:after {
        background-size: 75px;
        height: 15px;
        bottom: -13px; } }
  .header__logo {
    text-align: left;
    max-width: 1000px;
    margin: auto;
    padding: 50px 0;
    position: relative;
    z-index: 1; }
    @media all and (max-width: 1060px) {
      .header__logo {
        padding: 40px; }
        .header__logo img {
          width: 280px; } }
    @media all and (max-width: 560px) {
      .header__logo {
        padding: 25px; }
        .header__logo img {
          width: 200px; } }
    @media all and (max-width: 375px) {
      .header__logo {
        padding: 15px 15px 25px; }
        .header__logo img {
          width: 150px; } }

.menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }
  .menu__btn_wrap {
    max-width: 1280px;
    margin: auto;
    padding: 35px 100px 0 0; }
    .menu__btn_wrap:after {
      content: "";
      clear: both;
      display: block; }
    @media all and (max-width: 1060px) {
      .menu__btn_wrap {
        padding: 25px 40px 0 0; } }
    @media all and (max-width: 560px) {
      .menu__btn_wrap {
        padding: 20px 30px 0 0; } }
    @media all and (max-width: 375px) {
      .menu__btn_wrap {
        padding: 15px 20px 0 0; } }
  .menu__btn {
    float: right;
    width: 60px;
    height: 60px;
    border-radius: 20%;
    background-color: #ff671f;
    position: relative;
    transition: background-color 200ms ease;
    z-index: 3;
    cursor: pointer; }
    .menu__btn:hover {
      background-color: #ff854c; }
    @media all and (max-width: 1060px) {
      .menu__btn {
        width: 50px;
        height: 50px; } }
    @media all and (max-width: 560px) {
      .menu__btn {
        width: 40px;
        height: 40px; } }
    .menu__btn_line {
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -30%;
      width: 60%;
      height: 4px;
      background-color: #fff;
      border-radius: 2px;
      transition: all 300ms ease; }
      .menu__btn_line:nth-of-type(1) {
        top: 14px; }
      .menu__btn_line:nth-of-type(2) {
        top: 28px; }
      .menu__btn_line:nth-of-type(3) {
        bottom: 14px; }
      @media all and (max-width: 1060px) {
        .menu__btn_line {
          height: 3px; }
          .menu__btn_line:nth-of-type(1) {
            top: 12px; }
          .menu__btn_line:nth-of-type(2) {
            top: 24px; }
          .menu__btn_line:nth-of-type(3) {
            bottom: 12px; } }
      @media all and (max-width: 560px) {
        .menu__btn_line {
          height: 3px; }
          .menu__btn_line:nth-of-type(1) {
            top: 9px; }
          .menu__btn_line:nth-of-type(2) {
            top: 18px; }
          .menu__btn_line:nth-of-type(3) {
            bottom: 10px; } }
      .menu__btn-on .menu__btn_line:nth-of-type(1) {
        transform: translateY(14px) rotate(-45deg); }
      .menu__btn-on .menu__btn_line:nth-of-type(2) {
        opacity: 0; }
      .menu__btn-on .menu__btn_line:nth-of-type(3) {
        transform: translateY(-14px) rotate(45deg); }
      @media all and (max-width: 1060px) {
        .menu__btn-on .menu__btn_line:nth-of-type(1) {
          transform: translateY(12px) rotate(-45deg); }
        .menu__btn-on .menu__btn_line:nth-of-type(3) {
          transform: translateY(-12px) rotate(45deg); } }
      @media all and (max-width: 560px) {
        .menu__btn-on .menu__btn_line:nth-of-type(1) {
          transform: translateY(9px) rotate(-45deg); }
        .menu__btn-on .menu__btn_line:nth-of-type(3) {
          transform: translateY(-9px) rotate(45deg); } }
  @media all and (max-width: 670px) {
    .menu .sp {
      display: none; } }
  .menu__item {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.85);
    line-height: 1.4;
    z-index: 2;
    display: none;
    overflow-y: auto; }
    .menu__item .inner__menu {
      float: none;
      margin: auto;
      width: 100%; }
      @media all and (max-width: 1060px) {
        .menu__item .inner__menu {
          float: none;
          margin: auto; } }
      @media all and (max-width: 670px) {
        .menu__item .inner__menu {
          float: none;
          margin: auto;
          position: inherit; } }
      @media all and (max-width: 560px) {
        .menu__item .inner__menu {
          float: none;
          margin: auto;
          position: inherit; } }
    .menu__item-bnr {
      display: inline-block;
      width: 294px;
      float: right;
      margin: -1.25em 1em 0 0;
      position: relative;
      z-index: 1; }
      .menu__item-bnr a {
        transition: opacity 200ms ease; }
        .menu__item-bnr a:hover {
          opacity: .8; }
      .menu__item-bnr img {
        width: 100%; }
      .menu__item-bnr span {
        color: #005366;
        display: block;
        font-size: 0.63158em;
        letter-spacing: 0; }
      @media all and (max-width: 1060px) {
        .menu__item-bnr {
          width: 30%;
          min-width: 210px; } }
      @media all and (max-width: 670px) {
        .menu__item-bnr {
          position: absolute;
          right: 25px;
          bottom: -80px;
          float: none; } }
      @media all and (max-width: 560px) {
        .menu__item-bnr {
          right: 15px;
          bottom: -75px; } }
      @media all and (max-width: 375px) {
        .menu__item-bnr {
          right: 10px;
          bottom: -120%;
          width: 55%;
          min-width: auto; }
          .menu__item-bnr span {
            line-height: 1.5;
            font-size: 0.68421em; } }
    .menu__item-page {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 52%;
      transform: translate(-50%, -50%); }
      .menu__item-page > li {
        margin-bottom: 1.25em; }
      .menu__item-page a {
        color: #ff671f;
        font-size: 1.26316em; }
        .menu__item-page a:hover {
          color: #ff854c; }
    .menu__item-sns {
      margin: 4em 0 0 30px; }
      @media all and (max-width: 1060px) {
        .menu__item-sns {
          margin: 4em 0 0 0; } }
      @media all and (max-width: 768px) {
        .menu__item-sns {
          margin: 3em 0 0 0; } }
      .menu__item-sns li {
        display: inline-block;
        margin: 0 14px; }
        @media all and (max-width: 1060px) {
          .menu__item-sns li {
            width: 60px;
            margin: 0 10px; } }
        @media all and (max-width: 768px) {
          .menu__item-sns li {
            width: 45px;
            margin: 0 7px; } }
        @media all and (max-width: 375px) {
          .menu__item-sns li {
            width: 40px;
            margin: 0 3px; } }
      .menu__item-sns-line {
        transform: scale(1.75) translate(-8px, -3px); }
        @media all and (max-width: 1060px) {
          .menu__item-sns-line {
            transform: scale(1.5) translate(-3px, -1px); } }
        @media all and (max-width: 768px) {
          .menu__item-sns-line {
            transform: scale(1.15) translate(-3px, 4px); } }
        @media all and (max-width: 375px) {
          .menu__item-sns-line {
            transform: scale(1.03) translate(-3px, 5px); } }

.contents {
  max-width: 1000px;
  margin: 0 auto 6em;
  overflow: hidden; }
  .contents-wrap-map {
    padding: 15px;
    background-color: #fff;
    border-radius: 50px;
    margin-top: 90px; }
    @media all and (max-width: 1060px) {
      .contents-wrap-map {
        margin-top: 70px; } }
    @media all and (max-width: 768px) {
      .contents-wrap-map {
        margin-top: 50px; } }
    @media all and (max-width: 560px) {
      .contents-wrap-map {
        margin-top: 30px; } }
    @media all and (max-width: 600px) {
      .contents-wrap-map {
        border-radius: 20px; } }
  @media all and (max-width: 560px) {
    .contents__lead {
      text-align: justify; }
      .contents__lead .pc {
        display: none; } }
  @media all and (max-width: 560px) {
    .contents__lead-map .pc {
      display: none; } }
  @media all and (max-width: 560px) {
    .contents__txt {
      text-align: justify; }
      .contents__txt .pc {
        display: none; } }
  .contents__txt-strong {
    font-size: 1.2em;
    letter-spacing: .2em;
    color: #005366; }
  .contents__animation-guard {
    width: 960px;
    height: 360px;
    margin: auto;
    background: url(../svg/dog_guard.svg) no-repeat;
    animation: guard 1.2s steps(2) infinite;
    background-position: 0 0;
    background-size: cover; }
    @media all and (max-width: 1030px) {
      .contents__animation-guard {
        width: 640px;
        height: 240px;
        animation: guard2 1.2s steps(2) infinite; } }
    @media all and (max-width: 700px) {
      .contents__animation-guard {
        width: 480px;
        height: 180px;
        animation: guard3 1.2s steps(2) infinite; } }
    @media all and (max-width: 550px) {
      .contents__animation-guard {
        width: 240px;
        height: 90px;
        animation: guard4 1.2s steps(2) infinite; } }
  .contents__animation-wash {
    width: 600px;
    height: 400px;
    margin: auto;
    background: url(../svg/dog_wash.svg) no-repeat;
    animation: wash 1.2s steps(2) infinite;
    background-position: 0 0;
    background-size: cover; }
    @media all and (max-width: 768px) {
      .contents__animation-wash {
        width: 500px;
        height: 332px;
        animation: wash2 1.2s steps(2) infinite; } }
    @media all and (max-width: 560px) {
      .contents__animation-wash {
        width: 260px;
        height: 172px;
        animation: wash3 1.2s steps(2) infinite; } }
  .contents__animation-hospital {
    width: 774px;
    height: 400px;
    margin: auto;
    background: url(../svg/dog_hospital.svg) no-repeat;
    animation: hospital 1.2s steps(2) infinite;
    background-position: 0 0;
    background-size: cover; }
    @media all and (max-width: 840px) {
      .contents__animation-hospital {
        width: 520px;
        height: 270px;
        animation: hospital2 1.2s steps(2) infinite; } }
    @media all and (max-width: 560px) {
      .contents__animation-hospital {
        width: 290px;
        height: 150px;
        animation: hospital3 1.2s steps(2) infinite; } }
  .contents__animation-medicine {
    width: 700px;
    height: 420px;
    margin: auto;
    background: url(../svg/dog_medicine.svg) no-repeat;
    animation: medicine 1.2s steps(2) infinite;
    background-position: 0 0;
    background-size: cover; }
    @media all and (max-width: 768px) {
      .contents__animation-medicine {
        width: 560px;
        height: 334px;
        animation: medicine2 1.2s steps(2) infinite; } }
    @media all and (max-width: 560px) {
      .contents__animation-medicine {
        width: 290px;
        height: 175px;
        animation: medicine3 1.2s steps(2) infinite; } }
  .contents__bnr {
    transition: opacity 200ms ease; }
    .contents__bnr:hover {
      opacity: .8; }

@keyframes guard {
  to {
    background-position: 0 -720px; } }

@keyframes guard2 {
  to {
    background-position: 0 -480px; } }

@keyframes guard3 {
  to {
    background-position: 0 -360px; } }

@keyframes guard4 {
  to {
    background-position: 0 -180px; } }

@keyframes wash {
  to {
    background-position: 0 -800px; } }

@keyframes wash2 {
  to {
    background-position: 0 -666px; } }

@keyframes wash3 {
  to {
    background-position: 0 -346px; } }

@keyframes hospital {
  to {
    background-position: 0 -800px; } }

@keyframes hospital2 {
  to {
    background-position: 0 -538px; } }

@keyframes hospital3 {
  to {
    background-position: 0 -300px; } }

@keyframes medicine {
  to {
    background-position: 0 -839px; } }

@keyframes medicine2 {
  to {
    background-position: 0 -671px; } }

@keyframes medicine3 {
  to {
    background-position: 0 -349px; } }

.firstview-index {
  overflow: hidden; }
  @media all and (max-width: 1060px) {
    .firstview-index {
      margin: 0 -20px; } }
  .firstview-index__title {
    /* mixin */
    margin: 104px auto -80px;
    opacity: 0;
    transform: translate(0, 50px) scale(1, 0);
    transition: opacity 400ms ease, transform 400ms cubic-bezier(0, -0.15, 0, 1.85); }
    .firstview-index__title .sp {
      display: none; }
    @media all and (max-width: 1060px) {
      .firstview-index__title {
        padding: 0 20px; } }
    @media all and (max-width: 500px) {
      .firstview-index__title {
        margin: 25% auto -15%; } }
    @media all and (max-width: 768px) {
      .firstview-index__title .pc {
        display: none; }
      .firstview-index__title .sp {
        display: inline;
        width: 100%; } }
    .firstview-index__title.on {
      opacity: 1;
      transform: translate(0, 0px) scale(1, 1); }
  .firstview-index__lead {
    /* mixin */
    padding: 0 10px;
    opacity: 0;
    transition: opacity 1s ease; }
    .firstview-index__lead span {
      display: inline-block; }
    .firstview-index__lead.on {
      opacity: 1; }
  .firstview-index__fig {
    position: relative;
    display: inline-block;
    line-height: 0;
    font-size: 0; }
    @media all and (max-width: 768px) {
      .firstview-index__fig {
        padding: 10% 0;
        margin-bottom: -10%; } }
    .firstview-index__fig .tmp {
      opacity: .5;
      position: absolute;
      top: 2.9%;
      left: 49%;
      transform: translateX(-50%); }
    .firstview-index__fig-dog {
      position: relative;
      z-index: 1; }
      .firstview-index__fig-dog img {
        width: 100%; }
    .firstview-index__fig-eyebrow {
      position: absolute;
      display: inline-block;
      width: 2.5%;
      top: 47.5%;
      left: 43.5%;
      line-height: 0;
      font-size: 0;
      opacity: 0;
      z-index: 1;
      transition: opacity 300ms ease 500ms; }
      .firstview-index__fig-eyebrow.on {
        opacity: 1; }
    .firstview-index__fig-trouble {
      position: absolute;
      display: inline-block;
      width: 5.6%;
      top: 23.5%;
      left: 47%;
      line-height: 0;
      font-size: 0;
      opacity: 0;
      z-index: 1;
      transition: opacity 300ms ease 500ms; }
      @media all and (max-width: 768px) {
        .firstview-index__fig-trouble {
          top: 29.5%; } }
      .firstview-index__fig-trouble.on {
        opacity: 1; }
    .firstview-index__fig-baloon {
      transition: opacity 800ms ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* mixin */ }
      .firstview-index__fig-baloon.hidden {
        opacity: 0; }
      .firstview-index__fig-baloon-01 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 28.3%;
        top: 31.2%;
        left: 0%; }
        .firstview-index__fig-baloon-01.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-01.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-01 {
            top: 41.2%;
            left: 7%; } }
      .firstview-index__fig-baloon-02 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 24.9%;
        top: 76.2%;
        left: 60.1%; }
        .firstview-index__fig-baloon-02.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-02.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-02 {
            top: 82.2%;
            left: 56.1%; } }
      .firstview-index__fig-baloon-03 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 34.8%;
        top: 39%;
        left: 65.2%; }
        .firstview-index__fig-baloon-03.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-03.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-03 {
            top: 49%;
            left: 59.2%; } }
      .firstview-index__fig-baloon-04 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 31.1%;
        top: 0%;
        left: 11.6%; }
        .firstview-index__fig-baloon-04.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-04.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-04 {
            top: 7%;
            left: 11.6%; } }
      .firstview-index__fig-baloon-05 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 28.1%;
        top: 66%;
        left: 6.7%; }
        .firstview-index__fig-baloon-05.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-05.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-05 {
            top: 74%;
            left: 12.7%; } }
      .firstview-index__fig-baloon-06 {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 600ms cubic-bezier(1, 0, 0.2, 1.6);
        transform-origin: bottom center;
        width: 32.1%;
        top: 2.8%;
        left: 62.5%; }
        .firstview-index__fig-baloon-06.on {
          transform: scale(1); }
          @media all and (max-width: 768px) {
            .firstview-index__fig-baloon-06.on {
              transform: scale(1.4); } }
        @media all and (max-width: 768px) {
          .firstview-index__fig-baloon-06 {
            top: 12.8%;
            left: 57.5%; } }
    .firstview-index__fig-nomi {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      /* mixin */ }
      .firstview-index__fig-nomi.on {
        opacity: 1;
        animation: parent_move_left 3s steps(1); }
      .firstview-index__fig-nomi-01 {
        position: absolute;
        display: inline-block;
        width: 10.1%;
        top: 30.1%;
        left: 10%;
        animation: move1 2.5s steps(1) infinite; }
      .firstview-index__fig-nomi-02 {
        position: absolute;
        display: inline-block;
        width: 11.7%;
        top: 48.1%;
        left: 20.4%;
        animation: move1 3s steps(1) infinite; }
      .firstview-index__fig-nomi-03 {
        position: absolute;
        display: inline-block;
        width: 8.1%;
        top: 57.9%;
        left: 7.4%;
        animation: move1 2s steps(1) infinite; }
    .firstview-index__fig-madani {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      /* mixin */ }
      .firstview-index__fig-madani.on {
        opacity: 1;
        animation: parent_move_right 3s steps(1); }
      .firstview-index__fig-madani-01 {
        position: absolute;
        display: inline-block;
        width: 9.2%;
        top: 30.6%;
        left: 80.9%;
        animation: move1 2.5s steps(1) infinite 0.2s; }
      .firstview-index__fig-madani-02 {
        position: absolute;
        display: inline-block;
        width: 13.1%;
        top: 42.8%;
        left: 64.7%;
        animation: move1 3s steps(1) infinite 0.2s; }
      .firstview-index__fig-madani-03 {
        position: absolute;
        display: inline-block;
        width: 8.8%;
        top: 59%;
        left: 81.6%;
        animation: move1 2s steps(1) infinite 0.2s; }

@keyframes move1 {
  25% {
    transform: rotate(30deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-30deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes parent_move_left {
  0% {
    transform: translate(-65%, 0);
    opacity: 1; }
  17% {
    transform: translate(-50%, 0);
    opacity: 1; }
  33% {
    transform: translate(-40%, 0); }
  50% {
    transform: translate(-30%, 0); }
  67% {
    transform: translate(-20%, 0); }
  83% {
    transform: translate(-10%, 0); }
  100% {
    transform: translate(0%, 0); } }

@keyframes parent_move_right {
  0% {
    transform: translate(65%, 0);
    opacity: 1; }
  17% {
    transform: translate(50%, 0);
    opacity: 1; }
  33% {
    transform: translate(40%, 0); }
  50% {
    transform: translate(30%, 0); }
  67% {
    transform: translate(20%, 0); }
  83% {
    transform: translate(10%, 0); }
  100% {
    transform: translate(0%, 0); } }

.firstview {
  /* mixin */ }
  .firstview__img {
    margin-bottom: 3.15789em; }
    .firstview__img-top {
      margin-bottom: 6.31579em; }
  .firstview__title {
    /* mixin */
    margin: 94px auto 100px;
    opacity: 0;
    transition: opacity 750ms ease; }
    @media all and (max-width: 500px) {
      .firstview__title {
        margin: 25% auto 17%; } }
    .firstview__title .sp {
      display: none; }
    @media all and (max-width: 768px) {
      .firstview__title .pc {
        display: none; }
      .firstview__title .sp {
        display: inline; } }
    .firstview__title.on {
      opacity: 1; }
    .firstview__title-noeffect {
      margin: 94px auto 100px;
      margin: 94px auto 50px; }
      @media all and (max-width: 500px) {
        .firstview__title-noeffect {
          margin: 25% auto 17%; } }
      .firstview__title-noeffect .sp {
        display: none; }
      @media all and (max-width: 768px) {
        .firstview__title-noeffect .pc {
          display: none; }
        .firstview__title-noeffect .sp {
          display: inline; } }
  .firstview__lead {
    /* mixin */
    opacity: 0;
    transition: opacity 1s ease; }
    .firstview__lead span {
      display: inline-block;
      width: max-content; }
    .firstview__lead.on {
      opacity: 1; }
    .firstview__lead-noeffect span {
      display: inline-block;
      width: max-content; }
  .firstview__fig {
    margin: 0 auto 55px;
    opacity: 0;
    transition: opacity 1.5s ease; }
    @media all and (max-width: 500px) {
      .firstview__fig {
        margin: 0 auto 5.5%; } }
    .firstview__fig.on {
      opacity: 1; }
    .firstview__fig:after {
      content: "";
      clear: both;
      display: block; }
    .firstview__fig_dog {
      /* mixin */ }
      .firstview__fig_dog-flea {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 52.2%; }
      .firstview__fig_dog-flea-sick {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 48.3%; }
      .firstview__fig_dog-flea-extermination {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 51.8%; }
      .firstview__fig_dog-tick {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 52.2%; }
      .firstview__fig_dog-tick-sick {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 48.3%; }
      .firstview__fig_dog-tick-extermination {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 53.4%; }
      .firstview__fig_dog-medicine {
        float: right;
        display: inline-block;
        position: relative;
        line-height: 1;
        width: 46%; }
      .firstview__fig_dog_effect {
        /* mixin */ }
        .firstview__fig_dog_effect-trouble {
          display: inline-block;
          position: absolute;
          transform-origin: center bottom;
          animation: fv_tilt 2.5s steps(1) infinite;
          width: 30.8%;
          top: -32%;
          left: 35%; }
        .firstview__fig_dog_effect-question {
          display: inline-block;
          position: absolute;
          transform-origin: center bottom;
          animation: fv_tilt 2.5s steps(1) infinite;
          width: 21%;
          top: -37%;
          left: 40%; }
      .firstview__fig_dog_eyebrow {
        display: inline-block;
        width: 12.1%;
        position: absolute;
        top: 49%;
        left: 20%;
        line-height: 0;
        font-size: 0; }
    .firstview__fig_other {
      /* mixin */ }
      .firstview__fig_other-flea {
        float: left;
        display: inline-block;
        margin-top: 3.4%;
        width: 35.9%; }
      .firstview__fig_other-flea-sick {
        float: left;
        display: inline-block;
        margin-top: 3.4%;
        width: 45.3%; }
      .firstview__fig_other-flea-extermination {
        float: left;
        display: inline-block;
        margin-top: 1%;
        width: 42.5%; }
      .firstview__fig_other-tick {
        float: left;
        display: inline-block;
        margin-top: -3%;
        width: 42.2%; }
      .firstview__fig_other-tick-sick {
        float: left;
        display: inline-block;
        margin-top: -2%;
        width: 45.3%; }
      .firstview__fig_other-tick-extermination {
        float: left;
        display: inline-block;
        margin-top: -3%;
        width: 42.7%; }
      .firstview__fig_other-medicine {
        float: left;
        display: inline-block;
        width: 48.3%; }
    .firstview__fig-flea {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 364px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-flea {
          margin: 0 auto 5.5%; } }
      .firstview__fig-flea.on {
        opacity: 1; }
      .firstview__fig-flea:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-flea-sick {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 392px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-flea-sick {
          margin: 0 auto 5.5%; } }
      .firstview__fig-flea-sick.on {
        opacity: 1; }
      .firstview__fig-flea-sick:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-flea-extermination {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 366px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-flea-extermination {
          margin: 0 auto 5.5%; } }
      .firstview__fig-flea-extermination.on {
        opacity: 1; }
      .firstview__fig-flea-extermination:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-tick {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 366px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-tick {
          margin: 0 auto 5.5%; } }
      .firstview__fig-tick.on {
        opacity: 1; }
      .firstview__fig-tick:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-tick-sick {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 394px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-tick-sick {
          margin: 0 auto 5.5%; } }
      .firstview__fig-tick-sick.on {
        opacity: 1; }
      .firstview__fig-tick-sick:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-tick-extermination {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 363px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-tick-extermination {
          margin: 0 auto 5.5%; } }
      .firstview__fig-tick-extermination.on {
        opacity: 1; }
      .firstview__fig-tick-extermination:after {
        content: "";
        clear: both;
        display: block; }
    .firstview__fig-medicine {
      margin: 0 auto 55px;
      opacity: 0;
      transition: opacity 1.5s ease;
      max-width: 408px;
      padding: 0 20px; }
      @media all and (max-width: 500px) {
        .firstview__fig-medicine {
          margin: 0 auto 5.5%; } }
      .firstview__fig-medicine.on {
        opacity: 1; }
      .firstview__fig-medicine:after {
        content: "";
        clear: both;
        display: block; }

@keyframes fv_tilt {
  25% {
    transform: rotate(15deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-15deg); }
  100% {
    transform: rotate(0deg); } }

.navi {
  width: 848px;
  margin: 0 auto 9%;
  line-height: 1.6; }
  .navi:after {
    content: "";
    clear: both;
    display: block; }
  @media all and (max-width: 1060px) {
    .navi {
      width: 84.8%; } }
  @media all and (max-width: 660px) {
    .navi {
      width: 100%; } }
  @media all and (max-width: 560px) {
    .navi {
      margin-bottom: 90px; } }
  .navi__box {
    /* mixin */
    width: 395px;
    float: left;
    margin-bottom: 40px; }
    .navi__box:nth-child(odd) {
      margin-right: 5.8%; }
    @media all and (max-width: 1060px) {
      .navi__box {
        width: 46.58019%;
        margin-bottom: 4.71698%; }
        .navi__box:nth-child(odd) {
          margin-right: 6.83962%; } }
    @media all and (max-width: 560px) {
      .navi__box {
        float: none;
        width: 100%;
        margin-bottom: 3em; } }
    .navi__box-coming {
      width: 395px;
      float: left;
      margin-bottom: 40px;
      position: relative; }
      .navi__box-coming:nth-child(odd) {
        margin-right: 5.8%; }
      @media all and (max-width: 1060px) {
        .navi__box-coming {
          width: 46.58019%;
          margin-bottom: 4.71698%; }
          .navi__box-coming:nth-child(odd) {
            margin-right: 6.83962%; } }
      @media all and (max-width: 560px) {
        .navi__box-coming {
          float: none;
          width: 100%;
          margin-bottom: 3em; } }
      .navi__box-coming:after {
        content: "";
        display: block;
        width: 37.97468%;
        height: 37.97468%;
        background-image: url(../svg/coming.svg);
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 1; }
    .navi__box_img {
      width: 100%;
      height: 280px;
      margin-bottom: 1em;
      position: relative; }
      .navi__box-coming .navi__box_img {
        opacity: .5; }
      @media all and (max-width: 1060px) {
        .navi__box_img {
          height: auto; }
          .navi__box_img:before {
            content: "";
            display: block;
            padding-top: 80%; } }
      @media all and (max-width: 660px) {
        .navi__box_img {
          height: 33.01887%; }
          .navi__box_img:before {
            display: none; } }
      @media all and (max-width: 560px) {
        .navi__box_img {
          margin-bottom: 1em; } }
      .navi__box_img img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto; }
        @media all and (max-width: 660px) {
          .navi__box_img img {
            position: relative; } }
        @media all and (max-width: 560px) {
          .navi__box_img img {
            width: 75%; } }
    .navi__box_txt {
      margin-bottom: 1em; }
      .navi__box-coming .navi__box_txt {
        opacity: .5; }
      .navi__box_txt-1line {
        margin-bottom: 1em;
        line-height: 1;
        padding-top: 1em; }
        .navi__box-coming .navi__box_txt-1line {
          opacity: .5; }
        @media all and (max-width: 560px) {
          .navi__box_txt-1line {
            line-height: auto;
            margin-bottom: 0;
            padding-top: 0; } }

.box {
  margin-bottom: 3.15789em;
  /* mixin */
  /* mixin */ }
  .box__head {
    font-size: 1.31579em;
    line-height: 1.2;
    color: #fff;
    padding: 1em;
    background-color: #ff671f;
    border-radius: 1em 1em 0 0;
    width: 46.5%;
    margin: 5.26316em auto 0; }
    .box + .box .box__head {
      margin: 7.36842em auto 0; }
    .box__head span {
      display: inline-block; }
    @media all and (max-width: 768px) {
      .box__head {
        width: 70%;
        margin: 5.26316em auto 0; } }
    @media all and (max-width: 700px) {
      .box__head {
        border-radius: 0.75em 0.75em 0 0; } }
    .box__head-ruby {
      font-size: 1.31579em;
      line-height: 1.2;
      color: #fff;
      padding: 1em;
      background-color: #ff671f;
      border-radius: 1em 1em 0 0;
      width: 46.5%;
      margin: 5.26316em auto 0;
      padding: 1.25em 1em 0.75em; }
      .box + .box .box__head-ruby {
        margin: 7.36842em auto 0; }
      .box__head-ruby span {
        display: inline-block; }
      @media all and (max-width: 768px) {
        .box__head-ruby {
          width: 70%;
          margin: 5.26316em auto 0; } }
      @media all and (max-width: 700px) {
        .box__head-ruby {
          border-radius: 0.75em 0.75em 0 0; } }
  .box__area {
    padding: 3em 4em;
    background-color: #fff;
    border-radius: 2.5em;
    position: relative; }
    @media all and (max-width: 768px) {
      .box__area {
        padding: 3em; } }
    @media all and (max-width: 700px) {
      .box__area {
        border-radius: 1.5em; } }
    .box__area-medicine {
      padding: 3em 4em;
      background-color: #fff;
      border-radius: 2.5em;
      position: relative;
      padding: 3em 4em 3em 5em; }
      @media all and (max-width: 768px) {
        .box__area-medicine {
          padding: 3em; } }
      @media all and (max-width: 700px) {
        .box__area-medicine {
          border-radius: 1.5em; } }
      @media all and (max-width: 768px) {
        .box__area-medicine {
          padding: 3em 3em 3em 4em; } }
      @media all and (max-width: 700px) {
        .box__area-medicine {
          padding: 3em; } }
      @media all and (max-width: 560px) {
        .box__area-medicine {
          padding: 2.5em; } }
      @media all and (max-width: 375px) {
        .box__area-medicine {
          padding: 1.5em; } }
    .box__area-form {
      padding: 3em 4em;
      background-color: #fff;
      border-radius: 2.5em;
      position: relative;
      padding: 3em 5.5em; }
      @media all and (max-width: 768px) {
        .box__area-form {
          padding: 3em; } }
      @media all and (max-width: 700px) {
        .box__area-form {
          border-radius: 1.5em; } }
      @media all and (max-width: 560px) {
        .box__area-form {
          padding: 2em 1.5em; } }
    .box__area-confirm {
      padding: 3em 4em;
      background-color: #fff;
      border-radius: 2.5em;
      position: relative;
      padding: 3em 5.5em; }
      @media all and (max-width: 768px) {
        .box__area-confirm {
          padding: 3em; } }
      @media all and (max-width: 700px) {
        .box__area-confirm {
          border-radius: 1.5em; } }
      @media all and (max-width: 560px) {
        .box__area-confirm {
          padding: 2em 1.5em; } }
    .box__area-thanx {
      padding: 3em 4em;
      background-color: #fff;
      border-radius: 2.5em;
      position: relative;
      padding: 7em 5.5em 3em; }
      @media all and (max-width: 768px) {
        .box__area-thanx {
          padding: 3em; } }
      @media all and (max-width: 700px) {
        .box__area-thanx {
          border-radius: 1.5em; } }
      @media all and (max-width: 768px) {
        .box__area-thanx {
          padding: 5em 2em 2em; } }
    .box__area_multicol:after {
      content: "";
      clear: both;
      display: block; }
    .box__area_multicol-addrhospital {
      font-size: 0; }
      .box__area_multicol-addrhospital:after {
        content: "";
        clear: both;
        display: block; }
    .box__area_patient {
      position: absolute;
      top: 1.5em;
      right: 1.5em;
      line-height: 1;
      width: 30%; }
      @media all and (max-width: 800px) {
        .box__area_patient {
          width: 15%; } }
      @media all and (max-width: 700px) {
        .box__area_patient {
          position: relative;
          text-align: center;
          top: auto;
          right: auto;
          width: auto;
          margin-bottom: 1em;
          overflow: hidden; }
          .box__area_patient:after {
            content: "";
            clear: both;
            display: block; } }
  @media all and (max-width: 700px) and (max-width: 700px) {
    .box__area_patient ul {
      float: left;
      left: 50%;
      position: relative; } }
      .box__area_patient li {
        float: right;
        margin-bottom: 0.75em; }
        @media all and (max-width: 700px) {
          .box__area_patient li {
            float: left;
            left: -50%;
            position: relative; } }
        .box__area_patient li ~ li {
          margin-right: 0.5em; }
          @media all and (max-width: 800px) {
            .box__area_patient li ~ li {
              margin-right: 0; } }
          @media all and (max-width: 700px) {
            .box__area_patient li ~ li {
              float: left;
              margin-left: 0.5em;
              left: -50%;
              position: relative; } }
      .box__area_patient_icon {
        display: block;
        width: 82px;
        height: 82px;
        background-color: #ffe613;
        border-radius: 50%;
        position: relative; }
        .box__area_patient_icon img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
        @media all and (max-width: 768px) {
          .box__area_patient_icon {
            width: 62px;
            height: 62px;
            position: relative; }
            .box__area_patient_icon img {
              width: 65%; } }
        @media all and (max-width: 700px) {
          .box__area_patient_icon {
            width: 52px;
            height: 52px; } }
      .box__area_patient_txt {
        font-size: 0.63158em; }
    .box__area_content {
      /* -- mixin -- */
      /* -- mixin -- */ }
      .box__area_content-2column {
        height: 100%; }
        .box__area_content-2column:after {
          content: "";
          clear: both;
          display: block; }
      .box__area_content_img {
        padding-top: 2em;
        margin-bottom: 3em; }
        @media all and (max-width: 768px) {
          .box__area_content_img img {
            max-height: 200px; }
            .box__area_content_img img.madani_bkyoki_adj1 {
              width: 70%; } }
        @media all and (max-width: 700px) {
          .box__area_content_img {
            margin-bottom: 2em; }
            .box__area_content_img img.madani_bkyoki_adj1 {
              width: 75%; }
            .box__area_content_img img.madani_bkyoki_adj2 {
              width: 40%; } }
        .box__area_content_img-left {
          width: 40%;
          float: left; }
          @media all and (max-width: 700px) {
            .box__area_content_img-left {
              width: 55%;
              float: none;
              margin: 0 auto 2em; } }
        .box__area_content_img-right {
          width: 40%;
          float: right; }
          @media all and (max-width: 700px) {
            .box__area_content_img-right {
              width: 70%;
              float: none;
              margin: auto; } }
          .box__area_content_img-right .adj {
            margin-top: 15%; }
            @media all and (max-width: 700px) {
              .box__area_content_img-right .adj {
                margin: auto; } }
      .box__area_content_headdeco {
        width: 50%;
        letter-spacing: 0; }
        @media all and (max-width: 700px) {
          .box__area_content_headdeco {
            width: 100%; } }
        @media all and (max-width: 700px) {
          .box__area_content_headdeco {
            width: 100%; } }
      .box__area_content_head {
        margin: 0 auto 1em;
        width: 36%;
        min-width: 360px; }
        .box__area_content_head span {
          display: inline-block;
          color: #ff671f;
          border: 3px solid #ff671f;
          border-radius: 2em;
          margin: auto;
          padding: 0.25em 1em;
          width: 100%; }
          @media all and (max-width: 768px) {
            .box__area_content_head span {
              width: 100%;
              min-width: auto; } }
        @media all and (max-width: 700px) {
          .box__area_content_head {
            width: auto;
            min-width: auto; } }
        .box__area_content_head-left {
          margin: 0 auto 1em;
          width: 50%;
          margin: 0 0 2em 0;
          float: left; }
          .box__area_content_head-left span {
            display: inline-block;
            color: #ff671f;
            border: 3px solid #ff671f;
            border-radius: 2em;
            margin: auto;
            padding: 0.25em 1em;
            width: 100%; }
            @media all and (max-width: 768px) {
              .box__area_content_head-left span {
                width: 100%;
                min-width: auto; } }
          @media all and (max-width: 700px) {
            .box__area_content_head-left {
              float: none;
              width: auto;
              margin: 0 auto 1em; } }
          .box__area_content_head-left span {
            max-width: 298px; }
        .box__area_content_head-right {
          margin: 0 auto 1em;
          width: 50%;
          margin: 0 0 2em 0;
          float: right; }
          .box__area_content_head-right span {
            display: inline-block;
            color: #ff671f;
            border: 3px solid #ff671f;
            border-radius: 2em;
            margin: auto;
            padding: 0.25em 1em;
            width: 100%; }
            @media all and (max-width: 768px) {
              .box__area_content_head-right span {
                width: 100%;
                min-width: auto; } }
          @media all and (max-width: 700px) {
            .box__area_content_head-right {
              float: none;
              width: auto;
              margin: 0 auto 1em; } }
      .box__area_content_txt {
        margin-bottom: 2em; }
        @media all and (max-width: 700px) {
          .box__area_content_txt {
            text-align: justify; }
            .box__area_content_txt .pc {
              display: none; } }
        .box__area_content_txt-left {
          float: left;
          width: 50%;
          text-align: justify;
          margin-bottom: 1em; }
          .box__area_content_txt-left p {
            margin: 0 auto 1.5em; }
            .box__area_content_txt-left p:last-child {
              margin: auto; }
          @media all and (max-width: 700px) {
            .box__area_content_txt-left {
              float: none;
              width: auto; } }
        .box__area_content_txt-right {
          float: right;
          width: 50%;
          text-align: justify;
          margin-bottom: 1em; }
          .box__area_content_txt-right p {
            margin: 0 auto 1.5em; }
            .box__area_content_txt-right p:last-child {
              margin: auto; }
          @media all and (max-width: 700px) {
            .box__area_content_txt-right {
              float: none;
              width: auto; } }
      .box__area_content_list {
        text-align: left;
        padding-left: 1em;
        text-indent: -1em;
        line-height: 1.6;
        margin-bottom: 1em; }
        .box__area_content_list li {
          margin-bottom: 0.5em; }
          .box__area_content_list li:before {
            content: "・";
            font-feature-settings: normal; }

.fig {
  /* mixin */
  max-width: 1000px;
  margin: auto; }
  .fig-clear {
    max-width: 1000px;
    margin: auto; }
    .fig-clear:after {
      content: "";
      clear: both;
      display: block; }
  .fig-find {
    max-width: 1000px;
    margin: auto; }
    @media all and (max-width: 560px) {
      .fig-find {
        width: 60%; } }
    @media all and (max-width: 375px) {
      .fig-find {
        width: 75%; } }
    .fig-find-clear {
      max-width: 1000px;
      margin: auto; }
      @media all and (max-width: 560px) {
        .fig-find-clear {
          width: 60%; } }
      @media all and (max-width: 375px) {
        .fig-find-clear {
          width: 75%; } }
      .fig-find-clear:after {
        content: "";
        clear: both;
        display: block; }
  .fig__area {
    /* mixin */
    /* mixin */ }
    .fig__area_img {
      background-color: #fff;
      border-radius: 2.5em;
      width: 100%;
      height: 100%;
      position: relative; }
      @media all and (max-width: 560px) {
        .fig__area_img {
          border-radius: 1.5em; } }
      .fig__area_img img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto; }
        @media all and (max-width: 1060px) {
          .fig__area_img img {
            width: 50%; } }
      .fig__area_img:after {
        content: "";
        display: block;
        background-image: url(../svg/fig_find_arrow.svg);
        background-position: center center;
        background-size: cover;
        position: absolute;
        width: 8.53242%;
        height: 13.65188%;
        right: -15.69966%;
        top: 50%;
        transform: translateY(-50%); }
        @media all and (max-width: 560px) {
          .fig__area_img:after {
            display: none; } }
    .fig__area_txt {
      line-height: 1.4;
      margin-top: 1em; }
      @media all and (max-width: 560px) {
        .fig__area_txt {
          position: relative; }
          .fig__area_txt:after {
            content: "";
            display: block;
            background-image: url(../svg/fig_find_arrow-down.svg);
            background-position: center center;
            background-size: contain;
            width: 100%;
            height: 20px;
            margin-top: 1em; } }
    @media all and (max-width: 560px) and (max-width: 375px) {
      .fig__area_txt:after {
        height: 15px; } }
    .fig__area-find {
      width: 29.3%;
      margin-right: 6%;
      float: left; }
      .fig__area-find:nth-of-type(3n) {
        margin-right: 0; }
        .fig__area-find:nth-of-type(3n) dt:after {
          display: none; }
      @media all and (max-width: 560px) {
        .fig__area-find {
          width: 100%;
          float: none;
          margin-right: 0px;
          margin-bottom: 6%; }
          .fig__area-find:last-of-type dd:after {
            display: none; } }
      .fig__area-find_img {
        background-color: #fff;
        border-radius: 2.5em;
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 100%; }
        @media all and (max-width: 560px) {
          .fig__area-find_img {
            border-radius: 1.5em; } }
        .fig__area-find_img img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
          @media all and (max-width: 1060px) {
            .fig__area-find_img img {
              width: 50%; } }
        .fig__area-find_img:after {
          content: "";
          display: block;
          background-image: url(../svg/fig_find_arrow.svg);
          background-position: center center;
          background-size: cover;
          position: absolute;
          width: 8.53242%;
          height: 13.65188%;
          right: -15.69966%;
          top: 50%;
          transform: translateY(-50%); }
          @media all and (max-width: 560px) {
            .fig__area-find_img:after {
              display: none; } }
        .fig__area-find_img span {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; }
        .fig__area-find_img-adj1 {
          background-color: #fff;
          border-radius: 2.5em;
          width: 100%;
          height: 100%;
          position: relative;
          padding-top: 100%; }
          @media all and (max-width: 560px) {
            .fig__area-find_img-adj1 {
              border-radius: 1.5em; } }
          .fig__area-find_img-adj1 img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto; }
            @media all and (max-width: 1060px) {
              .fig__area-find_img-adj1 img {
                width: 50%; } }
          .fig__area-find_img-adj1:after {
            content: "";
            display: block;
            background-image: url(../svg/fig_find_arrow.svg);
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 8.53242%;
            height: 13.65188%;
            right: -15.69966%;
            top: 50%;
            transform: translateY(-50%); }
            @media all and (max-width: 560px) {
              .fig__area-find_img-adj1:after {
                display: none; } }
          .fig__area-find_img-adj1 span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; }
          .fig__area-find_img-adj1 img {
            left: 10%; }
            @media all and (max-width: 1060px) {
              .fig__area-find_img-adj1 img {
                width: 70%; } }
        .fig__area-find_img-adj2 {
          background-color: #fff;
          border-radius: 2.5em;
          width: 100%;
          height: 100%;
          position: relative;
          padding-top: 100%; }
          @media all and (max-width: 560px) {
            .fig__area-find_img-adj2 {
              border-radius: 1.5em; } }
          .fig__area-find_img-adj2 img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto; }
            @media all and (max-width: 1060px) {
              .fig__area-find_img-adj2 img {
                width: 50%; } }
          .fig__area-find_img-adj2:after {
            content: "";
            display: block;
            background-image: url(../svg/fig_find_arrow.svg);
            background-position: center center;
            background-size: cover;
            position: absolute;
            width: 8.53242%;
            height: 13.65188%;
            right: -15.69966%;
            top: 50%;
            transform: translateY(-50%); }
            @media all and (max-width: 560px) {
              .fig__area-find_img-adj2:after {
                display: none; } }
          .fig__area-find_img-adj2 span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; }
          .fig__area-find_img-adj2 img {
            top: -10%; }
      .fig__area-find_txt {
        line-height: 1.4;
        margin-top: 1em; }
        @media all and (max-width: 560px) {
          .fig__area-find_txt {
            position: relative; }
            .fig__area-find_txt:after {
              content: "";
              display: block;
              background-image: url(../svg/fig_find_arrow-down.svg);
              background-position: center center;
              background-size: contain;
              width: 100%;
              height: 20px;
              margin-top: 1em; } }
    @media all and (max-width: 560px) and (max-width: 375px) {
      .fig__area-find_txt:after {
        height: 15px; } }

.fig {
  /* mixin */ }
  .fig-nomilife {
    max-width: 1000px;
    margin: auto;
    position: relative; }
    .fig-nomilife__img {
      display: inline-block;
      width: 76.1%;
      margin: auto; }
      .fig-nomilife__img img {
        -webkit-tap-highlight-color: transparent; }
      .fig-nomilife__img area {
        outline: none; }
      @media all and (max-width: 560px) {
        .fig-nomilife__img {
          width: 100%; } }
    .fig-nomilife__info {
      font-size: 0.78947em;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1em 2em;
      border: 2px dotted #776c66;
      border-radius: 1em; }
      .fig-nomilife__info img {
        vertical-align: middle;
        margin-right: 0.25em;
        width: 2em; }
      @media all and (max-width: 560px) {
        .fig-nomilife__info {
          position: relative;
          display: inline-block;
          margin: 2em auto 0; }
          .fig-nomilife__info br {
            display: none; } }

.fig {
  /* mixin */ }
  .fig-danilife {
    max-width: 1000px;
    margin: auto;
    position: relative; }
    .fig-danilife__img {
      margin: auto; }
      .fig-danilife__img img {
        -webkit-tap-highlight-color: transparent; }
      .fig-danilife__img area {
        outline: none;
        cursor: pointer; }
      .fig-danilife__img .sp {
        display: none; }
      @media all and (max-width: 768px) {
        .fig-danilife__img .pc {
          display: none; }
        .fig-danilife__img .sp {
          display: inline; } }
    .fig-danilife__info {
      max-width: 906px;
      text-align: left;
      margin: 0 auto 3.5%; }
      .fig-danilife__info span {
        font-size: 0.78947em;
        display: inline-block;
        padding: 1em 2em;
        border: 2px dotted #776c66;
        border-radius: 1em; }
      .fig-danilife__info img {
        vertical-align: middle;
        margin-right: 0.25em;
        width: 2em; }
      @media all and (max-width: 768px) {
        .fig-danilife__info {
          text-align: center; } }

.fig {
  /* mixin */ }
  .fig-nomiwhere {
    position: relative;
    margin: 0 auto 14%;
    line-height: 1; }
    @media all and (max-width: 768px) {
      .fig-nomiwhere {
        display: none; } }
    .fig-nomiwhere__base {
      display: inline-block;
      width: 100%; }
    .fig-nomiwhere__balloon li {
      display: inline-block;
      position: absolute;
      width: 19.7%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-nomiwhere__balloon li:nth-of-type(1) {
        top: 43.8%;
        left: 13.6%; }
      .fig-nomiwhere__balloon li:nth-of-type(2) {
        top: 44.8%;
        left: 49.4%; }
      .fig-nomiwhere__balloon li:nth-of-type(3) {
        top: 56.4%;
        left: 70.8%; }
      .fig-nomiwhere__balloon li:nth-of-type(4) {
        transform-origin: top center;
        top: 100.5%;
        left: 17.5%; }
      .fig-nomiwhere__balloon li:nth-of-type(5) {
        transform-origin: top center;
        top: 100.5%;
        left: 38.7%; }
      .fig-nomiwhere__balloon li.on {
        transform: scale(1); }
  .fig-nomiwhere-sp {
    max-width: 644px;
    position: relative;
    margin: 0 auto 6%;
    line-height: 1;
    display: none; }
    @media all and (max-width: 768px) {
      .fig-nomiwhere-sp {
        display: block; } }
    .fig-nomiwhere-sp .tmp {
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0; }
    .fig-nomiwhere-sp__base {
      display: inline-block;
      width: 100%; }
    .fig-nomiwhere-sp__balloon_num li {
      display: inline-block;
      position: absolute;
      width: 5.3%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-nomiwhere-sp__balloon_num li:nth-of-type(1) {
        top: 57.4%;
        left: 17.9%; }
      .fig-nomiwhere-sp__balloon_num li:nth-of-type(2) {
        top: 61.1%;
        left: 31.8%; }
      .fig-nomiwhere-sp__balloon_num li:nth-of-type(3) {
        top: 79.5%;
        left: 47.3%; }
      .fig-nomiwhere-sp__balloon_num li:nth-of-type(4) {
        top: 53%;
        left: 63.7%; }
      .fig-nomiwhere-sp__balloon_num li:nth-of-type(5) {
        top: 69.5%;
        left: 73.4%; }
      .fig-nomiwhere-sp__balloon_num li.on {
        transform: scale(1); }
    .fig-nomiwhere-sp__balloon {
      max-width: 644px;
      margin: 0 auto 8%;
      display: none; }
      @media all and (max-width: 768px) {
        .fig-nomiwhere-sp__balloon {
          display: block; } }
      .fig-nomiwhere-sp__balloon:after {
        content: "";
        clear: both;
        display: block; }
      .fig-nomiwhere-sp__balloon li {
        float: left;
        width: 50.93168%;
        margin: 0 1.24224% 1.24224% 0;
        transform: scale(0);
        transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.2), margin-top 200ms ease 500ms; }
        .fig-nomiwhere-sp__balloon li img {
          width: 100%; }
        .fig-nomiwhere-sp__balloon li:nth-of-type(2n) {
          width: 47.82609%;
          margin-right: 0; }
        .fig-nomiwhere-sp__balloon li.on {
          transform: scale(1); }

.fig {
  /* mixin */ }
  .fig-daniwhere {
    position: relative;
    margin: 0 auto 14%;
    line-height: 1; }
    @media all and (max-width: 768px) {
      .fig-daniwhere {
        display: none; } }
    .fig-daniwhere__base {
      display: inline-block;
      width: 100%; }
    .fig-daniwhere__balloon li {
      display: inline-block;
      position: absolute;
      width: 18.6%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-daniwhere__balloon li:nth-of-type(1) {
        top: 26.8%;
        left: 8%; }
      .fig-daniwhere__balloon li:nth-of-type(2) {
        top: 32%;
        left: 33.9%; }
      .fig-daniwhere__balloon li:nth-of-type(3) {
        top: 53.4%;
        left: 58.8%; }
      .fig-daniwhere__balloon li:nth-of-type(4) {
        width: 16.1%;
        top: 24.5%;
        left: 81.2%; }
      .fig-daniwhere__balloon li.on {
        transform: scale(1); }
  .fig-daniwhere-sp {
    max-width: 644px;
    position: relative;
    margin: 0 auto 6%;
    line-height: 1;
    display: none; }
    @media all and (max-width: 768px) {
      .fig-daniwhere-sp {
        display: block; } }
    .fig-daniwhere-sp__base {
      display: inline-block;
      width: 100%; }
    .fig-daniwhere-sp__balloon_num li {
      display: inline-block;
      position: absolute;
      width: 5.3%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-daniwhere-sp__balloon_num li:nth-of-type(1) {
        top: 53.4%;
        left: 18.2%; }
      .fig-daniwhere-sp__balloon_num li:nth-of-type(2) {
        top: 43.1%;
        left: 40.8%; }
      .fig-daniwhere-sp__balloon_num li:nth-of-type(3) {
        top: 63.5%;
        left: 65.5%; }
      .fig-daniwhere-sp__balloon_num li:nth-of-type(4) {
        top: 25%;
        left: 86.9%; }
      .fig-daniwhere-sp__balloon_num li.on {
        transform: scale(1); }
    .fig-daniwhere-sp__balloon {
      max-width: 644px;
      margin: 0 auto 8%;
      display: none; }
      @media all and (max-width: 768px) {
        .fig-daniwhere-sp__balloon {
          display: block; } }
      .fig-daniwhere-sp__balloon:after {
        content: "";
        clear: both;
        display: block; }
      .fig-daniwhere-sp__balloon li {
        float: left;
        width: 50.93168%;
        margin: 0 1.24224% 1.24224% 0;
        transform: scale(0);
        transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.2), margin-top 200ms ease 500ms; }
        .fig-daniwhere-sp__balloon li img {
          width: 100%; }
        .fig-daniwhere-sp__balloon li:nth-of-type(2n) {
          width: 47.82609%;
          margin-right: 0; }
        .fig-daniwhere-sp__balloon li.on {
          transform: scale(1); }

.fig {
  /* mixin */ }
  .fig-nomiplace {
    position: relative;
    margin: 0 auto 14%;
    line-height: 1; }
    @media all and (max-width: 768px) {
      .fig-nomiplace {
        display: none; } }
    .fig-nomiplace .tmp {
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0; }
    .fig-nomiplace__base {
      display: inline-block;
      width: 100%; }
    .fig-nomiplace__balloon li {
      display: inline-block;
      position: absolute;
      width: 18.6%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-nomiplace__balloon li:nth-of-type(1) {
        transform-origin: top center;
        top: 97.6%;
        left: 10%; }
      .fig-nomiplace__balloon li:nth-of-type(2) {
        top: 50.1%;
        left: 28.5%; }
      .fig-nomiplace__balloon li:nth-of-type(3) {
        top: 78.1%;
        left: 51.9%; }
      .fig-nomiplace__balloon li:nth-of-type(4) {
        width: 14.4%;
        top: 61.5%;
        left: 71.5%; }
      .fig-nomiplace__balloon li:nth-of-type(5) {
        transform-origin: top center;
        width: 16.7%;
        top: 99.9%;
        left: 74.5%; }
      .fig-nomiplace__balloon li.on {
        transform: scale(1); }
  .fig-nomiplace-sp {
    max-width: 644px;
    position: relative;
    margin: 0 auto 6%;
    line-height: 1;
    display: none; }
    @media all and (max-width: 768px) {
      .fig-nomiplace-sp {
        display: block; } }
    .fig-nomiplace-sp .tmp {
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0; }
    .fig-nomiplace-sp__base {
      display: inline-block;
      width: 100%; }
    .fig-nomiplace-sp__balloon_num li {
      display: inline-block;
      position: absolute;
      width: 5.3%;
      transform: scale(0);
      transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
      transform-origin: bottom center; }
      .fig-nomiplace-sp__balloon_num li:nth-of-type(1) {
        top: 55.4%;
        left: 10%; }
      .fig-nomiplace-sp__balloon_num li:nth-of-type(2) {
        top: 57.3%;
        left: 34.2%; }
      .fig-nomiplace-sp__balloon_num li:nth-of-type(3) {
        top: 80.6%;
        left: 58.3%; }
      .fig-nomiplace-sp__balloon_num li:nth-of-type(4) {
        top: 68%;
        left: 78.5%; }
      .fig-nomiplace-sp__balloon_num li:nth-of-type(5) {
        top: 83.6%;
        left: 89.1%; }
      .fig-nomiplace-sp__balloon_num li.on {
        transform: scale(1); }
    .fig-nomiplace-sp__balloon {
      max-width: 644px;
      margin: 0 auto 8%;
      display: none; }
      @media all and (max-width: 768px) {
        .fig-nomiplace-sp__balloon {
          display: block; } }
      .fig-nomiplace-sp__balloon:after {
        content: "";
        clear: both;
        display: block; }
      .fig-nomiplace-sp__balloon li {
        float: left;
        width: 50.93168%;
        margin: 0 1.24224% 1.24224% 0;
        transform: scale(0);
        transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.2), margin-top 200ms ease 500ms; }
        .fig-nomiplace-sp__balloon li img {
          width: 100%; }
        .fig-nomiplace-sp__balloon li:nth-of-type(2n) {
          width: 47.82609%;
          margin-right: 0; }
        .fig-nomiplace-sp__balloon li.on {
          transform: scale(1); }

.fig {
  /* mixin */ }
  .fig-nomijump {
    position: relative;
    display: inline-block; }
    .fig-nomijump .spacing {
      opacity: 0; }
    .fig-nomijump__dog {
      display: inline-block;
      width: 23.4%;
      position: absolute;
      top: 13.8%;
      left: 0.4%; }
    .fig-nomijump__nomi {
      display: inline-block;
      width: 15.6%;
      position: absolute;
      top: 0%;
      left: 28.4%;
      opacity: 0; }
      .fig-nomijump__nomi.on {
        opacity: 1;
        animation: nomijump_nomi_X 3s ease; }
      .fig-nomijump__nomi-wrap.on {
        display: block;
        animation: nomijump_nomi_Y 3s ease; }
      .fig-nomijump__nomi-bound.on {
        display: block;
        animation: nomijump_nomi_bound 3s ease; }
    .fig-nomijump__line {
      display: inline-block;
      width: 49.9%;
      height: 75%;
      position: absolute;
      top: 21.7%;
      right: 0.4%;
      text-align: right;
      overflow: hidden;
      opacity: 0; }
      .fig-nomijump__line.on {
        opacity: 1;
        animation: nomijump_nomi_line 3s ease; }
      .fig-nomijump__line-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0; }
        .fig-nomijump__line-wrap.on {
          animation: nomijump_nomi_line-img 3s ease; }

@keyframes nomijump_nomi_X {
  0% {
    transform: translateX(450%); }
  25% {
    transform: translateX(270%); }
  30% {
    transform: translateX(270%); }
  70% {
    transform: translateX(0%); } }

@keyframes nomijump_nomi_Y {
  0% {
    transform: translateY(10%); }
  30% {
    transform: translateY(150%); }
  55% {
    transform: translateY(0); } }

@keyframes nomijump_nomi_bound {
  0% {
    transform: scale(1, 1); }
  20% {
    transform: scale(1, 1); }
  30% {
    transform: scale(1.2, 0.8); }
  40% {
    transform: scale(0.8, 1.2); }
  70% {
    transform: scale(1, 1); } }

@keyframes nomijump_nomi_line {
  0% {
    transform: translateX(99.8%); }
  25% {
    transform: translateX(60.6784%); }
  30% {
    transform: translateX(60.6784%); }
  70% {
    transform: translateX(0); } }

@keyframes nomijump_nomi_line-img {
  0% {
    transform: translateX(-100%); }
  25% {
    transform: translateX(-61%); }
  30% {
    transform: translateX(-61%); }
  70% {
    transform: translateX(0); } }

.fig {
  /* mixin */ }
  .fig-donotpull {
    position: relative;
    max-width: 650px;
    margin: 0 auto 5.8%; }
    .fig-donotpull__dani {
      display: inline-block;
      width: 50%;
      position: absolute;
      top: 0%;
      left: 23%;
      z-index: -1;
      animation: dani_pull 2.5s infinite normal; }
    .fig-donotpull__base_parts {
      position: absolute;
      display: inline-block;
      width: 30.8%;
      left: 32.5%;
      top: 71%;
      line-height: 1;
      transform: scale(1, 0);
      transform-origin: bottom;
      animation: base_parts 2.5s infinite normal; }
    .fig-donotpull__x {
      display: inline-block;
      width: 17%;
      position: absolute;
      top: 53.2%;
      left: 60.1%;
      opacity: 1;
      animation: donotpull_x 1s 6; }

@keyframes dani_pull {
  0% {
    margin-top: 0; }
  50% {
    margin-top: -5%; }
  80% {
    margin-top: -5%; }
  100% {
    margin-top: 0; } }

@keyframes base_parts {
  0% {
    transform: scale(1, 0); }
  50% {
    transform: scale(1, 1); }
  80% {
    transform: scale(1, 1); }
  100% {
    transform: scale(1, 0); } }

@keyframes donotpull_x {
  50% {
    opacity: 0; } }

.fig {
  /* mixin */ }
  .fig-nomiact {
    line-height: 0;
    font-size: 0;
    position: relative; }
    @media (max-width: 687px) {
      .fig-nomiact {
        display: none; } }
    .fig-nomiact__nomi {
      position: absolute;
      top: 48%;
      left: 17%;
      display: inline-block;
      width: 12.8%;
      animation: nomiact 1s steps(1) infinite normal; }
    .fig-nomiact__dani {
      position: absolute;
      top: 66%;
      left: 68%;
      display: inline-block;
      width: 8.5%;
      animation: daniact 4s steps(1) infinite normal; }
    .fig-nomiact__leaf {
      position: absolute;
      top: 84%;
      left: 64.2%;
      display: inline-block;
      width: 17.7%; }
  .fig-nomiactsp {
    line-height: 0;
    font-size: 0;
    display: block;
    position: relative; }
    @media (min-width: 687px) {
      .fig-nomiactsp {
        display: none; } }
    .fig-nomiactsp__nomi {
      position: absolute;
      top: 48%;
      left: 21%;
      display: inline-block;
      width: 12.8%;
      animation: nomiact 1s steps(1) infinite normal; }
    .fig-nomiactsp__dani {
      position: absolute;
      top: 67.6%;
      left: 72.6%;
      display: inline-block;
      width: 10.1%;
      animation: daniact 4s steps(1) infinite normal; }
    .fig-nomiactsp__leaf {
      position: absolute;
      top: 84.4%;
      left: 73.9%;
      display: inline-block;
      width: 20.3%; }

@keyframes nomiact {
  50% {
    transform: translate(0, 0) rotate(0deg) scale(-1, 1); } }

@keyframes daniact {
  50% {
    transform: translate(20%, 0) rotate(0deg) scale(-1, 1); } }

.qa {
  counter-reset: qNum 0; }
  .qa dt {
    color: #ff671f;
    padding-left: 11.5%;
    margin-bottom: 0.75em;
    text-align: left;
    vertical-align: middle;
    position: relative; }
    @media all and (max-width: 768px) {
      .qa dt {
        padding-left: 0; } }
    @media all and (max-width: 560px) {
      .qa dt {
        margin-top: 5em;
        line-height: 1.6;
        text-align: center; }
        .qa dt span {
          display: inline-block; } }
    .qa dt ~ dt {
      margin-top: 3.52632em; }
      @media all and (max-width: 560px) {
        .qa dt ~ dt {
          margin-top: 5em; } }
    .qa dt:before {
      color: #fff;
      font-size: 1.63158em;
      line-height: 1;
      font-family: 'Comfortaa', cursive;
      -webkit-text-stroke: 1px #fff;
      counter-increment: qNum 1;
      content: "Q" counter(qNum);
      display: inline-block;
      margin: 0 0.5em 0 0;
      padding: 0.25em 0.5em;
      background-color: #ff671f;
      border-radius: 1em; }
      @media all and (max-width: 560px) {
        .qa dt:before {
          position: absolute;
          top: -1.75em;
          left: 50%;
          transform: translateX(-50%); } }
  .qa dd {
    padding: 1.5em 5em;
    background-color: #fff;
    border-radius: 2.5em; }
    @media all and (max-width: 1060px) {
      .qa dd {
        padding: 1.5em 3em; } }
    @media all and (max-width: 768px) {
      .qa dd {
        padding: 1.5em 2em;
        border-radius: 1.5em;
        text-align: justify; } }

.paging {
  /* mixin */
  margin: 8em auto 0;
  overflow: hidden; }
  .paging ul {
    float: left;
    left: 50%;
    position: relative; }
    .paging ul:after {
      content: "";
      clear: both;
      display: block; }
    @media all and (max-width: 990px) {
      .paging ul {
        float: none;
        left: auto; } }
  .paging li {
    float: left;
    left: -50%;
    position: relative;
    margin: 0 2.10526em; }
    @media all and (max-width: 990px) {
      .paging li {
        float: none;
        left: auto;
        margin: 0 2.10526em 2.10526em; } }
  .paging-form {
    margin: 8em auto 0;
    overflow: hidden;
    position: relative;
    margin: 6em -2.10526em 2em; }
    .paging-form ul {
      float: left;
      left: 50%;
      position: relative; }
      .paging-form ul:after {
        content: "";
        clear: both;
        display: block; }
      @media all and (max-width: 990px) {
        .paging-form ul {
          float: none;
          left: auto; } }
    .paging-form li {
      float: left;
      left: -50%;
      position: relative;
      margin: 0 2.10526em; }
      @media all and (max-width: 990px) {
        .paging-form li {
          float: none;
          left: auto;
          margin: 0 2.10526em 2.10526em; } }
    @media all and (max-width: 560px) {
      .paging-form {
        margin: 4em auto 0; } }
    .paging-form ul {
      float: none;
      left: auto; }
    .paging-form li {
      float: none;
      left: auto;
      display: inline-block;
      width: 37.93103%; }
      @media all and (max-width: 768px) {
        .paging-form li {
          width: 34.48276%; } }
      @media all and (max-width: 560px) {
        .paging-form li {
          width: 50%;
          margin: 0 auto 2.10526em; } }
      @media all and (max-width: 470px) {
        .paging-form li {
          width: 100%; } }

#lifemodal_wrap {
  /* mixin */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all 200ms ease;
  display: none; }
  #lifemodal_wrap.bg {
    background-color: rgba(0, 0, 0, 0.5); }

.lifemodal__window-seichu, .lifemodal__window-sanagi, .lifemodal__window-youchu, .lifemodal__window-tamago {
  width: 95%;
  max-width: 575px;
  padding: 4em;
  background-color: #fff;
  border-radius: 2.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  display: none; }
  .lifemodal__window-seichu:after, .lifemodal__window-sanagi:after, .lifemodal__window-youchu:after, .lifemodal__window-tamago:after {
    content: "";
    clear: both;
    display: block; }
  @media all and (max-width: 1060px) {
    .lifemodal__window-seichu, .lifemodal__window-sanagi, .lifemodal__window-youchu, .lifemodal__window-tamago {
      width: 55%; } }
  @media all and (max-width: 768px) {
    .lifemodal__window-seichu, .lifemodal__window-sanagi, .lifemodal__window-youchu, .lifemodal__window-tamago {
      width: 70%;
      padding: 3em; } }
  @media all and (max-width: 560px) {
    .lifemodal__window-seichu, .lifemodal__window-sanagi, .lifemodal__window-youchu, .lifemodal__window-tamago {
      padding: 2em;
      border-radius: 1.5em; } }

.lifemodal__window-houketsu, .lifemodal__window-wakadani {
  width: 95%;
  max-width: 575px;
  padding: 4em;
  background-color: #fff;
  border-radius: 2.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  display: none; }
  .lifemodal__window-houketsu:after, .lifemodal__window-wakadani:after {
    content: "";
    clear: both;
    display: block; }
  @media all and (max-width: 1060px) {
    .lifemodal__window-houketsu, .lifemodal__window-wakadani {
      width: 55%; } }
  @media all and (max-width: 768px) {
    .lifemodal__window-houketsu, .lifemodal__window-wakadani {
      width: 70%;
      padding: 3em; } }
  @media all and (max-width: 560px) {
    .lifemodal__window-houketsu, .lifemodal__window-wakadani {
      padding: 2em;
      border-radius: 1.5em; } }

.lifemodal__window_head {
  font-size: 1.78947em;
  margin-bottom: 0.75em;
  line-height: 1;
  color: #ff671f;
  text-align: left;
  padding-left: 40%; }
  @media all and (max-width: 560px) {
    .lifemodal__window_head {
      width: 100%;
      float: none;
      text-align: center;
      padding-left: 0;
      margin: 0 auto 1em; } }

.lifemodal__window_detail {
  display: table;
  vertical-align: middle; }

.lifemodal__window_txt {
  font-size: 0.89474em;
  width: 60%;
  text-align: justify;
  float: right; }
  @media all and (max-width: 560px) {
    .lifemodal__window_txt {
      font-size: 0.89474em;
      width: 100%;
      float: none; } }

.lifemodal__window_img {
  width: 35%;
  float: left; }
  @media all and (max-width: 768px) {
    .lifemodal__window_img img {
      max-width: 70%; } }
  @media all and (max-width: 560px) {
    .lifemodal__window_img {
      width: 45%;
      margin: 0 auto 1em;
      float: none; } }
  @media all and (max-width: 375px) {
    .lifemodal__window_img {
      width: 60%; } }

.lifemodal__window_close {
  display: block;
  width: 38px;
  height: 38px;
  background-color: #ff671f;
  border-radius: 25%;
  position: absolute;
  top: 7.5%;
  right: 5%;
  cursor: pointer; }
  .lifemodal__window_close img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  @media all and (max-width: 560px) {
    .lifemodal__window_close {
      top: 5%;
      width: 28px;
      height: 28px; } }
  @media all and (max-width: 375px) {
    .lifemodal__window_close {
      top: 4%;
      width: 20px;
      height: 20px; } }

.slider {
  /* mixin */ }
  .slider-wrap {
    max-width: 980px;
    margin: auto;
    padding: 0 50px; }
    @media all and (max-width: 600px) {
      .slider-wrap {
        padding: 30px; } }
  .slider-madani {
    width: 100%;
    margin: auto;
    background-color: #fff;
    border-radius: 2.5em;
    position: relative; }
    @media all and (max-width: 600px) {
      .slider-madani {
        border-radius: 1.5em; } }
    .slider-madani .slick-arrow {
      text-indent: -9999px;
      display: inline-block;
      background-color: #ff671f;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 140px;
      background-position: center center;
      z-index: 2; }
      @media all and (max-width: 600px) {
        .slider-madani .slick-arrow {
          width: 30px;
          background-size: 45%; } }
      .slider-madani .slick-arrow.slick-next {
        background-image: url(../svg/slider_btn_next.svg);
        right: -50px;
        border-radius: 0 2em 2em 0; }
        @media all and (max-width: 600px) {
          .slider-madani .slick-arrow.slick-next {
            right: -30px;
            border-radius: 0 1em 1em 0; } }
      .slider-madani .slick-arrow.slick-prev {
        background-image: url(../svg/slider_btn_prev.svg);
        left: -50px;
        border-radius: 2em 0 0 2em; }
        @media all and (max-width: 600px) {
          .slider-madani .slick-arrow.slick-prev {
            left: -30px;
            border-radius: 1em 0 0 1em; } }
    .slider-madani .slick-dots {
      position: absolute;
      line-height: 1;
      bottom: -40px;
      left: 0;
      width: 100%;
      text-align: center; }
      @media all and (max-width: 600px) {
        .slider-madani .slick-dots {
          bottom: -8%; } }
      .slider-madani .slick-dots li {
        display: inline-block;
        text-indent: -9999px;
        width: 12px;
        height: 12px;
        margin: 0 22px;
        border-radius: 50%;
        background-color: #005366; }
        .slider-madani .slick-dots li.slick-active {
          background-color: #ff671f; }
        @media all and (max-width: 600px) {
          .slider-madani .slick-dots li {
            width: 8px;
            height: 8px;
            margin: 0 5%; } }
    .slider-madani__slide {
      padding: 3em 4em;
      -webkit-tap-highlight-color: transparent;
      outline: none; }
      @media all and (max-width: 600px) {
        .slider-madani__slide {
          padding: 1.5em; } }
      .slider-madani__slide dl:after {
        content: "";
        clear: both;
        display: block; }
      .slider-madani__slide_head {
        width: 60%;
        margin-bottom: 0.5em;
        float: right;
        color: #ff671f;
        font-size: 1.26316em; }
        @media all and (max-width: 768px) {
          .slider-madani__slide_head {
            float: none;
            width: 100%;
            margin-bottom: 0; } }
      .slider-madani__slide_txt {
        width: 55%;
        text-align: justify;
        float: right; }
        .slider-madani__slide_txt p {
          margin-bottom: 0.5em; }
        @media all and (max-width: 768px) {
          .slider-madani__slide_txt {
            float: none;
            width: 100%; } }
        @media all and (max-width: 600px) {
          .slider-madani__slide_txt {
            line-height: 1.5;
            font-size: 0.94737em; } }
      .slider-madani__slide_img {
        width: 45%;
        float: left;
        text-align: center; }
        .slider-madani__slide_img img {
          display: inline !important;
          margin-left: -15%; }
          @media all and (max-width: 768px) {
            .slider-madani__slide_img img {
              margin-left: 0; } }
        @media all and (max-width: 768px) {
          .slider-madani__slide_img {
            float: none;
            width: 50%;
            margin: 1.5em auto 2em; } }
        @media all and (max-width: 600px) {
          .slider-madani__slide_img {
            width: 40%;
            margin: 0.5em auto 1em; } }

#medicinemodal_wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all 200ms ease;
  display: none; }
  #medicinemodal_wrap.bg {
    background-color: rgba(0, 0, 0, 0.5); }

.medicinemodal__window {
  width: 95%;
  max-width: 940px;
  padding: 5em 4em 4em;
  background-color: #fff;
  border-radius: 2.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  display: none; }
  @media all and (max-width: 1060px) {
    .medicinemodal__window {
      width: 70%; } }
  @media all and (max-width: 768px) {
    .medicinemodal__window {
      padding: 4em 3em 3em; } }
  @media all and (max-width: 560px) {
    .medicinemodal__window {
      padding: 4em 2em 3em;
      border-radius: 1.5em; } }
  .medicinemodal__window_ph {
    margin: 0 auto 1.5em; }
  .medicinemodal__window_txt {
    margin: 0 auto 1.25em;
    line-height: 1;
    font-size: 1.57895em;
    color: #005366; }
    @media all and (max-width: 560px) {
      .medicinemodal__window_txt {
        font-size: 1.26316em; } }
  .medicinemodal__window_close {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #ff671f;
    border-radius: 25%;
    position: absolute;
    top: 5%;
    right: 4%;
    cursor: pointer; }
    .medicinemodal__window_close img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
    @media all and (max-width: 1060px) {
      .medicinemodal__window_close {
        top: 4%;
        width: 32px;
        height: 32px; } }
    @media all and (max-width: 768px) {
      .medicinemodal__window_close {
        width: 28px;
        height: 28px; } }
    @media all and (max-width: 560px) {
      .medicinemodal__window_close {
        width: 24px;
        height: 24px; } }
    @media all and (max-width: 375px) {
      .medicinemodal__window_close {
        top: 5%;
        right: 5%;
        width: 20px;
        height: 20px; } }

.mapIndex {
  padding: 6.84211em 0 4.21053em;
  background-color: #fff; }
  @media all and (max-width: 1060px) {
    .mapIndex {
      margin: 0 -20px; } }
  .mapIndex__area {
    max-width: 1000px;
    margin: auto; }
    @media all and (max-width: 1060px) {
      .mapIndex__area {
        padding: 0 20px; } }
    .mapIndex__area h2 {
      margin: 0 auto 2.10526em; }
      .mapIndex__area h2 ~ h2 {
        margin: 9.47368em auto 2.10526em; }
    .mapIndex__area p {
      color: #005366; }
      .mapIndex__area p span {
        display: inline-block; }
      @media all and (max-width: 460px) {
        .mapIndex__area p {
          line-height: 1.6; } }
    .mapIndex__area_fig {
      /* mixin */
      display: inline-block;
      margin: 0 auto 3em;
      position: relative; }
      .mapIndex__area_fig img {
        width: 100%; }
      .mapIndex__area_fig-coming {
        display: inline-block;
        margin: 0 auto 3em;
        position: relative;
        /**
                  &:after{
                      content: "";
                      display: block;
                      width: 237/744*100+%;
                      height: 237/577*100+%;
                      background-image: url(../svg/coming.svg);
                      background-size: contain;
                      position: absolute;
                      top: -19/577*100+%;
                      left: -39/744*100+%;
                      @include query(880){left: 0;}
                  }
                ****/ }
        .mapIndex__area_fig-coming img {
          width: 100%; }
      .mapIndex__area_fig-balloon li {
        position: absolute;
        display: inline-block;
        transform: scale(0);
        transition: transform 500ms cubic-bezier(1, 0, 0.7, 1.6), margin-top 200ms ease 500ms;
        transform-origin: bottom center; }
        .mapIndex__area_fig-balloon li:nth-of-type(1) {
          width: 22.3%;
          top: 25.2%;
          left: 28.7%; }
        .mapIndex__area_fig-balloon li:nth-of-type(2) {
          width: 20.9%;
          top: 39.5%;
          left: 73.6%; }
        .mapIndex__area_fig-balloon li:nth-of-type(3) {
          width: 17.2%;
          top: 60.4%;
          left: 11.5%; }
        .mapIndex__area_fig-balloon li:nth-of-type(4) {
          width: 13.6%;
          top: 62.3%;
          left: 54%; }
        .mapIndex__area_fig-balloon li.on {
          transform: scale(1);
          margin-top: 0; }

.map-search {
  margin-bottom: 3.15789em;
  /* mixin */ }
  .map-search__head {
    font-size: 1.31579em;
    line-height: 1.2;
    color: #fff;
    padding: 1em;
    background-color: #ff671f;
    border-radius: 1em 1em 0 0;
    width: 46.5%;
    margin: 3.68421em auto 0; }
    @media all and (max-width: 768px) {
      .map-search__head {
        width: 70%; } }
    @media all and (max-width: 560px) {
      .map-search__head {
        border-radius: 0.75em 0.75em 0 0; } }
    @media all and (max-width: 768px) {
      .map-search__head {
        width: 100%;
        border-radius: .75em; } }
  .map-search__area {
    padding: 3em;
    background-color: #fff;
    border-radius: 2.5em;
    position: relative;
    padding: 11.5% 0 17%; }
    @media all and (max-width: 768px) {
      .map-search__area {
        background-color: transparent;
        padding-bottom: 0; }
        .map-search__area img {
          display: none; } }
    .map-search__area_wrap {
      position: relative;
      width: 74.4%;
      margin: auto; }
    .map-search__area_map-line {
      position: absolute;
      top: 0;
      left: 0; }
    .map-search__area_map-btns li {
      display: inline-block;
      width: 28.89785%;
      height: 11.90476%;
      position: absolute; }
      @media all and (max-width: 768px) {
        .map-search__area_map-btns li {
          position: relative;
          width: 100%;
          top: auto;
          left: auto;
          margin-bottom: 1em; } }
    .map-search__area_map-btns-hkd-thk {
      top: 3%;
      left: 42.2%; }
    .map-search__area_map-btns-knt {
      top: 99.7%;
      left: 76%; }
    .map-search__area_map-btns-chb {
      top: 23.6%;
      left: 35.5%; }
    .map-search__area_map-btns-kns {
      top: 95.7%;
      left: 34.5%; }
    .map-search__area_map-btns-ch-sk {
      top: 30.7%;
      left: 1%; }
    .map-search__area_map-btns-ky-okw {
      top: 99.7%;
      left: -5%; }

.map-madani {
  height: 750px;
  height: 70vh;
  border-radius: 50px; }
  @media all and (max-width: 600px) {
    .map-madani {
      border-radius: 20px; } }
  .map-madani__login {
    margin: 0 auto;
    line-height: 1.5; }
    .map-madani__login a {
      color: #ff671f;
      text-decoration: underline; }
    .map-madani__login .sp {
      display: none; }
    @media all and (max-width: 420px) {
      .map-madani__login .sp {
        display: inline; } }

/* infoBox */
.gmap {
  position: relative; }
  .gmap-info {
    font-size: 19px;
    width: 300px;
    padding: 25px 26px 25px 25px;
    border-radius: 30px;
    background-color: #fff;
    color: #776c66;
    text-align: justify; }
    @media all and (max-width: 600px) {
      .gmap-info {
        font-size: 15px;
        width: 224px !important;
        border-radius: 20px; } }
    .gmap-info > img {
      position: absolute !important;
      top: 17px !important;
      right: 20px !important;
      margin: auto !important; }
    .gmap-info:after {
      position: absolute;
      display: block;
      left: 0;
      bottom: -46px;
      width: 100%;
      height: 46px;
      background: url(../svg/map_balloon_tail.svg) no-repeat 0 0;
      background-position: center center;
      content: ''; }
    .gmap-info__block-wrap {
      height: auto;
      width: 100%;
      max-height: 300px;
      padding-right: 20px;
      overflow-y: auto;
      /*スクロールバー全体*/
      /*スクロールバーの軌道*/
      /*スクロールバーの動く部分*/ }
      @media all and (max-width: 600px) {
        .gmap-info__block-wrap {
          max-height: 200px;
          padding-right: 10px; } }
      .gmap-info__block-wrap::-webkit-scrollbar {
        width: 5px; }
      .gmap-info__block-wrap::-webkit-scrollbar-track {
        border-radius: 5px; }
      .gmap-info__block-wrap::-webkit-scrollbar-thumb {
        background-color: #b3b3b3;
        border-radius: 5px; }
    .gmap-info__block {
      padding: 0.25em 0 1.25em;
      margin-bottom: 1em;
      border-bottom: 2px dotted #776c66; }
      .gmap-info__block:after {
        content: "";
        clear: both;
        display: block; }
      .gmap-info__block:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none; }
      .gmap-info__block-innerwrap {
        width: 100%; }
        .gmap-info__block-innerwrap:after {
          content: "";
          clear: both;
          display: block; }
      .gmap-info__block-txt {
        width: 100%; }
        .gmap-info__block-txt-left {
          width: 50%;
          padding-right: 0.5em;
          float: left; }
      .gmap-info__block-ph {
        float: right;
        width: 50%;
        padding-left: 0.5em; }
      .gmap-info__block-fig {
        width: 100%;
        margin-top: 0.5em; }
    .gmap-info__title {
      color: #ff671f;
      font-weight: bold;
      margin: 0 auto 1.5em; }
    .gmap-info__head {
      color: #ff671f;
      font-size: 0.63158em;
      font-weight: bold;
      margin: 0.5em auto 0;
      line-height: 1.2; }
      .gmap-info__head:first-of-type {
        margin: 0 auto; }
    .gmap-info__txt {
      font-size: 0.63158em;
      font-weight: bold;
      margin: auto; }
    .gmap-info__ph img {
      max-width: 100% !important; }
    .gmap-info__fig {
      /* __fig */ }
      .gmap-info__fig-front {
        margin: auto;
        position: relative;
        width: 50%;
        float: left; }
        .gmap-info__fig-front img {
          max-width: 100% !important; }
      .gmap-info__fig-back {
        margin: auto;
        position: relative;
        width: 50%;
        float: left; }
        .gmap-info__fig-back img {
          max-width: 100% !important; }
      .gmap-info__fig-mark {
        /* mixin */ }
        .gmap-info__fig-mark-part1 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 8.5%;
          left: 17%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part1 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part2 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 8.5%;
          left: 62%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part2 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part3 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 18%;
          left: 40%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part3 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part4 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 29%;
          left: 29%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part4 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part5 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 29%;
          left: 53%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part5 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part6 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 39%;
          left: 40.5%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part6 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part7 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 40%;
          left: 8%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part7 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part8 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 40%;
          left: 73%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part8 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part9 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 62%;
          left: 40.5%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part9 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part10 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 80%;
          left: 28%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part10 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part11 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 80%;
          left: 53%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part11 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part12 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 8.5%;
          left: 17%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part12 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part13 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 8.5%;
          left: 62%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part13 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part14 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 53%;
          left: 40.5%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part14 {
              width: 14px;
              height: 14px; } }
        .gmap-info__fig-mark-part15 {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(255, 103, 31, 0.4);
          top: 69%;
          left: 40.5%; }
          @media all and (max-width: 600px) {
            .gmap-info__fig-mark-part15 {
              width: 14px;
              height: 14px; } }

#mapmodal_wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all 200ms ease;
  display: none; }
  #mapmodal_wrap.bg {
    background-color: rgba(0, 0, 0, 0.5); }

.mapmodal__window-hkd-thk, .mapmodal__window-knt, .mapmodal__window-chb, .mapmodal__window-kns, .mapmodal__window-ch-sk, .mapmodal__window-ky-okw {
  width: 95%;
  max-width: 950px;
  padding: 130px 167px;
  background-color: #fff;
  border-radius: 2.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  display: none; }
  .mapmodal__window-hkd-thk:after, .mapmodal__window-knt:after, .mapmodal__window-chb:after, .mapmodal__window-kns:after, .mapmodal__window-ch-sk:after, .mapmodal__window-ky-okw:after {
    content: "";
    clear: both;
    display: block; }
  @media all and (max-width: 1060px) {
    .mapmodal__window-hkd-thk, .mapmodal__window-knt, .mapmodal__window-chb, .mapmodal__window-kns, .mapmodal__window-ch-sk, .mapmodal__window-ky-okw {
      width: 75%;
      padding: 9.12281% 8.78947%; } }
  @media all and (max-width: 768px) {
    .mapmodal__window-hkd-thk, .mapmodal__window-knt, .mapmodal__window-chb, .mapmodal__window-kns, .mapmodal__window-ch-sk, .mapmodal__window-ky-okw {
      padding: 10% 10% 10%; } }
  @media all and (max-width: 375px) {
    .mapmodal__window-hkd-thk, .mapmodal__window-knt, .mapmodal__window-chb, .mapmodal__window-kns, .mapmodal__window-ch-sk, .mapmodal__window-ky-okw {
      border-radius: 1.5em;
      padding: 10% 5% 7.5%; } }

.mapmodal__window_head {
  font-size: 1.57895em;
  margin-bottom: 2em; }
  @media all and (max-width: 768px) {
    .mapmodal__window_head {
      margin-bottom: 1.5em;
      line-height: 1.4;
      font-size: 1.36842em;
      padding: 0 1em; }
      .mapmodal__window_head span {
        display: inline-block; } }
  @media all and (max-width: 375px) {
    .mapmodal__window_head {
      margin-bottom: 1em;
      padding: 0 2em; } }

.mapmodal__window_btn {
  width: 195px;
  height: 85px;
  display: table;
  margin: 0 15px 15px 0;
  float: left; }
  .mapmodal__window_btn:nth-of-type(3n) {
    margin-right: 0; }
  @media all and (max-width: 1060px) {
    .mapmodal__window_btn {
      width: 31.66667%;
      height: 65px;
      margin: 0 2.5% 2.5% 0; } }
  @media all and (max-width: 768px) {
    .mapmodal__window_btn {
      width: 47.5%;
      height: 45px;
      margin: 0 5% 5% 0; }
      .mapmodal__window_btn:nth-of-type(3n) {
        margin-right: 5%; }
      .mapmodal__window_btn:nth-of-type(2n) {
        margin-right: 0; } }
  @media all and (max-width: 375px) {
    .mapmodal__window_btn {
      height: 35px; } }

.mapmodal__window_close {
  display: block;
  width: 38px;
  height: 38px;
  background-color: #ff671f;
  border-radius: 25%;
  position: absolute;
  top: 7.5%;
  right: 5%;
  cursor: pointer; }
  .mapmodal__window_close img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  @media all and (max-width: 768px) {
    .mapmodal__window_close {
      top: 5%; } }
  @media all and (max-width: 375px) {
    .mapmodal__window_close {
      top: 5%;
      width: 28px;
      height: 28px; } }

.concerned {
  text-align: justify;
  width: 85.4%;
  padding: 1em 2.5em;
  margin: auto;
  background-color: #fff;
  border-radius: 1.5em;
  display: table;
  vertical-align: middle; }
  @media all and (max-width: 768px) {
    .concerned {
      width: 100%;
      padding: 2em; } }
  .concerned dt {
    display: table-cell;
    text-align: left;
    line-height: 1.4;
    padding: 0 1em 0 0;
    border-right: 0.2em dotted #7d7d7d; }
    @media all and (max-width: 1060px) {
      .concerned dt {
        text-align: center;
        width: 35%; } }
    @media all and (max-width: 768px) {
      .concerned dt {
        display: block;
        width: 100%;
        text-align: left;
        padding: 0 1em 1.5em 0;
        border-right: none;
        border-bottom: 3px dotted #7d7d7d;
        font-size: 1.15789em;
        line-height: 1; } }
  .concerned dd {
    font-size: 0.73684em;
    font-weight: normal;
    display: table-cell;
    padding: 1em 1em 1em 3em; }
    @media all and (max-width: 1060px) {
      .concerned dd {
        width: 65%; } }
    @media all and (max-width: 768px) {
      .concerned dd {
        display: block;
        width: 100%;
        padding: 2em 0em 0 0;
        font-size: 0.84211em; } }
    @media all and (max-width: 480px) {
      .concerned dd .pc {
        display: none; } }
    @media all and (max-width: 375px) {
      .concerned dd {
        font-size: 0.94737em;
        line-height: 1.6; } }
    .concerned dd a {
      color: #2496df;
      text-decoration: underline; }

.cities {
  /* mixin */
  /* mxin */ }
  .cities__head {
    font-size: 1.31579em;
    line-height: 1.2;
    color: #fff;
    padding: 1em;
    background-color: #ff671f;
    border-radius: 1em 1em 0 0;
    width: 46.5%;
    margin: 11% auto 0; }
    .cities__head span {
      display: inline-block; }
    @media all and (max-width: 768px) {
      .cities__head {
        width: 70%; } }
  .cities__area {
    padding: 7.5%;
    background-color: #fff;
    border-radius: 2.5em;
    position: relative; }
    @media all and (max-width: 560px) {
      .cities__area {
        padding: 10%;
        border-radius: 1.5em; } }
    @media all and (max-width: 768px) {
      .cities__area {
        font-size: 16px; } }
    @media all and (max-width: 560px) {
      .cities__area {
        font-size: 14px; } }
  .cities__note {
    text-align: right;
    font-size: 0.78947em;
    margin-bottom: 1em; }
  .cities__allCheck {
    margin-bottom: 1em; }
    .cities__allCheck input {
      display: none; }
    .cities__allCheck label {
      display: block;
      float: left;
      text-align: left;
      width: 25%;
      line-height: 1;
      vertical-align: middle;
      padding-right: 1em;
      padding-left: 2.25em;
      text-indent: -2.25em;
      margin-bottom: 1em;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent; }
      .cities__allCheck label.hover {
        color: #ff671f; }
      @media all and (max-width: 768px) {
        .cities__allCheck label {
          width: 33.33333%; } }
      @media all and (max-width: 560px) {
        .cities__allCheck label {
          width: 50%;
          margin-bottom: 1.5em;
          padding-left: 1.75em;
          text-indent: -1.75em; } }
      .cities__allCheck label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #ff671f;
        border-radius: 3px;
        margin-right: 1em; }
        @media all and (max-width: 768px) {
          .cities__allCheck label:before {
            border: 2px solid #ff671f; } }
        @media all and (max-width: 560px) {
          .cities__allCheck label:before {
            margin-right: 0.5em; } }
      .cities__allCheck label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #776c66;
        border-left: 3px solid #776c66;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .cities__allCheck label:after {
            border-bottom: 2px solid #776c66;
            border-left: 2px solid #776c66; } }
    .cities__allCheck input[type=checkbox] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #ff671f;
      border-radius: 3px;
      margin-right: 1em; }
      @media all and (max-width: 768px) {
        .cities__allCheck input[type=checkbox] + label:before {
          border: 2px solid #ff671f; } }
      @media all and (max-width: 560px) {
        .cities__allCheck input[type=checkbox] + label:before {
          margin-right: 0.5em; } }
    .cities__allCheck input[type=checkbox] + label:after {
      content: "";
      display: block;
      width: 0.75em;
      height: 0.5em;
      border-bottom: 3px solid #776c66;
      border-left: 3px solid #776c66;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(34%, 45%) rotate(-45deg) scale(0); }
      @media all and (max-width: 768px) {
        .cities__allCheck input[type=checkbox] + label:after {
          border-bottom: 2px solid #776c66;
          border-left: 2px solid #776c66; } }
    .cities__allCheck input[type=radio] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #ff671f;
      border-radius: 50%;
      margin-right: 1em; }
      @media all and (max-width: 768px) {
        .cities__allCheck input[type=radio] + label:before {
          border: 2px solid #ff671f; } }
      @media all and (max-width: 560px) {
        .cities__allCheck input[type=radio] + label:before {
          margin-right: 0.5em; } }
    .cities__allCheck input[type=radio] + label:after {
      content: "";
      display: block;
      width: 0.6em;
      height: 0.6em;
      background-color: #776c66;
      border-radius: 50%;
      position: absolute;
      left: 0.625em;
      top: 0.625em;
      border: none;
      transform: translate(-0.3em, -0.3em) scale(0); }
    .cities__allCheck .disable {
      color: #c9c9c9 !important; }
      .cities__allCheck .disable:before {
        display: none !important; }
      .cities__allCheck .disable:after {
        display: none !important; }
    .cities__allCheck input:checked + label {
      color: #ff671f; }
    .cities__allCheck input[type=checkbox]:checked + label:after {
      transform: translate(34%, 45%) rotate(-45deg) scale(1); }
    .cities__allCheck input[type=radio]:checked + label:after {
      transform: translate(-0.3em, -0.3em) scale(1); }
    .cities__allCheck input[type=checkbox] + label {
      float: none; }
      @media all and (max-width: 560px) {
        .cities__allCheck input[type=checkbox] + label {
          width: 75%; } }
  .cities__areaDetail:after {
    content: "";
    clear: both;
    display: block; }
  .cities__areaDetail li {
    display: inline; }
    .cities__areaDetail li input {
      display: none; }
    .cities__areaDetail li label {
      display: block;
      float: left;
      text-align: left;
      width: 25%;
      line-height: 1;
      vertical-align: middle;
      padding-right: 1em;
      padding-left: 2.25em;
      text-indent: -2.25em;
      margin-bottom: 1em;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent; }
      .cities__areaDetail li label.hover {
        color: #ff671f; }
      @media all and (max-width: 768px) {
        .cities__areaDetail li label {
          width: 33.33333%; } }
      @media all and (max-width: 560px) {
        .cities__areaDetail li label {
          width: 50%;
          margin-bottom: 1.5em;
          padding-left: 1.75em;
          text-indent: -1.75em; } }
      .cities__areaDetail li label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #ff671f;
        border-radius: 3px;
        margin-right: 1em; }
        @media all and (max-width: 768px) {
          .cities__areaDetail li label:before {
            border: 2px solid #ff671f; } }
        @media all and (max-width: 560px) {
          .cities__areaDetail li label:before {
            margin-right: 0.5em; } }
      .cities__areaDetail li label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #776c66;
        border-left: 3px solid #776c66;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .cities__areaDetail li label:after {
            border-bottom: 2px solid #776c66;
            border-left: 2px solid #776c66; } }
    .cities__areaDetail li input[type=checkbox] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #ff671f;
      border-radius: 3px;
      margin-right: 1em; }
      @media all and (max-width: 768px) {
        .cities__areaDetail li input[type=checkbox] + label:before {
          border: 2px solid #ff671f; } }
      @media all and (max-width: 560px) {
        .cities__areaDetail li input[type=checkbox] + label:before {
          margin-right: 0.5em; } }
    .cities__areaDetail li input[type=checkbox] + label:after {
      content: "";
      display: block;
      width: 0.75em;
      height: 0.5em;
      border-bottom: 3px solid #776c66;
      border-left: 3px solid #776c66;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(34%, 45%) rotate(-45deg) scale(0); }
      @media all and (max-width: 768px) {
        .cities__areaDetail li input[type=checkbox] + label:after {
          border-bottom: 2px solid #776c66;
          border-left: 2px solid #776c66; } }
    .cities__areaDetail li input[type=radio] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #ff671f;
      border-radius: 50%;
      margin-right: 1em; }
      @media all and (max-width: 768px) {
        .cities__areaDetail li input[type=radio] + label:before {
          border: 2px solid #ff671f; } }
      @media all and (max-width: 560px) {
        .cities__areaDetail li input[type=radio] + label:before {
          margin-right: 0.5em; } }
    .cities__areaDetail li input[type=radio] + label:after {
      content: "";
      display: block;
      width: 0.6em;
      height: 0.6em;
      background-color: #776c66;
      border-radius: 50%;
      position: absolute;
      left: 0.625em;
      top: 0.625em;
      border: none;
      transform: translate(-0.3em, -0.3em) scale(0); }
    .cities__areaDetail li .disable {
      color: #c9c9c9 !important; }
      .cities__areaDetail li .disable:before {
        display: none !important; }
      .cities__areaDetail li .disable:after {
        display: none !important; }
    .cities__areaDetail li input:checked + label {
      color: #ff671f; }
    .cities__areaDetail li input[type=checkbox]:checked + label:after {
      transform: translate(34%, 45%) rotate(-45deg) scale(1); }
    .cities__areaDetail li input[type=radio]:checked + label:after {
      transform: translate(-0.3em, -0.3em) scale(1); }

.selectedArea {
  display: table;
  width: 100%;
  margin: 0 auto 6em; }
  @media all and (max-width: 768px) {
    .selectedArea {
      margin: 0 auto 2em; } }
  .selectedArea dt {
    display: table-cell;
    background-color: #ff671f;
    color: #fff;
    width: 30%;
    padding: 2em;
    border-radius: 2em 0 0 2em;
    vertical-align: middle; }
    @media all and (max-width: 768px) {
      .selectedArea dt {
        display: block;
        width: 100%;
        border-radius: 1.5em 1.5em 0 0;
        padding: 1em 2em;
        text-align: left;
        font-size: 1.25em;
        line-height: 1; } }
  .selectedArea dd {
    display: table-cell;
    background-color: #fff;
    width: 70%;
    padding: 2em 3em;
    border-radius: 0 2em 2em 0;
    vertical-align: middle;
    text-align: left;
    font-feature-settings: normal; }
    @media all and (max-width: 768px) {
      .selectedArea dd {
        display: block;
        width: 100%;
        border-radius: 0 0 1.5em 1.5em;
        padding: 1em 2em;
        text-align: left; } }

.hospital {
  margin-bottom: 2em; }
  .hospital__item {
    display: table;
    line-height: 1.4;
    width: 100%;
    height: 90px;
    padding: 0.75em 3em;
    margin: 0 auto 1.5em;
    background-color: #fff;
    border-radius: 2em;
    text-align: left;
    /* mixin */ }
    @media all and (max-width: 768px) {
      .hospital__item {
        display: block;
        height: auto;
        padding: 0.75em 2em;
        border-radius: 1.5em; } }
    .hospital__item_name {
      display: table-cell;
      width: 25%;
      padding: 1em 1em 1em 0;
      vertical-align: middle; }
      @media all and (max-width: 768px) {
        .hospital__item_name {
          display: block;
          width: 100%;
          margin: auto;
          padding: 0.5em 1em 0.5em 0;
          font-size: 1.25em; } }
    .hospital__item_address {
      display: table-cell;
      background-size: 2.25em 2.25em;
      background-position: 1.5em center;
      vertical-align: middle;
      width: 40%;
      padding: 1em 1em 1em 4.5em;
      border-left: 3px dotted #776c66;
      border-right: 3px dotted #776c66;
      background-image: url(../svg/icon_map.svg); }
      @media all and (max-width: 768px) {
        .hospital__item_address {
          display: block;
          width: 100%;
          padding: 1em 1em 1em 3em;
          margin: auto;
          background-position: 0 center; } }
      @media all and (max-width: 768px) {
        .hospital__item_address {
          border-left: none;
          border-right: none;
          border-top: 2px dotted #776c66;
          border-bottom: 2px dotted #776c66; } }
    .hospital__item_tel {
      display: table-cell;
      background-size: 2.25em 2.25em;
      background-position: 1.5em center;
      vertical-align: middle;
      width: 25%;
      padding: 1em 0 1em 4.5em;
      background-image: url(../svg/icon_tel.svg); }
      @media all and (max-width: 768px) {
        .hospital__item_tel {
          display: block;
          width: 100%;
          padding: 1em 1em 1em 3em;
          margin: auto;
          background-position: 0 center; } }

.links {
  margin-bottom: 3em;
  padding: 3em;
  background-color: #fff;
  border-radius: 2.5em;
  text-align: justify; }
  @media all and (max-width: 560px) {
    .links {
      padding: 2em;
      border-radius: 1.5em; } }
  .links__item {
    padding: 2em 0;
    border-bottom: 4px dotted #776c66;
    /* mixin */ }
    .links__item:first-of-type {
      padding: 0 0 2em; }
    .links__item:last-of-type {
      border-bottom: none; }
    .links__item:after {
      content: "";
      clear: both;
      display: block; }
    @media all and (max-width: 560px) {
      .links__item {
        border-bottom: 2px dotted #776c66; } }
    .links__item_img {
      line-height: 1;
      width: 30%;
      float: left;
      text-align: center; }
      .links__item_img a {
        transition: opacity 200ms ease; }
        .links__item_img a:hover {
          opacity: .8; }
      @media all and (max-width: 560px) {
        .links__item_img {
          width: 60%;
          float: none;
          margin: 0 auto 1.5em; } }
      @media all and (max-width: 375px) {
        .links__item_img {
          width: 80%; } }
      .links__item_img-linkfree {
        text-align: center;
        margin: 3em auto; }
        @media all and (max-width: 560px) {
          .links__item_img-linkfree {
            margin-bottom: 2em; } }
    .links__item_head {
      font-size: 0.94737em;
      width: 67.5%;
      float: right;
      line-height: 1.4;
      margin-bottom: 1em; }
      .links__item_head span {
        display: inline-block; }
      @media all and (max-width: 560px) {
        .links__item_head {
          width: 100%;
          float: none;
          text-align: center;
          font-size: 1.25em;
          margin-bottom: 0.75em; } }
      .links__item_head-linkfree {
        text-align: center;
        margin-bottom: 1em; }
    .links__item_txt {
      font-size: 0.73684em;
      font-weight: normal;
      width: 67.5%;
      float: right; }
      @media all and (max-width: 560px) {
        .links__item_txt {
          width: 100%;
          float: none;
          font-size: 1em; } }
      .links__item_txt-linkfree {
        font-size: 0.73684em;
        font-weight: normal;
        margin-bottom: 1em; }
    .links__item_code-linkfree textarea {
      color: #776c66;
      width: 100%;
      padding: 1em;
      font-size: 0.84211em;
      overflow: scroll;
      word-break: break-all; }

.totop {
  display: block;
  background: url(../svg/totop_dog.svg) no-repeat;
  width: 140px;
  height: 140px;
  position: fixed;
  right: -140px;
  bottom: 0;
  z-index: 1;
  transition: bottom 0 linear;
  background-position: 0 0;
  backface-visibility: hidden;
  transition: bottom 25ms ease; }
  @media all and (max-width: 768px) {
    .totop {
      width: 100px;
      height: 100px;
      right: -100px;
      background-size: cover; } }
  @media all and (max-width: 560px) {
    .totop {
      width: 60px;
      height: 60px;
      right: -60px;
      transition: right 500ms ease; } }
  .totop-walk {
    animation: walk 0.3s steps(2) infinite;
    transition: bottom 1000ms ease; }
    @media all and (max-width: 768px) {
      .totop-walk {
        animation: walk2 0.2s steps(2) infinite; } }
    @media all and (max-width: 560px) {
      .totop-walk {
        animation: walk3 0.3s steps(2) infinite;
        transition: right 1000ms ease; } }
  .totop-on {
    right: 30px; }
    @media all and (max-width: 768px) {
      .totop-on {
        right: 10px; } }
    @media all and (max-width: 560px) {
      .totop-on {
        right: 5px; } }
  .totop:after {
    content: "";
    display: block;
    width: 43px;
    height: 43px;
    background-image: url(../svg/totop_arrow.svg);
    background-size: contain;
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translateX(-50%); }
    @media all and (max-width: 768px) {
      .totop:after {
        width: 33px;
        height: 33px; } }
    @media all and (max-width: 560px) {
      .totop:after {
        width: 24px;
        height: 24px;
        top: -10%;
        left: 70%; } }

@keyframes walk {
  to {
    background-position: 0 -280px; } }

@keyframes walk2 {
  to {
    background-position: 0 -200px; } }

@keyframes walk3 {
  to {
    background-position: 0 -120px; } }

.footer {
  margin: 1em auto 0;
  background-color: #fff;
  letter-spacing: normal; }
  .index + .footer {
    margin-top: 0; }
  .footer__bnr {
    color: #464646;
    font-weight: normal;
    padding: 2.63158em 10px;
    background-color: #f2f2f2;
    background-image: url(../img/ft_bnr_bg.gif);
    background-repeat: repeat;
    /* mixin */ }
    .footer__bnr ul {
      max-width: 715px;
      margin: auto; }
      .footer__bnr ul:after {
        content: "";
        clear: both;
        display: block; }
    .footer__bnr-2row {
      float: left;
      margin-bottom: 1.21053em; }
      .footer__bnr-2row span {
        font-size: 0.68421em;
        display: block;
        margin-bottom: 0.5em; }
      .footer__bnr-2row a {
        display: inline-block;
        padding: 3px;
        background-color: #fff;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
        transition: opacity 200ms ease; }
        .footer__bnr-2row a:hover {
          opacity: .8; }
      @media all and (max-width: 745px) {
        .footer__bnr-2row {
          float: none; }
          .footer__bnr-2row span {
            font-size: 0.89474em; } }
      @media all and (max-width: 500px) {
        .footer__bnr-2row img {
          width: 100%; } }
      .footer__bnr-2row-left {
        float: left;
        margin-bottom: 1.21053em;
        margin-right: 1.21053em; }
        .footer__bnr-2row-left span {
          font-size: 0.68421em;
          display: block;
          margin-bottom: 0.5em; }
        .footer__bnr-2row-left a {
          display: inline-block;
          padding: 3px;
          background-color: #fff;
          box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
          transition: opacity 200ms ease; }
          .footer__bnr-2row-left a:hover {
            opacity: .8; }
        @media all and (max-width: 745px) {
          .footer__bnr-2row-left {
            float: none; }
            .footer__bnr-2row-left span {
              font-size: 0.89474em; } }
        @media all and (max-width: 500px) {
          .footer__bnr-2row-left img {
            width: 100%; } }
        @media all and (max-width: 745px) {
          .footer__bnr-2row-left {
            margin-right: 0; } }
    .footer__bnr-1row {
      float: left;
      margin-bottom: 1.21053em;
      float: none; }
      .footer__bnr-1row span {
        font-size: 0.68421em;
        display: block;
        margin-bottom: 0.5em; }
      .footer__bnr-1row a {
        display: inline-block;
        padding: 3px;
        background-color: #fff;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
        transition: opacity 200ms ease; }
        .footer__bnr-1row a:hover {
          opacity: .8; }
      @media all and (max-width: 745px) {
        .footer__bnr-1row {
          float: none; }
          .footer__bnr-1row span {
            font-size: 0.89474em; } }
      @media all and (max-width: 500px) {
        .footer__bnr-1row img {
          width: 100%; } }
      .footer__bnr-1row span {
        font-size: 0.73684em; }
      @media all and (max-width: 745px) {
        .footer__bnr-1row span {
          font-size: 0.94737em; } }
  .footer__bottom {
    color: #464646;
    font-size: 0.63158em;
    font-weight: normal;
    line-height: 1.4;
    padding: 2.63158em 1.94737em; }
    .footer__bottom_info {
      max-width: 1000px;
      margin: auto;
      text-align: left;
      position: relative; }
      .footer__bottom_info:after {
        content: "";
        clear: both;
        display: block; }
      .footer__bottom_info_logo {
        float: left;
        margin-right: 1.31579em; }
        @media all and (max-width: 880px) {
          .footer__bottom_info_logo {
            float: none;
            margin: 0 auto 2em;
            text-align: center; } }
      .footer__bottom_info_link {
        float: left;
        margin-top: 0.63158em;
        font-size: 0; }
        .footer__bottom_info_link:after {
          content: "";
          clear: both;
          display: block; }
        @media all and (max-width: 880px) {
          .footer__bottom_info_link {
            float: none;
            text-align: center;
            margin: 0 auto; } }
        .footer__bottom_info_link li {
          display: inline-block;
          font-size: 10px;
          line-height: 1; }
          .footer__bottom_info_link li ~ li {
            margin-left: 1em;
            padding-left: 1em;
            border-left: 1px solid #464646; }
            @media all and (max-width: 400px) {
              .footer__bottom_info_link li ~ li {
                margin-left: 0;
                padding-left: 0;
                border-left: none; } }
          .footer__bottom_info_link li a {
            color: #464646; }
          @media all and (max-width: 400px) {
            .footer__bottom_info_link li {
              display: block;
              margin-bottom: 1em; } }
      .footer__bottom_info_copyright {
        float: right;
        width: 44%; }
        @media all and (max-width: 880px) {
          .footer__bottom_info_copyright {
            float: none;
            width: 100%;
            margin-top: 2em;
            padding-top: 2em;
            border-top: 1px dotted #BDBDBD; } }

.conversion {
  color: #fff;
  letter-spacing: 0.125em;
  padding: 5.42105em 20px;
  background-color: #005366; }
  .conversion dt {
    font-size: 1.84211em;
    font-weight: normal;
    line-height: 1.4;
    margin-bottom: 0.75em; }
    .conversion dt span {
      display: inline-block; }
  .conversion__txt {
    font-size: 0.84211em;
    font-weight: normal;
    margin-bottom: 1.68421em; }

.a-fueru-madani {
  position: relative;
  max-width: 800px;
  margin: auto; }
  .a-fueru-madani:after {
    content: "";
    clear: both;
    display: block; }
  .a-fueru-madani__present, .a-fueru-madani__arrow, .a-fueru-madani__future {
    width: 33.3%;
    float: left; }
    @media all and (max-width: 700px) {
      .a-fueru-madani__present, .a-fueru-madani__arrow, .a-fueru-madani__future {
        width: auto;
        float: none; } }
  .a-fueru-madani__arrow {
    height: 214px;
    position: relative; }
    .a-fueru-madani__arrow img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
      @media all and (max-width: 700px) {
        .a-fueru-madani__arrow img {
          transform: rotate(90deg) scale(0.8); } }
    .a-fueru-madani__arrow-txt {
      color: #fff;
      font-size: 1.54em;
      line-height: 1;
      height: 1em;
      text-shadow: 2px 0 2px #fe6620, -2px 0 2px #fe6620, 0 2px 2px #fe6620, 0 -2px 2px #fe6620, 2px 2px 2px #fe6620, -2px -2px 2px #fe6620, 2px -2px 2px #fe6620, -2px 2px 2px #fe6620;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
      @media all and (max-width: 700px) {
        .a-fueru-madani__arrow-txt {
          transform: translateY(-1em); } }
  .a-fueru-madani__madani1 {
    font-size: 0;
    height: 214px;
    position: relative; }
    @media all and (max-width: 700px) {
      .a-fueru-madani__madani1 {
        height: 154px; } }
    .a-fueru-madani__madani1 img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
  .a-fueru-madani__madani500 {
    width: 210px;
    height: 217px;
    overflow: hidden;
    margin: auto;
    font-size: 0; }
    .a-fueru-madani__madani500 img {
      backface-visibility: hidden;
      transition: margin-top 3s;
      margin-top: 100%; }
    .a-fueru-madani__madani500.js-animation img {
      margin-top: 0; }
  .a-fueru-madani__caption {
    font-size: 1.71em;
    margin-top: 1em;
    margin-bottom: 0; }
    .a-fueru-madani__caption--count {
      font-size: 2.8em;
      text-align: right;
      padding-right: 20%;
      margin-bottom: 0; }
      @media all and (max-width: 1060px) {
        .a-fueru-madani__caption--count {
          padding-right: 25%; } }
      @media all and (max-width: 700px) {
        .a-fueru-madani__caption--count {
          width: 210px;
          padding-right: 1.4em; } }
      @media all and (max-width: 375px) {
        .a-fueru-madani__caption--count {
          padding-right: 1.6em; } }

.a-madani-zoom {
  overflow: hidden;
  margin-bottom: 5em; }
  .a-madani-zoom > svg {
    display: none; }
  .a-madani-zoom__left-block, .a-madani-zoom__right-block {
    width: 50%; }
    @media all and (max-width: 790px) {
      .a-madani-zoom__left-block, .a-madani-zoom__right-block {
        width: 100%; } }
    .a-madani-zoom__left-block dt > strong, .a-madani-zoom__right-block dt > strong {
      font-size: 1.3em; }
  .a-madani-zoom__left-block {
    float: left; }
    @media all and (max-width: 790px) {
      .a-madani-zoom__left-block {
        float: none;
        margin-bottom: 3em; } }
  .a-madani-zoom__right-block {
    float: right; }
    @media all and (max-width: 790px) {
      .a-madani-zoom__right-block {
        float: none; } }
  .a-madani-zoom__illust {
    position: relative;
    margin-bottom: 1em; }
  .a-madani-zoom__dani, .a-madani-zoom__dani--small {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    filter: url("#blur5");
    -webkit-filter: blur(5px);
    transition: transform .5s linear .5s; }
    .js-animation .a-madani-zoom__dani, .js-animation .a-madani-zoom__dani--small {
      animation: blur-anim 1s linear;
      filter: none; }
  .a-madani-zoom__dani {
    transform: translateX(-5%) scale(1.2); }
    .js-animation .a-madani-zoom__dani {
      transform: translateX(-5%) scale(1); }
  .a-madani-zoom__dani--small {
    transform: translateX(-5%) scale(0.5); }
    .js-animation .a-madani-zoom__dani--small {
      transform: translateX(-5%) scale(0.2); }

@keyframes blur-anim {
  0% {
    filter: url("#blur5");
    -webkit-filter: blur(5px); }
  20% {
    filter: url("#blur4");
    -webkit-filter: blur(4px); }
  40% {
    filter: url("#blur3");
    -webkit-filter: blur(3px); }
  60% {
    filter: url("#blur2");
    -webkit-filter: blur(2px); }
  80% {
    filter: url("#blur1");
    -webkit-filter: blur(1px); }
  100% {
    filter: url("#blur0");
    -webkit-filter: blur(0px); } }

.formArea {
  /* mixin */
  /* mixin */
  /* mixin */
  /* mixin */
  /* mixin */
  /* mixin */
  /* mixin */
  /* __check */
  /* mixin */
  /* mixin */ }
  .formArea-login {
    color: #464646;
    max-width: 430px;
    padding-top: 2.5em;
    margin: 0 auto 2.5em;
    position: relative; }
    .formArea-login * {
      color: inherit; }
    .formArea-login button {
      color: #fff;
      text-align: center; }
    .formArea-login.err .formArea__err {
      display: block; }
  .formArea-thanx {
    color: #464646; }
    .formArea-thanx * {
      color: inherit; }
    .formArea-thanx button {
      color: #fff;
      text-align: center; }
    .formArea-thanx_txt-large {
      font-size: 1.68421em;
      margin: 0 auto 1.5em; }
      .formArea-thanx_txt-large span {
        display: inline-block; }
    .formArea-thanx_txt-small {
      font-size: 1.05263em;
      font-weight: normal;
      margin: 0 auto 7em; }
      .formArea-thanx_txt-small span {
        display: inline-block; }
      @media all and (max-width: 560px) {
        .formArea-thanx_txt-small {
          margin: 0 auto 5em; } }
  .formArea__item {
    color: #464646;
    text-align: left;
    margin-top: 1em;
    padding-top: 2.5em;
    position: relative; }
    .formArea__item * {
      color: inherit; }
    .formArea__item button {
      color: #fff;
      text-align: center; }
    @media all and (max-width: 768px) {
      .formArea__item {
        float: none;
        width: 100%; } }
    .formArea__item.err .formArea__err {
      display: block; }
    .formArea__item.require > dt:after {
      content: "必須";
      font-size: 0.68421em;
      letter-spacing: 0;
      line-height: 1;
      color: #fff;
      background-color: #ff671f;
      padding: 0.3em 0.8em;
      border-radius: 0.4em;
      margin-left: 1em; }
    .formArea__item-first {
      color: #464646;
      text-align: left;
      margin-top: 1em;
      padding-top: 2.5em;
      position: relative;
      margin-top: 0; }
      .formArea__item-first * {
        color: inherit; }
      .formArea__item-first button {
        color: #fff;
        text-align: center; }
      @media all and (max-width: 768px) {
        .formArea__item-first {
          float: none;
          width: 100%; } }
      .formArea__item-first.err .formArea__err {
        display: block; }
      .formArea__item-first.require > dt:after {
        content: "必須";
        font-size: 0.68421em;
        letter-spacing: 0;
        line-height: 1;
        color: #fff;
        background-color: #ff671f;
        padding: 0.3em 0.8em;
        border-radius: 0.4em;
        margin-left: 1em; }
    .formArea__item-fileup {
      color: #464646;
      text-align: left;
      margin-top: 1em;
      padding-top: 2.5em;
      position: relative; }
      .formArea__item-fileup * {
        color: inherit; }
      .formArea__item-fileup button {
        color: #fff;
        text-align: center; }
      @media all and (max-width: 768px) {
        .formArea__item-fileup {
          float: none;
          width: 100%; } }
      .formArea__item-fileup.err .formArea__err {
        display: block; }
      .formArea__item-fileup.require > dt:after {
        content: "必須";
        font-size: 0.68421em;
        letter-spacing: 0;
        line-height: 1;
        color: #fff;
        background-color: #ff671f;
        padding: 0.3em 0.8em;
        border-radius: 0.4em;
        margin-left: 1em; }
      @media all and (max-width: 768px) {
        .formArea__item-fileup {
          display: none; } }
    .formArea__item-half {
      color: #464646;
      text-align: left;
      margin-top: 1em;
      padding-top: 2.5em;
      position: relative;
      width: 47.5%;
      float: left;
      margin-right: 5%; }
      .formArea__item-half * {
        color: inherit; }
      .formArea__item-half button {
        color: #fff;
        text-align: center; }
      @media all and (max-width: 768px) {
        .formArea__item-half {
          float: none;
          width: 100%; } }
      .formArea__item-half.err .formArea__err {
        display: block; }
      .formArea__item-half.require > dt:after {
        content: "必須";
        font-size: 0.68421em;
        letter-spacing: 0;
        line-height: 1;
        color: #fff;
        background-color: #ff671f;
        padding: 0.3em 0.8em;
        border-radius: 0.4em;
        margin-left: 1em; }
      .formArea__item-half-nomargin {
        color: #464646;
        text-align: left;
        margin-top: 1em;
        padding-top: 2.5em;
        position: relative;
        width: 47.5%;
        float: left; }
        .formArea__item-half-nomargin * {
          color: inherit; }
        .formArea__item-half-nomargin button {
          color: #fff;
          text-align: center; }
        @media all and (max-width: 768px) {
          .formArea__item-half-nomargin {
            float: none;
            width: 100%; } }
        .formArea__item-half-nomargin.err .formArea__err {
          display: block; }
        .formArea__item-half-nomargin.require > dt:after {
          content: "必須";
          font-size: 0.68421em;
          letter-spacing: 0;
          line-height: 1;
          color: #fff;
          background-color: #ff671f;
          padding: 0.3em 0.8em;
          border-radius: 0.4em;
          margin-left: 1em; }
    .formArea__item-quarter {
      color: #464646;
      text-align: left;
      margin-top: 1em;
      padding-top: 2.5em;
      position: relative;
      width: 21.25%;
      float: left;
      margin-right: 5%; }
      .formArea__item-quarter * {
        color: inherit; }
      .formArea__item-quarter button {
        color: #fff;
        text-align: center; }
      @media all and (max-width: 768px) {
        .formArea__item-quarter {
          float: none;
          width: 100%; } }
      .formArea__item-quarter.err .formArea__err {
        display: block; }
      @media all and (max-width: 768px) {
        .formArea__item-quarter {
          width: 75%; } }
      .formArea__item-quarter.require > dt:after {
        content: "必須";
        font-size: 0.68421em;
        letter-spacing: 0;
        line-height: 1;
        color: #fff;
        background-color: #ff671f;
        padding: 0.3em 0.8em;
        border-radius: 0.4em;
        margin-left: 1em; }
      .formArea__item-quarter.require dt:after {
        margin-left: 0.5em;
        padding: .3em .5em; }
        @media all and (max-width: 768px) {
          .formArea__item-quarter.require dt:after {
            padding: 0.3em 0.8em;
            margin-left: 1em; } }
      .formArea__item-quarter-nomargin {
        color: #464646;
        text-align: left;
        margin-top: 1em;
        padding-top: 2.5em;
        position: relative;
        width: 21.25%;
        float: left; }
        .formArea__item-quarter-nomargin * {
          color: inherit; }
        .formArea__item-quarter-nomargin button {
          color: #fff;
          text-align: center; }
        @media all and (max-width: 768px) {
          .formArea__item-quarter-nomargin {
            float: none;
            width: 100%; } }
        .formArea__item-quarter-nomargin.err .formArea__err {
          display: block; }
        @media all and (max-width: 768px) {
          .formArea__item-quarter-nomargin {
            width: 75%; } }
        .formArea__item-quarter-nomargin.require > dt:after {
          content: "必須";
          font-size: 0.68421em;
          letter-spacing: 0;
          line-height: 1;
          color: #fff;
          background-color: #ff671f;
          padding: 0.3em 0.8em;
          border-radius: 0.4em;
          margin-left: 1em; }
        .formArea__item-quarter-nomargin.require dt:after {
          margin-left: 0.5em;
          padding: .3em .5em; }
          @media all and (max-width: 768px) {
            .formArea__item-quarter-nomargin.require dt:after {
              padding: 0.3em 0.8em;
              margin-left: 1em; } }
  .formArea__wrap {
    display: block;
    text-align: left;
    margin-bottom: 60px; }
    @media all and (max-width: 768px) {
      .formArea__wrap {
        margin-bottom: 40px; } }
    @media all and (max-width: 560px) {
      .formArea__wrap {
        margin-bottom: 30px; } }
    @media all and (max-width: 375px) {
      .formArea__wrap {
        margin-bottom: 20px; } }
    .formArea__wrap-login {
      display: block;
      text-align: left;
      margin-bottom: 60px;
      font-size: 0; }
      @media all and (max-width: 768px) {
        .formArea__wrap-login {
          margin-bottom: 40px; } }
      @media all and (max-width: 560px) {
        .formArea__wrap-login {
          margin-bottom: 30px; } }
      @media all and (max-width: 375px) {
        .formArea__wrap-login {
          margin-bottom: 20px; } }
  .formArea__head {
    font-size: 1.15789em;
    line-height: 1;
    letter-spacing: 0;
    vertical-align: middle;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 0.2em dotted #7d7d7d; }
    .formArea__head small {
      font-weight: normal;
      letter-spacing: 0; }
    .formArea__head:before {
      content: "";
      display: inline-block;
      width: 1.57895em;
      height: 1.57895em;
      background-image: url(../svg/form_head.svg);
      background-size: contain;
      margin-right: 0.5em; }
    @media all and (max-width: 560px) {
      .formArea__head {
        border-bottom: 0.15em dotted #7d7d7d; } }
    .formArea__head-noline {
      font-size: 1.15789em;
      line-height: 1;
      letter-spacing: 0;
      vertical-align: middle;
      margin-bottom: 1em;
      padding-bottom: 0.5em;
      border-bottom: 0.2em dotted #7d7d7d;
      border-bottom: none;
      padding-bottom: 0; }
      .formArea__head-noline small {
        font-weight: normal;
        letter-spacing: 0; }
      .formArea__head-noline:before {
        content: "";
        display: inline-block;
        width: 1.57895em;
        height: 1.57895em;
        background-image: url(../svg/form_head.svg);
        background-size: contain;
        margin-right: 0.5em; }
  .formArea__txt {
    font-weight: normal;
    line-height: 1.5;
    font-size: 0.94737em;
    margin: auto; }
    .formArea__txt-withunit {
      font-weight: normal;
      line-height: 1.5;
      font-size: 0.94737em;
      margin: auto;
      float: left;
      width: 80%; }
  .formArea__label {
    font-size: 22px; }
    .formArea__label small {
      font-size: 0.72727em; }
    @media all and (max-width: 768px) {
      .formArea__label {
        font-size: 18px; } }
    @media all and (max-width: 560px) {
      .formArea__label {
        font-size: 15px; } }
    @media all and (max-width: 375px) {
      .formArea__label {
        font-size: 13px; } }
    .formArea__label-login {
      font-size: 22px;
      display: inline-block;
      width: 14%;
      padding-left: 0.5em;
      letter-spacing: 0; }
      .formArea__label-login small {
        font-size: 0.72727em; }
      @media all and (max-width: 768px) {
        .formArea__label-login {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__label-login {
          font-size: 15px; } }
      @media all and (max-width: 375px) {
        .formArea__label-login {
          font-size: 13px; } }
    .formArea__label-addrhospital {
      font-size: 22px;
      display: inline-block;
      width: 15%;
      padding: .7em 0;
      margin-top: 1.5em;
      line-height: 1; }
      .formArea__label-addrhospital small {
        font-size: 0.72727em; }
      @media all and (max-width: 768px) {
        .formArea__label-addrhospital {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__label-addrhospital {
          font-size: 15px; } }
      @media all and (max-width: 375px) {
        .formArea__label-addrhospital {
          font-size: 13px; } }
      @media all and (max-width: 450px) {
        .formArea__label-addrhospital {
          width: 20%; } }
      @media all and (max-width: 375px) {
        .formArea__label-addrhospital {
          letter-spacing: 0; } }
  .formArea__input {
    font-size: 22px;
    appearance: none;
    width: 100%;
    border: 2px solid #7d7d7d;
    border-radius: 10px;
    padding: 0.5em;
    line-height: 1;
    font-size: 19px;
    font-family: inherit;
    font-weight: bold;
    color: #464646;
    outline: none; }
    .formArea__input:focus {
      box-shadow: 0px 0px 5px 1px #ff671f; }
    @media all and (max-width: 768px) {
      .formArea__input {
        font-size: 18px; } }
    @media all and (max-width: 560px) {
      .formArea__input {
        font-size: 15px;
        border-radius: 5px;
        border: 1px solid #7d7d7d; } }
    @media all and (max-width: 375px) {
      .formArea__input {
        font-size: 12px; } }
    .formArea__input[type="date"] {
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      background-color: #fff;
      padding: 0.75em 0.5em; }
    .formArea__input-withunit {
      font-size: 22px;
      appearance: none;
      width: 100%;
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em;
      line-height: 1;
      font-size: 19px;
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      outline: none;
      width: 80%; }
      .formArea__input-withunit:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__input-withunit {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__input-withunit {
          font-size: 15px;
          border-radius: 5px;
          border: 1px solid #7d7d7d; } }
      @media all and (max-width: 375px) {
        .formArea__input-withunit {
          font-size: 12px; } }
      .formArea__input-withunit[type="date"] {
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        background-color: #fff;
        padding: 0.75em 0.5em; }
    .formArea__input-login {
      font-size: 22px;
      appearance: none;
      width: 100%;
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em;
      line-height: 1;
      font-size: 19px;
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      outline: none;
      display: inline-block;
      width: 85%; }
      .formArea__input-login:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__input-login {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__input-login {
          font-size: 15px;
          border-radius: 5px;
          border: 1px solid #7d7d7d; } }
      @media all and (max-width: 375px) {
        .formArea__input-login {
          font-size: 12px; } }
      .formArea__input-login[type="date"] {
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        background-color: #fff;
        padding: 0.75em 0.5em; }
    .formArea__input-unit {
      width: 15%;
      padding-left: 4%;
      font-size: 0.94737;
      vertical-align: bottom;
      letter-spacing: 0; }
    .formArea__input-pos1 {
      font-size: 22px;
      appearance: none;
      width: 100%;
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em;
      line-height: 1;
      font-size: 19px;
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      outline: none;
      width: 12.5%; }
      .formArea__input-pos1:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__input-pos1 {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__input-pos1 {
          font-size: 15px;
          border-radius: 5px;
          border: 1px solid #7d7d7d; } }
      @media all and (max-width: 375px) {
        .formArea__input-pos1 {
          font-size: 12px; } }
      .formArea__input-pos1[type="date"] {
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        background-color: #fff;
        padding: 0.75em 0.5em; }
      @media all and (max-width: 768px) {
        .formArea__input-pos1 {
          width: 4em; } }
    .formArea__input-pos2 {
      font-size: 22px;
      appearance: none;
      width: 100%;
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em;
      line-height: 1;
      font-size: 19px;
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      outline: none;
      width: 20%; }
      .formArea__input-pos2:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__input-pos2 {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__input-pos2 {
          font-size: 15px;
          border-radius: 5px;
          border: 1px solid #7d7d7d; } }
      @media all and (max-width: 375px) {
        .formArea__input-pos2 {
          font-size: 12px; } }
      .formArea__input-pos2[type="date"] {
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        background-color: #fff;
        padding: 0.75em 0.5em; }
      @media all and (max-width: 768px) {
        .formArea__input-pos2 {
          width: 5em; } }
    .formArea__input-addrhospital {
      font-size: 22px;
      appearance: none;
      width: 100%;
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em;
      line-height: 1;
      font-size: 19px;
      font-family: inherit;
      font-weight: bold;
      color: #464646;
      outline: none;
      width: 84.5%;
      margin-top: 1.5em; }
      .formArea__input-addrhospital:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__input-addrhospital {
          font-size: 18px; } }
      @media all and (max-width: 560px) {
        .formArea__input-addrhospital {
          font-size: 15px;
          border-radius: 5px;
          border: 1px solid #7d7d7d; } }
      @media all and (max-width: 375px) {
        .formArea__input-addrhospital {
          font-size: 12px; } }
      .formArea__input-addrhospital[type="date"] {
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        background-color: #fff;
        padding: 0.75em 0.5em; }
      @media all and (max-width: 450px) {
        .formArea__input-addrhospital {
          width: 79%; } }
  .formArea__notice {
    color: #ff2458;
    font-size: 16px;
    font-weight: bold;
    float: right;
    margin: 0 !important;
    display: block; }
  .formArea__textarea {
    width: 100%;
    color: #464646;
    outline: none;
    border: none;
    font-size: 0.84211em;
    resize: none;
    /*スクロールバー全体*/
    /*スクロールバーの軌道*/
    /*スクロールバーの動く部分*/ }
    .formArea__textarea-wrap {
      border: 2px solid #7d7d7d;
      border-radius: 10px;
      padding: 0.5em 0.5em 0.5em 1em; }
      .formArea__textarea-wrap.focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 560px) {
        .formArea__textarea-wrap {
          border: 1px solid #7d7d7d; } }
    @media all and (max-width: 768px) {
      .formArea__textarea {
        font-size: 0.94737em; } }
    .formArea__textarea::-webkit-scrollbar {
      width: 5px;
      margin-right: 5px; }
    .formArea__textarea::-webkit-scrollbar-track {
      border-radius: 5px; }
    .formArea__textarea::-webkit-scrollbar-thumb {
      background-color: #ff671f;
      border-radius: 5px; }
  .formArea__check {
    vertical-align: middle; }
    .formArea__check ul:after {
      content: "";
      clear: both;
      display: block; }
    .formArea__check input {
      display: none; }
    .formArea__check label {
      display: block;
      float: left;
      text-align: left;
      line-height: 1;
      vertical-align: middle;
      padding-right: 2em;
      padding-left: 2.25em;
      text-indent: -2.25em;
      margin-bottom: 1em;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent; }
      .formArea__check label.hover {
        color: #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__check label {
          width: 33.33333%;
          padding-right: 0; } }
      @media all and (max-width: 560px) {
        .formArea__check label {
          width: 50%;
          margin-bottom: 1.5em;
          padding-left: 1.75em;
          text-indent: -1.75em;
          padding-right: 0.25em; } }
      @media all and (max-width: 450px) {
        .formArea__check label.parasiteLv-high {
          width: 100%; } }
      .formArea__check label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 3px;
        margin-right: 1em; }
        @media all and (max-width: 768px) {
          .formArea__check label:before {
            border: 2px solid #7d7d7d; } }
        @media all and (max-width: 560px) {
          .formArea__check label:before {
            margin-right: 0.5em; } }
      .formArea__check label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #ff671f;
        border-left: 3px solid #ff671f;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .formArea__check label:after {
            border-bottom: 2px solid #ff671f;
            border-left: 2px solid #ff671f; } }
    .formArea__check input[type=checkbox] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #7d7d7d;
      border-radius: 3px;
      margin: -0.15em 0.5em 0 0; }
      @media all and (max-width: 768px) {
        .formArea__check input[type=checkbox] + label:before {
          border: 2px solid #7d7d7d; } }
    .formArea__check input[type=checkbox] + label:after {
      content: "";
      display: block;
      width: 0.75em;
      height: 0.5em;
      border-bottom: 3px solid #ff671f;
      border-left: 3px solid #ff671f;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: -0.1em;
      transform: translate(34%, 45%) rotate(-45deg) scale(0); }
      @media all and (max-width: 768px) {
        .formArea__check input[type=checkbox] + label:after {
          border-bottom: 2px solid #ff671f;
          border-left: 2px solid #ff671f; } }
    .formArea__check input[type=radio] + label:before {
      content: "";
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
      border: 3px solid #7d7d7d;
      border-radius: 50%;
      margin: -0.15em 0.5em 0 0; }
      @media all and (max-width: 768px) {
        .formArea__check input[type=radio] + label:before {
          border: 2px solid #7d7d7d; } }
    .formArea__check input[type=radio] + label:after {
      content: "";
      display: block;
      width: 0.6em;
      height: 0.6em;
      background-color: #ff671f;
      border-radius: 50%;
      position: absolute;
      left: 0.625em;
      top: 0.625em;
      margin-top: -0.1em;
      border: none;
      transform: translate(-0.3em, -0.3em) scale(0); }
    .formArea__check input:checked + label {
      color: #ff671f; }
    .formArea__check input[type=checkbox]:checked + label:after {
      transform: translate(34%, 45%) rotate(-45deg) scale(1); }
    .formArea__check input[type=radio]:checked + label:after {
      transform: translate(-0.3em, -0.3em) scale(1); }
    .formArea__check input[type=checkbox][disabled] + label {
      cursor: auto;
      opacity: .5; }
    .formArea__check input[type=checkbox][disabled]:checked + label {
      cursor: auto;
      color: inherit;
      opacity: 1; }
    .formArea__check-col3 {
      vertical-align: middle; }
      .formArea__check-col3 ul:after {
        content: "";
        clear: both;
        display: block; }
      .formArea__check-col3 input {
        display: none; }
      .formArea__check-col3 label {
        display: block;
        float: left;
        text-align: left;
        line-height: 1;
        vertical-align: middle;
        padding-right: 2em;
        padding-left: 2.25em;
        text-indent: -2.25em;
        margin-bottom: 1em;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent; }
        .formArea__check-col3 label.hover {
          color: #ff671f; }
        @media all and (max-width: 768px) {
          .formArea__check-col3 label {
            width: 33.33333%;
            padding-right: 0; } }
        @media all and (max-width: 560px) {
          .formArea__check-col3 label {
            width: 50%;
            margin-bottom: 1.5em;
            padding-left: 1.75em;
            text-indent: -1.75em;
            padding-right: 0.25em; } }
        @media all and (max-width: 450px) {
          .formArea__check-col3 label.parasiteLv-high {
            width: 100%; } }
        .formArea__check-col3 label:before {
          content: "";
          display: inline-block;
          width: 1.25em;
          height: 1.25em;
          border: 3px solid #7d7d7d;
          border-radius: 3px;
          margin-right: 1em; }
          @media all and (max-width: 768px) {
            .formArea__check-col3 label:before {
              border: 2px solid #7d7d7d; } }
          @media all and (max-width: 560px) {
            .formArea__check-col3 label:before {
              margin-right: 0.5em; } }
        .formArea__check-col3 label:after {
          content: "";
          display: block;
          width: 0.75em;
          height: 0.5em;
          border-bottom: 3px solid #ff671f;
          border-left: 3px solid #ff671f;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(34%, 45%) rotate(-45deg) scale(0); }
          @media all and (max-width: 768px) {
            .formArea__check-col3 label:after {
              border-bottom: 2px solid #ff671f;
              border-left: 2px solid #ff671f; } }
      .formArea__check-col3 input[type=checkbox] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 3px;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-col3 input[type=checkbox] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-col3 input[type=checkbox] + label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #ff671f;
        border-left: 3px solid #ff671f;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.1em;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .formArea__check-col3 input[type=checkbox] + label:after {
            border-bottom: 2px solid #ff671f;
            border-left: 2px solid #ff671f; } }
      .formArea__check-col3 input[type=radio] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 50%;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-col3 input[type=radio] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-col3 input[type=radio] + label:after {
        content: "";
        display: block;
        width: 0.6em;
        height: 0.6em;
        background-color: #ff671f;
        border-radius: 50%;
        position: absolute;
        left: 0.625em;
        top: 0.625em;
        margin-top: -0.1em;
        border: none;
        transform: translate(-0.3em, -0.3em) scale(0); }
      .formArea__check-col3 input:checked + label {
        color: #ff671f; }
      .formArea__check-col3 input[type=checkbox]:checked + label:after {
        transform: translate(34%, 45%) rotate(-45deg) scale(1); }
      .formArea__check-col3 input[type=radio]:checked + label:after {
        transform: translate(-0.3em, -0.3em) scale(1); }
      .formArea__check-col3 input[type=checkbox][disabled] + label {
        cursor: auto;
        opacity: .5; }
      .formArea__check-col3 input[type=checkbox][disabled]:checked + label {
        cursor: auto;
        color: inherit;
        opacity: 1; }
      @media all and (max-width: 560px) {
        .formArea__check-col3 label {
          width: 33.33333%; } }
    .formArea__check-fig-sp {
      vertical-align: middle;
      padding-left: 1em; }
      .formArea__check-fig-sp ul:after {
        content: "";
        clear: both;
        display: block; }
      .formArea__check-fig-sp input {
        display: none; }
      .formArea__check-fig-sp label {
        display: block;
        float: left;
        text-align: left;
        line-height: 1;
        vertical-align: middle;
        padding-right: 2em;
        padding-left: 2.25em;
        text-indent: -2.25em;
        margin-bottom: 1em;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent; }
        .formArea__check-fig-sp label.hover {
          color: #ff671f; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-sp label {
            width: 33.33333%;
            padding-right: 0; } }
        @media all and (max-width: 560px) {
          .formArea__check-fig-sp label {
            width: 50%;
            margin-bottom: 1.5em;
            padding-left: 1.75em;
            text-indent: -1.75em;
            padding-right: 0.25em; } }
        @media all and (max-width: 450px) {
          .formArea__check-fig-sp label.parasiteLv-high {
            width: 100%; } }
        .formArea__check-fig-sp label:before {
          content: "";
          display: inline-block;
          width: 1.25em;
          height: 1.25em;
          border: 3px solid #7d7d7d;
          border-radius: 3px;
          margin-right: 1em; }
          @media all and (max-width: 768px) {
            .formArea__check-fig-sp label:before {
              border: 2px solid #7d7d7d; } }
          @media all and (max-width: 560px) {
            .formArea__check-fig-sp label:before {
              margin-right: 0.5em; } }
        .formArea__check-fig-sp label:after {
          content: "";
          display: block;
          width: 0.75em;
          height: 0.5em;
          border-bottom: 3px solid #ff671f;
          border-left: 3px solid #ff671f;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(34%, 45%) rotate(-45deg) scale(0); }
          @media all and (max-width: 768px) {
            .formArea__check-fig-sp label:after {
              border-bottom: 2px solid #ff671f;
              border-left: 2px solid #ff671f; } }
      .formArea__check-fig-sp input[type=checkbox] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 3px;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-sp input[type=checkbox] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-fig-sp input[type=checkbox] + label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #ff671f;
        border-left: 3px solid #ff671f;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.1em;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .formArea__check-fig-sp input[type=checkbox] + label:after {
            border-bottom: 2px solid #ff671f;
            border-left: 2px solid #ff671f; } }
      .formArea__check-fig-sp input[type=radio] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 50%;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-sp input[type=radio] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-fig-sp input[type=radio] + label:after {
        content: "";
        display: block;
        width: 0.6em;
        height: 0.6em;
        background-color: #ff671f;
        border-radius: 50%;
        position: absolute;
        left: 0.625em;
        top: 0.625em;
        margin-top: -0.1em;
        border: none;
        transform: translate(-0.3em, -0.3em) scale(0); }
      .formArea__check-fig-sp input:checked + label {
        color: #ff671f; }
      .formArea__check-fig-sp input[type=checkbox]:checked + label:after {
        transform: translate(34%, 45%) rotate(-45deg) scale(1); }
      .formArea__check-fig-sp input[type=radio]:checked + label:after {
        transform: translate(-0.3em, -0.3em) scale(1); }
      .formArea__check-fig-sp input[type=checkbox][disabled] + label {
        cursor: auto;
        opacity: .5; }
      .formArea__check-fig-sp input[type=checkbox][disabled]:checked + label {
        cursor: auto;
        color: inherit;
        opacity: 1; }
      @media all and (max-width: 700px) {
        .formArea__check-fig-sp label {
          width: 50%;
          margin-bottom: 1.5em;
          padding-left: 1.75em;
          text-indent: -1.75em;
          padding-right: .25em; } }
      @media all and (max-width: 450px) {
        .formArea__check-fig-sp {
          padding-left: 0;
          font-size: 0.94737em; } }
      @media all and (max-width: 375px) {
        .formArea__check-fig-sp {
          letter-spacing: 0; } }
      .formArea__check-fig-sp:after {
        content: "";
        clear: both;
        display: block; }
    .formArea__check-fig-pc {
      vertical-align: middle;
      /* mixin */ }
      .formArea__check-fig-pc ul:after {
        content: "";
        clear: both;
        display: block; }
      .formArea__check-fig-pc input {
        display: none; }
      .formArea__check-fig-pc label {
        display: block;
        float: left;
        text-align: left;
        line-height: 1;
        vertical-align: middle;
        padding-right: 2em;
        padding-left: 2.25em;
        text-indent: -2.25em;
        margin-bottom: 1em;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent; }
        .formArea__check-fig-pc label.hover {
          color: #ff671f; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-pc label {
            width: 33.33333%;
            padding-right: 0; } }
        @media all and (max-width: 560px) {
          .formArea__check-fig-pc label {
            width: 50%;
            margin-bottom: 1.5em;
            padding-left: 1.75em;
            text-indent: -1.75em;
            padding-right: 0.25em; } }
        @media all and (max-width: 450px) {
          .formArea__check-fig-pc label.parasiteLv-high {
            width: 100%; } }
        .formArea__check-fig-pc label:before {
          content: "";
          display: inline-block;
          width: 1.25em;
          height: 1.25em;
          border: 3px solid #7d7d7d;
          border-radius: 3px;
          margin-right: 1em; }
          @media all and (max-width: 768px) {
            .formArea__check-fig-pc label:before {
              border: 2px solid #7d7d7d; } }
          @media all and (max-width: 560px) {
            .formArea__check-fig-pc label:before {
              margin-right: 0.5em; } }
        .formArea__check-fig-pc label:after {
          content: "";
          display: block;
          width: 0.75em;
          height: 0.5em;
          border-bottom: 3px solid #ff671f;
          border-left: 3px solid #ff671f;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(34%, 45%) rotate(-45deg) scale(0); }
          @media all and (max-width: 768px) {
            .formArea__check-fig-pc label:after {
              border-bottom: 2px solid #ff671f;
              border-left: 2px solid #ff671f; } }
      .formArea__check-fig-pc input[type=checkbox] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 3px;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-pc input[type=checkbox] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-fig-pc input[type=checkbox] + label:after {
        content: "";
        display: block;
        width: 0.75em;
        height: 0.5em;
        border-bottom: 3px solid #ff671f;
        border-left: 3px solid #ff671f;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.1em;
        transform: translate(34%, 45%) rotate(-45deg) scale(0); }
        @media all and (max-width: 768px) {
          .formArea__check-fig-pc input[type=checkbox] + label:after {
            border-bottom: 2px solid #ff671f;
            border-left: 2px solid #ff671f; } }
      .formArea__check-fig-pc input[type=radio] + label:before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        border: 3px solid #7d7d7d;
        border-radius: 50%;
        margin: -0.15em 0.5em 0 0; }
        @media all and (max-width: 768px) {
          .formArea__check-fig-pc input[type=radio] + label:before {
            border: 2px solid #7d7d7d; } }
      .formArea__check-fig-pc input[type=radio] + label:after {
        content: "";
        display: block;
        width: 0.6em;
        height: 0.6em;
        background-color: #ff671f;
        border-radius: 50%;
        position: absolute;
        left: 0.625em;
        top: 0.625em;
        margin-top: -0.1em;
        border: none;
        transform: translate(-0.3em, -0.3em) scale(0); }
      .formArea__check-fig-pc input:checked + label {
        color: #ff671f; }
      .formArea__check-fig-pc input[type=checkbox]:checked + label:after {
        transform: translate(34%, 45%) rotate(-45deg) scale(1); }
      .formArea__check-fig-pc input[type=radio]:checked + label:after {
        transform: translate(-0.3em, -0.3em) scale(1); }
      .formArea__check-fig-pc input[type=checkbox][disabled] + label {
        cursor: auto;
        opacity: .5; }
      .formArea__check-fig-pc input[type=checkbox][disabled]:checked + label {
        cursor: auto;
        color: inherit;
        opacity: 1; }
      @media all and (max-width: 768px) {
        .formArea__check-fig-pc {
          display: none; } }
      .formArea__check-fig-pc li:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 23%;
        top: 8.3%;
        left: 10.6%; }
        .formArea__check-fig-pc li:nth-child(1) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(1) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(1) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(1) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(1) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(1) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(1) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 23%;
        top: 8.3%;
        left: 36.6%; }
        .formArea__check-fig-pc li:nth-child(2) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(2) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(2) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(2) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(2) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(2) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(2) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(3) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 14%;
        top: 22.8%;
        left: 45%; }
        .formArea__check-fig-pc li:nth-child(3) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(3) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(3) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(3) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(3) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(3) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(3) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 16%;
        top: 20.3%;
        left: 5%; }
        .formArea__check-fig-pc li:nth-child(4) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(4) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(4) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(4) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(4) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(4) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(4) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(5) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 16%;
        top: 40.5%;
        left: 42.5%; }
        .formArea__check-fig-pc li:nth-child(5) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(5) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(5) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(5) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(5) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(5) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(5) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(6) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 19%;
        top: 44.1%;
        left: 3.2%; }
        .formArea__check-fig-pc li:nth-child(6) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(6) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(6) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(6) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(6) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(6) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(6) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(7) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 13%;
        top: 64.6%;
        left: 3.9%; }
        .formArea__check-fig-pc li:nth-child(7) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(7) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(7) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(7) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(7) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(7) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(7) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(8) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 13%;
        top: 65.3%;
        left: 41.3%; }
        .formArea__check-fig-pc li:nth-child(8) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(8) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(8) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(8) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(8) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(8) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(8) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(9) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 16%;
        top: 75.3%;
        left: 42.7%; }
        .formArea__check-fig-pc li:nth-child(9) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(9) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(9) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(9) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(9) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(9) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(9) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(10) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 13%;
        top: 87.3%;
        left: 5.3%; }
        .formArea__check-fig-pc li:nth-child(10) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(10) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(10) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(10) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(10) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(10) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(10) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(11) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 13%;
        top: 86.3%;
        left: 41.4%; }
        .formArea__check-fig-pc li:nth-child(11) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(11) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(11) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(11) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(11) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(11) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(11) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(12) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 11%;
        top: 18%;
        left: 61.9%; }
        .formArea__check-fig-pc li:nth-child(12) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(12) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(12) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(12) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(12) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(12) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(12) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(13) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 11%;
        top: 42.3%;
        left: 81.4%; }
        .formArea__check-fig-pc li:nth-child(13) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(13) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(13) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(13) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(13) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(13) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(13) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(14) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 23%;
        top: 65.7%;
        left: 76.2%; }
        .formArea__check-fig-pc li:nth-child(14) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(14) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(14) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(14) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(14) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(14) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(14) label {
              font-size: 14px; } }
      .formArea__check-fig-pc li:nth-child(15) {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        letter-spacing: 0;
        display: inline-block;
        width: 20%;
        top: 85.7%;
        left: 77.2%; }
        .formArea__check-fig-pc li:nth-child(15) label {
          padding-right: 0;
          margin-bottom: 0;
          font-size: 18px; }
          .formArea__check-fig-pc li:nth-child(15) label:before {
            width: 1em;
            height: 1em;
            border: 2px solid #7d7d7d;
            background-color: #fff; }
          @media all and (max-width: 1020px) {
            .formArea__check-fig-pc li:nth-child(15) label {
              font-size: 18px; } }
          @media all and (max-width: 989px) {
            .formArea__check-fig-pc li:nth-child(15) label {
              font-size: 17px; } }
          @media all and (max-width: 958px) {
            .formArea__check-fig-pc li:nth-child(15) label {
              font-size: 16px; } }
          @media all and (max-width: 927px) {
            .formArea__check-fig-pc li:nth-child(15) label {
              font-size: 15px; } }
          @media all and (max-width: 896px) {
            .formArea__check-fig-pc li:nth-child(15) label {
              font-size: 14px; } }
  .formArea__fig-pc {
    position: relative; }
    .formArea__fig-pc_base {
      width: 100%; }
      @media all and (max-width: 768px) {
        .formArea__fig-pc_base {
          display: none; } }
  .formArea__fig-sp {
    display: none; }
    @media all and (max-width: 768px) {
      .formArea__fig-sp {
        display: block; } }
    .formArea__fig-sp_base {
      width: 100%;
      margin-bottom: 1.5em; }
  .formArea__select select {
    width: 100%;
    outline: none;
    line-height: 1;
    font-family: inherit;
    color: #464646;
    font-weight: bold;
    background: none transparent;
    vertical-align: middle;
    font-size: 0.73684em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0.75em;
    border: 2px solid #7d7d7d;
    border-radius: 0.5em; }
    .formArea__select select:focus {
      box-shadow: 0px 0px 5px 1px #ff671f; }
    @media all and (max-width: 768px) {
      .formArea__select select {
        font-size: 0.94737em;
        padding: 0.75em; } }
    @media all and (max-width: 560px) {
      .formArea__select select {
        border: 1px solid #7d7d7d; } }
  .formArea__select select option {
    background-color: #fff;
    color: #333; }
  .formArea__select select::-ms-expand {
    display: none; }
  .formArea__select select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #828c9a; }
  .formArea__select-wrap {
    position: relative;
    width: 100%; }
    .formArea__select-wrap:after {
      content: "";
      display: block;
      width: 5%;
      height: 55%;
      background-image: url(../svg/form_select_arrow.svg);
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 3%;
      transform: translate(0, -50%); }
      @media all and (max-width: 768px) {
        .formArea__select-wrap:after {
          right: 1%; } }
  .formArea__select-addrhospital {
    width: 30%;
    display: inline-block;
    margin-left: 2.5%; }
    .formArea__select-addrhospital select {
      width: 100%;
      outline: none;
      line-height: 1;
      font-family: inherit;
      color: #464646;
      font-weight: bold;
      background: none transparent;
      vertical-align: middle;
      font-size: 0.73684em;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0.75em;
      border: 2px solid #7d7d7d;
      border-radius: 0.5em; }
      .formArea__select-addrhospital select:focus {
        box-shadow: 0px 0px 5px 1px #ff671f; }
      @media all and (max-width: 768px) {
        .formArea__select-addrhospital select {
          font-size: 0.94737em;
          padding: 0.75em; } }
      @media all and (max-width: 560px) {
        .formArea__select-addrhospital select {
          border: 1px solid #7d7d7d; } }
    .formArea__select-addrhospital select option {
      background-color: #fff;
      color: #333; }
    .formArea__select-addrhospital select::-ms-expand {
      display: none; }
    .formArea__select-addrhospital select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #828c9a; }
    @media all and (max-width: 840px) {
      .formArea__select-addrhospital {
        display: block;
        margin-left: 0;
        width: 75%;
        margin-top: 1.25em; }
        .formArea__select-addrhospital select {
          font-size: 0.94737em;
          padding: 0.75em; } }
    .formArea__select-addrhospital-wrap {
      position: relative;
      width: 100%; }
      .formArea__select-addrhospital-wrap:after {
        content: "";
        display: block;
        width: 5%;
        height: 55%;
        background-image: url(../svg/form_select_arrow.svg);
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 3%;
        transform: translate(0, -50%); }
        @media all and (max-width: 768px) {
          .formArea__select-addrhospital-wrap:after {
            right: 1%; } }
      @media all and (max-width: 840px) {
        .formArea__select-addrhospital-wrap:after {
          width: 1em;
          right: 2%; } }
  .formArea__select-col3 select {
    width: 100%;
    outline: none;
    line-height: 1;
    font-family: inherit;
    color: #464646;
    font-weight: bold;
    background: none transparent;
    vertical-align: middle;
    font-size: 0.73684em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0.75em;
    border: 2px solid #7d7d7d;
    border-radius: 0.5em; }
    .formArea__select-col3 select:focus {
      box-shadow: 0px 0px 5px 1px #ff671f; }
    @media all and (max-width: 768px) {
      .formArea__select-col3 select {
        font-size: 0.94737em;
        padding: 0.75em; } }
    @media all and (max-width: 560px) {
      .formArea__select-col3 select {
        border: 1px solid #7d7d7d; } }
  .formArea__select-col3 select option {
    background-color: #fff;
    color: #333; }
  .formArea__select-col3 select::-ms-expand {
    display: none; }
  .formArea__select-col3 select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #828c9a; }
  .formArea__select-col3:after {
    content: "";
    clear: both;
    display: block; }
  .formArea__select-col3-wrap {
    position: relative;
    width: 31.3%;
    float: left;
    margin-right: 3%; }
    .formArea__select-col3-wrap:after {
      content: "";
      display: block;
      width: 5%;
      height: 55%;
      background-image: url(../svg/form_select_arrow.svg);
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 3%;
      transform: translate(0, -50%); }
      @media all and (max-width: 768px) {
        .formArea__select-col3-wrap:after {
          right: 1%; } }
    .formArea__select-col3-wrap:nth-of-type(3n) {
      margin-right: 0; }
    @media all and (max-width: 768px) {
      .formArea__select-col3-wrap {
        width: 75%;
        float: none;
        margin-right: 0;
        margin-bottom: 1em; } }
  .formArea__err {
    text-align: left;
    line-height: 1;
    letter-spacing: 0;
    padding: 0.5em 1em;
    font-size: 0.84211em;
    color: #fff;
    background-color: #ff2458;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none; }
    .formArea__err:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 7px 0 7px;
      border-color: #ff2457 transparent transparent transparent;
      position: absolute;
      bottom: -12px;
      left: 0.6em; }

.forget {
  margin: 1em auto 2em;
  display: inline-block;
  text-align: justify;
  color: #464646;
  line-height: 1.5;
  font-size: 0.84211em;
  font-weight: normal; }
  .forget .sp {
    display: none; }
  @media all and (max-width: 560px) {
    .forget {
      margin: 1em auto 0;
      letter-spacing: 0; }
      .forget br {
        display: none; }
      .forget .sp {
        display: inline; } }
