.btn{
    /* -- mixin -- */
    @mixin _link{
        color: #fff;
        font-weight: bold;
        font-size: 19/$baseSize+em;
        display: inline-block;
        line-height: 1.2;
        height: 83px;
        padding: 1.5em 3.25em 1.5em 1.5em;
        background-color: $keyColor_orng;
        border-radius: 1em;
        position: relative;
        transition: background-color 200ms ease;
        @include query(1060){height: auto;}
        &:hover{
            background-color: $keyColor_orng_light;
            &:after{background-color: $keyColor_navy_light;}
        }
        &:after{
            content: "";
            display: block;
            width: 46px;
            height: 46px;
            background-color: $keyColor_navy;
            border-radius: 25%;
            background-image: url(../svg/btn_link_arrow.svg);
            background-position: center center;
            position: absolute;
            top: 50%;
            right: 1em;
            margin-top: -23px;
            transition: background-color 200ms ease;
            @include query(1060){
                width: 36px;
                height: 36px;
                margin-top: -18px;
                background-size: 30%;
            }
            @include query(768){
                width: 26px;
                height: 26px;
                margin-top: -13px;
                background-size: 30%;
            }
        }
    }
    @mixin -prev{
        padding: 1.5em 1.5em 1.5em 3.25em;
        &:after{
            right: auto;
            left: 1em;
            transform: rotate(180deg);
        }
    }
    @mixin inBlock{
        .contents &{ //in block "contents"
            width: 400px;
            @include query(560){width: 100%;}
        }
        .paging &{ //in block "paging"
            width: 400px;
            @include query(560){width: 100%;}
        }
        .mapIndex &{ //in block "mapIndex"
            width: 400px;
            @include query(450){width: 100%;}
        }
        .conversion &{ //in block "conversion"
            width: 400px;
            @include query(450){width: 100%;}
        }
        .medicinemodal__window_link &{ //in block "medicinemodal"
            width: 400px;
            @include query(768){width: 100%;}
            @include query(430){
                font-size: 17/$baseSize+em;
                padding: 1.5em 4em 1.5em 1.5em;
            }
        }
        .box__area_content_txt-left &, //in block floated "box__area_content_txt"
        .box__area_content_txt-right & //in block floated "box__area_content_txt"
        {
            text-align: center;
            width: 100%;
        }
        .navi &{width: 100%;} //in block "navi"
        .map-search__area_map-btns &{ //in block "map_btns"
            font-size: 16/$baseSize+em;
            line-height: 1;
            width: 100%;
            height: 100%;
            display: table;
            @include query(768){
                font-size: 19/$baseSize+em;
            }
            span{
                display: table-cell;
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }
            &:after{
                width: 38px;
                height: 38px;
                margin-top: -19px;
                background-size: 25%;
                @include query(1060){
                    width: 28px;
                    height: 28px;
                    margin-top: -14px;
                }
                @include query(768){
                    width: 22px;
                    height: 22px;
                    margin-top: -11px;
                }
            }
        }
        .mapmodal__window_btn &{ //in block "mapmodal"
            width: 100%;
            height: 100%;
            padding: 0;
            display: table-cell;
            vertical-align: middle;
            &:after{display: none;}
        }
        //in form block
        .formArea &{color: #fff;}
        .formArea-login &{color: #fff;}
        .box__area-form &{
            margin: 3em auto;
            @include query(560){width: 75%;}
        }
        .paging-form &{
            margin: auto;
            width: 100%;
        }
        .formArea-thanx &{color: #fff;}
    }

    /* -- elements -- */
    &_link{
        @include _link;
        @include inBlock;
        @include query(560){padding: 1.5em;}
        @include query(375){height: auto;}
        &-prev{
            @include _link;
            @include -prev;
            @include inBlock;
            &-coming{
                @include _link;
                @include inBlock;
                @include query(560){padding: 1.5em;}
                @include query(375){height: auto;}
                cursor: auto;
                &:hover{
                    background-color: $keyColor_orng;
                    &:after{background-color: $keyColor_navy;}
                }
            }
        }
        &-coming{
            @include _link;
            @include inBlock;
            @include query(560){padding: 1.5em;}
            @include query(375){height: auto;}
            cursor: auto;
            &:hover{
                background-color: $keyColor_orng;
                &:after{background-color: $keyColor_navy;}
            }
        }
        &-2line{
            /* mixin */
            @mixin -two_line{
                @include _link;
                @include inBlock;
                text-align: left;
                padding: 0.9em 3.25em 0.9em 20%;
                @include query(768){padding: 0.9em 3em 1em 20%;}
                @include query(560){
                    br{display: none;}
                    text-align: center;
                    padding: 1.5em;
                }
                @include query(375){height: auto;}
            }
            @include -two_line;
            &-coming{
                @include -two_line;
                cursor: auto;
                &:hover{
                    background-color: $keyColor_orng;
                    &:after{background-color: $keyColor_navy;}
                }
            }
        }
    }
    &_form{
        color: #fff;
        font-weight: bold;
        font-size: 16/$baseSize+em;
        display: inline-block;
        line-height: 1;
        margin: 0 1em;
        padding: 1em;
        background-color: $keyColor_navy;
        border-radius: 0.5em;
        position: relative;
        transition: background-color 200ms ease;
        @include query(768){margin-right: 0;}
        &:hover{background-color: $keyColor_navy_light;}
        &:focus{box-shadow:0px 0px 5px 1px $keyColor_orng;}
    }
    &_coming{
        /* mixin */
        @mixin inBlock_coming{
            .conversion &{ //in block "conversion"
                width: 590px;
                @include query(640){width: 100%;}
            }
        }
        @include _link;
        @include inBlock_coming;
        &:after{display: none;}
        border-radius: 0.4em;
        padding: 0.5em;
        height: auto;
        line-height: 1;
        vertical-align: middle;
        background-color: #fff;
        text-align: center;
        color: $keyColor_navy;
        font-size: 50/$baseSize+em;
        cursor: auto;
        @include query(560){font-size: 35/$baseSize+em;}
        &:hover{background-color: #fff;}
    }
}
