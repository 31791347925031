.qa{
    counter-reset: qNum 0;
    dt{
        color: $keyColor_orng;
        padding-left: 115/1000*100+%;
        margin-bottom: 0.75em;
        text-align: left;
        vertical-align: middle;
        position: relative;
        @include query(768){padding-left: 0;}
        @include query(560){
            margin-top: 5em;
            line-height: 1.6;
            text-align: center;
            span{display: inline-block;}
        }
        &~dt{
            margin-top: 67/$baseSize+em;
            @include query(560){margin-top: 5em;}
        }
        &:before{
            color: #fff;
            font-size: 31/$baseSize+em;
            line-height: 1;
            font-family: 'Comfortaa', cursive;
            -webkit-text-stroke: 1px #fff;
            counter-increment: qNum 1;
            content:"Q" counter(qNum);
            display: inline-block;
            margin: 0 0.5em 0 0 ;
            padding: 0.25em 0.5em;
            background-color: $keyColor_orng;
            border-radius: 1em;
            @include query(560){
                position: absolute;
                top: -1.75em;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    dd{
        padding: 1.5em 5em;
        background-color: #fff;
        border-radius: 2.5em;
        @include query(1060){padding: 1.5em 3em;}
        @include query(768){
            padding: 1.5em 2em;
            border-radius: 1.5em;
            text-align: justify;
        }
    }
}
