.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    @include fig;
    &-clear{
        @include fig;
        &:after{content:"";clear:both;display:block;}
    }
    &-find{
        @include fig;
        @include query(560){width: 60%;}
        @include query(375){width: 75%;}
        &-clear{
            @include fig;
            @include query(560){width: 60%;}
            @include query(375){width: 75%;}
            &:after{content:"";clear:both;display:block;}
        }
    }
    &__area{
        /* mixin */
        @mixin -col3{
            width: 293/1000*100+%;
            margin-right: 60/1000*100+%;
            float: left;
            &:nth-of-type(3n){
                margin-right: 0;
                dt:after{display: none;}//arrow非表示
            }
            @include query(560){
                width: 100%;
                float: none;
                margin-right: 0px;
                margin-bottom: 60/1000*100+%;
                &:last-of-type{
                    dd:after{display: none;}
                }
            }
        }
        /* mixin */
        @mixin _img{
            background-color: #fff;
            border-radius: 2.5em;
            width: 100%;
            height: 100%;
            position: relative;
            @include query(560){border-radius: 1.5em;}
            img{
                @include img_center;
                @include query(1060){width: 50%;}
            }
            &:after{
                content: "";
                display: block;
                background-image: url(../svg/fig_find_arrow.svg);
                background-position: center center;
                background-size: cover;
                position: absolute;
                width: 25/293*100+%;
                height: 40/293*100+%;
                right: -46/293*100+%;
                top: 50%;
                transform: translateY(-50%);
                @include query(560){display: none;}
            }
        }
        @mixin _img-col3{
            padding-top: 293/293*100+%;
            span{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        @mixin _txt{
            line-height: 1.4;
            margin-top: 1em;
            @include query(560){
                position: relative;
                &:after{
                    content: "";
                    display: block;
                    background-image: url(../svg/fig_find_arrow-down.svg);
                    background-position: center center;
                    background-size: contain;
                    width: 100%;
                    height: 20px;
                    margin-top: 1em;
                    @include query(375){height: 15px;}
                }
            }
        }
        &_img{
            @include _img;
        }
        &_txt{
            @include _txt;
        }
        &-find{
            @include -col3;
            &_img{
                @include _img;
                @include _img-col3;
                &-adj1{
                    @include _img;
                    @include _img-col3;
                    img{
                        left: 10%;
                        @include query(1060){width: 70%;}
                    }
                }
                &-adj2{
                    @include _img;
                    @include _img-col3;
                    img{top: -10%;}
                }
            }
            &_txt{
                @include _txt;
            }
        }
    }
}
