section{
    @include query(1060){padding: 0 20px;}
}
h2{
    font-size: 25/$baseSize+em;
    color: #fff;
    line-height: 1;
    margin: 8em auto 2.5em;
    padding: 1em;
    width: 100%;
    background-color: $keyColor_navy;
    border-radius: 2em;
    .second &{max-width: 650px;}
    @include query(768){margin: 6em auto 2.5em;}
}
p{
    margin: 0 auto 3em;
}

strong{
    color: $keyColor_orng;
}
.medicine-prlink__midashi{
    color:$keyColor_navy;
    font-size: 1em;
    margin: 8em auto 1em;
    line-height: 1.4;
    span{display: inline-block;}
}
