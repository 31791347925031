.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-donotpull{
        position: relative;
        max-width: 650px;
        margin: 0 auto 58/1000*100+%;
        &__dani{
            display: inline-block;
            width: 50%;
            position: absolute;
            top: 0%;
            left: 23%;
            z-index: -1;
            animation: dani_pull 2.5s infinite normal;
        }
        &__base_parts{
            position: absolute;
            display: inline-block;
            width: 30.8%;
            left: 32.5%;
            top: 71%;
            line-height: 1;
            transform: scale(1, 0);
            transform-origin: bottom;
            animation: base_parts 2.5s infinite normal;
        }
        &__x{
            display: inline-block;
            width: 17%;
            position: absolute;
            top: 53.2%;
            left: 60.1%;
            opacity: 1;
            animation: donotpull_x 1s 6;
        }
    }
}
@keyframes dani_pull{
    0%{margin-top: 0;}
    50%{margin-top: -5%;}
    80%{margin-top: -5%;}
    100%{margin-top: 0;}
}
@keyframes base_parts{
    0%{transform: scale(1, 0);}
    50%{transform: scale(1, 1);}
    80%{transform: scale(1, 1);}
    100%{transform: scale(1, 0);}
}
@keyframes donotpull_x{
    50%{opacity: 0;}
}
