.mapIndex{
    padding: 130/$baseSize+em 0 80/$baseSize+em;
    background-color: #fff;
    @include query(1060){margin: 0 -20px;}
    &__area{
        max-width: 1000px;
        margin: auto;
        @include query(1060){padding: 0 20px;}
        h2{
            margin: 0 auto 40/$baseSize+em;
            &~h2{margin: 180/$baseSize+em auto 40/$baseSize+em;}
        }
        p{
            color: $keyColor_navy;
            span{display: inline-block;}
            @include query(460){line-height: 1.6;}
        }
        &_fig{
            /* mixin */
            @mixin _fig{
                display: inline-block;
                margin: 0 auto 3em;
                position: relative;
                img{width: 100%;}
            }
            @include _fig;
            &-coming{
                @include _fig;
                /**
                  &:after{
                      content: "";
                      display: block;
                      width: 237/744*100+%;
                      height: 237/577*100+%;
                      background-image: url(../svg/coming.svg);
                      background-size: contain;
                      position: absolute;
                      top: -19/577*100+%;
                      left: -39/744*100+%;
                      @include query(880){left: 0;}
                  }
                ****/  
            }
            &-balloon{
                li{
                    position: absolute;
                    display: inline-block;
                    transform: scale(0);
                    transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.600), margin-top 200ms ease 500ms;
                    transform-origin: bottom center;
                    &:nth-of-type(1){
                        width: 22.3%;
                        top: 25.2%; left: 28.7%;
                    }
                    &:nth-of-type(2){
                        width: 20.9%;
                        top: 39.5%; left: 73.6%;
                    }
                    &:nth-of-type(3){
                        width: 17.2%;
                        top: 60.4%; left: 11.5%;
                    }
                    &:nth-of-type(4){
                        width: 13.6%;
                        top: 62.3%; left: 54%;
                    }
                    &.on{
                        transform: scale(1);
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
