.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-danilife{
        @include fig;
        position: relative;
        &__img{
            margin: auto;
            img{-webkit-tap-highlight-color:rgba(0,0,0,0);}
            area{
                outline: none;
                cursor: pointer;
            }
            .sp{display: none;}
            @include query(768){
                .pc{display: none;}
                .sp{display: inline;}
            }
        }
        &__info{
            max-width: 906px;
            text-align: left;
            margin: 0 auto 35/1000*100+%;
            span{
                font-size: 15/$baseSize+em;
                display: inline-block;
                padding: 1em 2em;
                border: 2px dotted $keyColor_gray;
                border-radius: 1em;
            }
            img{
                vertical-align: middle;
                margin-right: 0.25em;
                width: 2em;
            }
            @include query(768){text-align: center;}
        }
    }
}
