.concerned{
    text-align: justify;
    width: 854/1000*100+%;
    padding: 1em 2.5em;
    margin: auto;
    background-color: #fff;
    border-radius: 1.5em;
    display: table;
    vertical-align: middle;
    @include query(768){
        width: 100%;
        padding: 2em;
    }
    dt{
        display: table-cell;
        text-align: left;
        line-height: 1.4;
        padding: 0 1em 0 0;
        border-right: 0.2em dotted #7d7d7d;
        @include query(1060){
            text-align: center;
            width: 35%;
        }
        @include query(768){
            display: block;
            width: 100%;
            text-align: left;
            padding: 0 1em 1.5em 0;
            border-right: none;
            border-bottom: 3px dotted #7d7d7d;
            font-size: 22/$baseSize+em;
            line-height: 1;
        }
    }
    dd{
        font-size: 14/$baseSize+em;
        font-weight: normal;
        display: table-cell;
        padding: 1em 1em 1em 3em;
        @include query(1060){width: 65%;}
        @include query(768){
            display: block;
            width: 100%;
            padding: 2em 0em 0 0;
            font-size: 16/$baseSize+em;
        }
        @include query(480){
            .pc{display: none;}
        }
        @include query(375){
            font-size: 18/$baseSize+em;
            line-height: 1.6;
        }
        a{
            color: #2496df;
            text-decoration: underline;
        }
    }
}
