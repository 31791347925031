@charset "UTF-8";

/* -- keyColor -- */
$keyColor_gray: #776c66;
$keyColor_navy: #005366;
$keyColor_orng: #ff671f;
$keyColor_yel: #ffe613;

$keyColor_orng_light: #ff854c;
$keyColor_navy_light: #337585;

/* -- fontsize_base -- */
$baseSize: 19;

/* -- common_mixin -- */
@mixin img_center{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

@mixin query($size){
  @media all and (max-width: $size + px) {
    @content;
  }
}

/* -- webfont -- */
.comfortaa{
    font-family: 'Comfortaa', cursive;
    -webkit-text-stroke: 1px $keyColor_gray;
}


/* -- import -- */
//basic
@import "base";
@import "article";

//element
@import "element_btn";
@import "element_note";

//block
@import "header";
@import "menu";

@import "contents";
@import "firstview-index";
@import "firstview";
@import "navi";
@import "box";

@import "fig_find";
@import "fig_nomilife";
@import "fig_danilife";
@import "fig_nomiwhere";
@import "fig_daniwhere";
@import "fig_nomiplace";
@import "fig_nomijump";
@import "fig_donotpull";
@import "fig_nomiact";

@import "qa";
@import "paging";

@import "lifemodal";
@import "slider";
@import "medicinemodal";

@import "mapIndex";
@import "map-search";
@import "map-madani";
@import "mapmodal";
@import "concerned";
@import "cities";
@import "hospital";
@import "links";
@import "totop";

@import "footer";
@import "conversion";

@import "a-fueru-madani";
@import "a-madani-zoom";

@import "form";
