.firstview{
    &__img{
        margin-bottom: 60/$baseSize+em;
        &-top{margin-bottom: 120/$baseSize+em;}
    }
    &__title{
        /* mixin */
        @mixin __title{
            margin: 94px auto 100px;
            @include query(500){margin: 25% auto 17%;}
            .sp{display: none;}
            @include query(768){
                .pc{display: none;}
                .sp{display: inline;}
            }
        }
        @include __title;
        opacity: 0;
        transition: opacity 750ms ease;
        &.on{opacity: 1;}
        &-noeffect{
            @include __title;
            margin: 94px auto 50px;
        }
    }
    &__lead{
        /* mixin */
        @mixin __lead{
            span{
                display: inline-block;
                width: max-content;
            }
        }
        @include __lead;
        opacity: 0;
        transition: opacity 1s ease;
        &.on{opacity: 1;}
        &-noeffect{
            @include __lead;
        }
    }
    /* mixin */
    @mixin __fig{
        margin: 0 auto 55px;
        opacity: 0;
        transition: opacity 1.5s ease;
        @include query(500){margin: 0 auto 55/1000*100+%;}
        &.on{opacity: 1;}
        &:after{content:"";clear:both;display:block;}
    }
    &__fig{
        @include __fig;
        &_dog{
            /* mixin */
            @mixin _dog{
                float: right;
                display: inline-block;
                position: relative;
                line-height: 1;
            }
            &-flea{
                @include _dog;
                width: 52.2%;
            }
            &-flea-sick{
                @include _dog;
                width: 48.3%;
            }
            &-flea-extermination{
                @include _dog;
                width: 51.8%;
            }
            &-tick{
                @include _dog;
                width: 52.2%;
            }
            &-tick-sick{
                @include _dog;
                width: 48.3%;
            }
            &-tick-extermination{
                @include _dog;
                width: 53.4%;
            }
            &-medicine{
                @include _dog;
                width: 46%;
            }
            &_effect{
                /* mixin */
                @mixin _effect{
                    display: inline-block;
                    position: absolute;
                    transform-origin: center bottom;
                    animation: fv_tilt 2.5s steps(1) infinite;
                }
                &-trouble{
                    @include _effect;
                    width: 30.8%;
                    top: -32%;
                    left: 35%;
                }
                &-question{
                    @include _effect;
                    width: 21%;
                    top: -37%;
                    left: 40%;
                }
            }
            &_eyebrow{
                display: inline-block;
                width: 12.1%;
                position: absolute;
                top: 49%;
                left: 20%;
                line-height: 0;
                font-size: 0;
            }
        }
        &_other{
            /* mixin */
            @mixin _other{
                float: left;
                display: inline-block;
            }
            &-flea{
                @include _other;
                margin-top: 34/1000*100+%;
                width: 35.9%;
            }
            &-flea-sick{
                @include _other;
                margin-top: 34/1000*100+%;
                width: 45.3%;
            }
            &-flea-extermination{
                @include _other;
                margin-top: 10/1000*100+%;
                width: 42.5%;
            }
            &-tick{
                @include _other;
                margin-top: -30/1000*100+%;
                width: 42.2%;
            }
            &-tick-sick{
                @include _other;
                margin-top: -20/1000*100+%;
                width: 45.3%;
            }
            &-tick-extermination{
                @include _other;
                margin-top: -30/1000*100+%;
                width: 42.7%;
            }
            &-medicine{
                @include _other;
                width: 48.3%;
            }
        }
        &-flea{
            @include __fig;
            max-width: 364px;
            padding: 0 20px;
        }
        &-flea-sick{
            @include __fig;
            max-width: 392px;
            padding: 0 20px;
        }
        &-flea-extermination{
            @include __fig;
            max-width: 366px;
            padding: 0 20px;
        }
        &-tick{
            @include __fig;
            max-width: 366px;
            padding: 0 20px;
        }
        &-tick-sick{
            @include __fig;
            max-width: 394px;
            padding: 0 20px;
        }
        &-tick-extermination{
            @include __fig;
            max-width: 363px;
            padding: 0 20px;
        }
        &-medicine{
            @include __fig;
            max-width: 408px;
            padding: 0 20px;
        }
        @keyframes fv_tilt{
          25%{transform: rotate(15deg);}
          50%{transform: rotate(0deg);}
          75%{transform: rotate(-15deg);}
          100%{transform: rotate(0deg);}
        }
    }
}
