.a-madani-zoom{
    overflow: hidden;
    margin-bottom: 5em;
    >svg{display: none;}
    &__left-block,
    &__right-block{
        width: 50%;
        @include query(790){width: 100%;}
        dt>strong{
            font-size: 1.3em;
        }
    }
    &__left-block{
        float: left;
        @include query(790){
            float: none;
            margin-bottom: 3em;
        }
    }
    &__right-block{
        float: right;
        @include query(790){float: none;}
    }
    &__illust{
        position: relative;
        margin-bottom:1em;
    }
    &__dani,&__dani--small{
        @include img_center;
        filter:url('#blur5');
        -webkit-filter: blur(5px);
        transition: transform .5s linear .5s;
        .js-animation &{
            animation:blur-anim 1s linear;
            filter:none;
        }
    }
    &__dani{
        transform: translateX(-5%) scale(1.2);
        .js-animation &{
            transform: translateX(-5%) scale(1);
        }
    }
    &__dani--small{
        transform: translateX(-5%) scale(.5);
        .js-animation &{
            transform: translateX(-5%) scale(.2);
        }
    }
}
@keyframes blur-anim{
  0% {
    filter:url('#blur5');
    -webkit-filter: blur(5px);
  }
  20% {
    filter:url('#blur4');
    -webkit-filter: blur(4px);
  }
  40% {
      filter:url('#blur3');
      -webkit-filter: blur(3px);
  }
  60% {
      filter:url('#blur2');
      -webkit-filter: blur(2px);
  }
  80% {
      filter:url('#blur1');
      -webkit-filter: blur(1px);
  }
  100% {
      filter:url('#blur0');
      -webkit-filter: blur(0px);
  }
}
