.firstview-index{
    overflow: hidden;
    @include query(1060){
        margin: 0 -20px;
    }
    &__title{
        /* mixin */
        @mixin __title{
            margin: 104px auto -80px;
            .sp{display: none;}
            @include query(1060){padding: 0 20px;}
            @include query(500){margin: 25% auto -15%;}
            @include query(768){
                .pc{display: none;}
                .sp{display: inline;
                    width: 100%;}
            }
        }
        @include __title;
        opacity: 0;
        transform: translate(0,50px) scale(1,0);
        transition:
            opacity 400ms ease,
            transform 400ms cubic-bezier(0.000, -0.150, 0.000, 1.850);
        &.on{
            opacity: 1;
            transform: translate(0,0px) scale(1,1);
        }
    }
    &__lead{
        /* mixin */
        @mixin __lead{
            padding: 0 10px;
            span{display: inline-block;}
        }
        @include __lead;
        opacity: 0;
        transition: opacity 1s ease;
        &.on{opacity: 1;}
    }
    &__fig{
        position: relative;
        display: inline-block;
        line-height: 0;
        font-size: 0;
        @include query(768){
            padding: 10% 0;
            margin-bottom: -10%;
        }
        .tmp{
            opacity: .5;
            position: absolute;
            top: 2.9%;
            left: 49%;
            transform: translateX(-50%);
        }
        &-dog{
            position: relative;
            z-index: 1;
            img{width: 100%;}
        }
        &-eyebrow{
            position: absolute;
            display: inline-block;
            width: 2.5%;
            top: 47.5%;
            left: 43.5%;
            line-height: 0;
            font-size: 0;
            opacity: 0;
            z-index: 1;
            transition: opacity 300ms ease 500ms;
            &.on{opacity: 1;}
        }
        &-trouble{
            position: absolute;
            display: inline-block;
            width: 5.6%;
            top: 23.5%;
            left: 47%;
            line-height: 0;
            font-size: 0;
            opacity: 0;
            z-index: 1;
            transition: opacity 300ms ease 500ms;
            @include query(768){top: 23.5+6+%;}
            &.on{opacity: 1;}
        }
        &-baloon{
            transition: opacity 800ms ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &.hidden{opacity: 0;}
            /* mixin */
            @mixin -baloon{
                position: absolute;
                display: inline-block;
                transform: scale(0);
                transition: transform 600ms cubic-bezier(1.000, 0.000, 0.200, 1.600);
                transform-origin: bottom center;
                &.on{
                    transform: scale(1);
                    @include query(768){transform: scale(1.4);}
                }
            }
            &-01{
                @include -baloon;
                width: 28.3%;
                top: 31.2%;
                left: 0%;
                @include query(768){
                    top: 31.2+10+%;
                    left: 0+7+%;
                }
            }
            &-02{
                @include -baloon;
                width: 24.9%;
                top: 76.2%;
                left: 60.1%;
                @include query(768){
                    top: 76.2+6+%;
                    left: 60.1-4+%;
                }
            }
            &-03{
                @include -baloon;
                width: 34.8%;
                top: 39%;
                left: 65.2%;
                @include query(768){
                    top: 39+10+%;
                    left: 65.2-6+%;
                }
            }
            &-04{
                @include -baloon;
                width: 31.1%;
                top: 0%;
                left: 11.6%;
                @include query(768){
                    top: 0+7+%;
                    left: 11.6+0+%;
                }
            }
            &-05{
                @include -baloon;
                width: 28.1%;
                top: 66%;
                left: 6.7%;
                @include query(768){
                    top: 66+8+%;
                    left: 6.7+6+%;
                }
            }
            &-06{
                @include -baloon;
                width: 32.1%;
                top: 2.8%;
                left: 62.5%;
                @include query(768){
                    top: 2.8+10+%;
                    left: 62.5-5+%;
                }
            }
        }
        &-nomi{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            &.on{
                opacity: 1;
                animation: parent_move_left 3s steps(1);
            }
            /* mixin */
            @mixin -nomi{
                position: absolute;
                display: inline-block;
            }
            &-01{
                @include -nomi;
                width: 10.1%;
                top: 30.1%;
                left: 10%;
                animation: move1 2.5s steps(1) infinite;
            }
            &-02{
                @include -nomi;
                width: 11.7%;
                top: 48.1%;
                left: 20.4%;
                animation: move1 3s steps(1) infinite;
            }
            &-03{
                @include -nomi;
                width: 8.1%;
                top: 57.9%;
                left: 7.4%;
                animation: move1 2s steps(1) infinite;
            }
        }
        &-madani{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            &.on{
                opacity: 1;
                animation: parent_move_right 3s steps(1);
            }
            /* mixin */
            @mixin -madnai{
                position: absolute;
                display: inline-block;
            }
            &-01{
                @include -madnai;
                width: 9.2%;
                top: 30.6%;
                left: 80.9%;
                animation: move1 2.5s steps(1) infinite 0.2s;
            }
            &-02{
                @include -madnai;
                width: 13.1%;
                top: 42.8%;
                left: 64.7%;
                animation: move1 3s steps(1) infinite 0.2s;
            }
            &-03{
                @include -madnai;
                width: 8.8%;
                top: 59%;
                left: 81.6%;
                animation: move1 2s steps(1) infinite 0.2s;
            }
        }
    }
}

@keyframes move1{
    25%{transform: rotate(30deg);}
    50%{transform: rotate(0deg);}
    75%{transform: rotate(-30deg);}
    100%{transform: rotate(0deg);}
}
@keyframes parent_move_left{
    0%{
        transform: translate(-65%, 0);
        opacity: 1;
    }
    17%{
        transform: translate(-50%, 0);
        opacity: 1;
    }
    33%{
        transform: translate(-40%, 0);
    }
    50%{
        transform: translate(-30%, 0);
    }
    67%{
        transform: translate(-20%, 0);
    }
    83%{
        transform: translate(-10%, 0);
    }
    100%{
        transform: translate(0%, 0);
    }
}
@keyframes parent_move_right{
    0%{
        transform: translate(65%, 0);
        opacity: 1;
    }
    17%{
        transform: translate(50%, 0);
        opacity: 1;
    }
    33%{
        transform: translate(40%, 0);
    }
    50%{
        transform: translate(30%, 0);
    }
    67%{
        transform: translate(20%, 0);
    }
    83%{
        transform: translate(10%, 0);
    }
    100%{
        transform: translate(0%, 0);
    }
}
