.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-nomijump{
        position: relative;
        display: inline-block;
        .spacing{opacity: 0;}
        &__dog{
            display: inline-block;
            width: 23.4%;
            position: absolute;
            top: 13.8%;
            left: 0.4%;
        }
        &__nomi{
            display: inline-block;
            width: 15.6%;
            position: absolute;
            top: 0%;
            left: 28.4%;
            opacity: 0;
            &.on{
                opacity: 1;
                animation: nomijump_nomi_X 3s ease;
            }
            &-wrap.on{
                display: block;
                animation: nomijump_nomi_Y 3s ease;
            }
            &-bound.on{
                display: block;
                animation: nomijump_nomi_bound 3s ease;
            }
        }
        &__line{
            display: inline-block;
            width: 49.9%;
            height: 75%;
            position: absolute;
            top: 21.7%;
            right: 0.4%;
            text-align: right;
            overflow: hidden;
            opacity: 0;
            &.on{
                opacity: 1;
                animation: nomijump_nomi_line 3s ease;
            }
            &-wrap{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                &.on{animation: nomijump_nomi_line-img 3s ease;}
            }
        }
    }
}
@keyframes nomijump_nomi_X{
    0%{transform: translateX(450%);}
    25%{transform: translateX(270%);}
    30%{transform: translateX(270%);}
    70%{transform: translateX(0%);}
}
@keyframes nomijump_nomi_Y{
    0%{transform: translateY(10%);}
    30%{transform: translateY(150%);}
    55%{transform: translateY(0);}
}
@keyframes nomijump_nomi_bound{
    0%{transform: scale(1, 1);}
    20%{transform: scale(1, 1);}
    30%{transform: scale(1.2, 0.8);}
    40%{transform: scale(0.8, 1.2);}
    70%{transform: scale(1, 1);}
}
@keyframes nomijump_nomi_line{
    0%{transform: translateX(49.9*2+%);}
    25%{transform: translateX(49.9*2*0.608+%);}
    30%{transform: translateX(49.9*2*0.608+%);}
    70%{transform: translateX(0);}
}
@keyframes nomijump_nomi_line-img{
    0%{transform: translateX(-100%);}
    25%{transform: translateX(-61%);}
    30%{transform: translateX(-61%);}
    70%{transform: translateX(0);}
}
