.note{
    /* mixin */
    @mixin note{
        text-align: justify;
        font-size: 16/$baseSize+em;
        font-weight: normal;
        line-height: 1.6;
    }
    &-hospital{
        @include note;
    }
}
