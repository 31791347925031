.footer{
    margin: 1em auto 0;
    background-color: #fff;
    letter-spacing: normal;
    .index+&{margin-top: 0;}
    &__bnr{
        color: #464646;
        font-weight: normal;
        padding: 50/$baseSize+em 10px;
        background-color: #f2f2f2;
        background-image: url(../img/ft_bnr_bg.gif);
        background-repeat: repeat;
        ul{
            max-width: 715px;
            margin: auto;
            &:after{content:"";clear:both;display:block;}
        }
        /* mixin */
        @mixin bnr_li{
            float: left;
            margin-bottom: 23/$baseSize+em;
            span{
                font-size: 13/$baseSize+em;
                display: block;
                margin-bottom: 0.5em;
            }
            a{
                display: inline-block;
                padding: 3px;
                background-color: #fff;
                box-shadow:0px 0px 3px 1px rgba(0,0,0,.2);
                transition: opacity 200ms ease;
                &:hover{opacity: .8;}
            }
            @include query(745){
                float: none;
                span{font-size: 17/$baseSize+em;}
            }
            @include query(500){
                img{width: 100%;}
            }
        }
        &-2row{
            @include bnr_li;
            &-left{
                @include bnr_li;
                margin-right: 23/$baseSize+em;
                @include query(745){margin-right: 0;}
            }
        }
        &-1row{
            @include bnr_li;
            float: none;
            span{font-size: 14/$baseSize+em;}
            @include query(745){
                span{font-size: 18/$baseSize+em;}
            }
        }
    }
    &__bottom{
        color: #464646;
        font-size: 12/$baseSize+em;
        font-weight: normal;
        line-height: 1.4;
        padding: 50/$baseSize+em 37/$baseSize+em;
        &_info{
            max-width: 1000px;
            margin: auto;
            text-align: left;
            position: relative;
            &:after{content:"";clear:both;display:block;}
            &_logo{
                float: left;
                margin-right: 25/$baseSize+em;
                @include query(880){
                    float: none;
                    margin: 0 auto 2em;
                    text-align: center;
                }
            }
            &_link{
                float: left;
                margin-top: 12/$baseSize+em;
                font-size: 0;
                &:after{content:"";clear:both;display:block;}
                @include query(880){
                    float: none;
                    text-align: center;
                    margin: 0 auto;
                }
                li{
                    display: inline-block;
                    font-size: 10px;
                    line-height: 1;
                    &~li{
                        margin-left: 1em;
                        padding-left: 1em;
                        border-left: 1px solid #464646;
                        @include query(400){
                            margin-left: 0;
                            padding-left: 0;
                            border-left: none;
                        }
                    }
                    a{color: #464646;}
                    @include query(400){
                        display: block;
                        margin-bottom: 1em;
                    }
                }
            }
            &_copyright{
                float: right;
                width: 440/1000*100+%;
                @include query(880){
                    float: none;
                    width: 100%;
                    margin-top: 2em;
                    padding-top: 2em;
                    border-top: 1px dotted #BDBDBD;
                }
            }
        }
    }
}
