.map-search{
    margin-bottom: 60/$baseSize+em;
    /* mixin */
    @mixin __head{
        font-size: 25/$baseSize+em;
        line-height: 1.2;
        color: #fff;
        padding: 1em;
        background-color: $keyColor_orng;
        border-radius: 1em 1em 0 0;
        width: 465/1000*100+%;
        margin: 70/$baseSize+em auto 0;
        @include query(768){width: 70%;}
        @include query(560){border-radius: 0.75em 0.75em 0 0;}
    }
    @mixin __area{
        padding: 3em;
        background-color: #fff;
        border-radius: 2.5em;
        position: relative;
    }
    &__head{
        @include __head;
        @include query(768){
            width: 100%;
            border-radius: .75em;
        }
    }
    &__area{
        @include __area;
        padding: 115/1000*100+% 0 170/1000*100+%;
        @include query(768){
            background-color: transparent;
            padding-bottom: 0;
            img{display: none;}
        }
        &_wrap{
            position: relative;
            width: 744/1000*100+%;
            margin: auto;

        }
        &_map-line{
            position: absolute;
            top: 0;
            left: 0;
        }
        &_map-btns{
            li{
                display: inline-block;
                width: 215/744*100+%;
                height: 70/588*100+%;
                position: absolute;
                @include query(768){
                    position: relative;
                    width: 100%;
                    top: auto;
                    left: auto;
                    margin-bottom: 1em;
                }
            }
            &-hkd-thk{ //北海道・東北
                top: 3%;
                left: 42.2%;
            }
            &-knt{ //関東
                top: 99.7%;
                left: 76%;
            }
            &-chb{ //中部
                top: 23.6%;
                left: 35.5%;
            }
            &-kns{ //関西
                top: 95.7%;
                left: 34.5%;
            }
            &-ch-sk{ //中国・四国
                top: 30.7%;
                left: 1%;
            }
            &-ky-okw{ //九州・沖縄
                top: 99.7%;
                left: -5%;
            }
        }
    }
}
