#medicinemodal_wrap{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    transition: all 200ms ease;
    display: none;
    &.bg{background-color: rgba(0,0,0,.5);}
}
.medicinemodal{
    @mixin __window{
        width: 95%;
        max-width: 940px;
        padding: 5em 4em 4em;
        background-color: #fff;
        border-radius: 2.5em;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 4;
        transform: translate(-50%, -50%);
        display: none;
        @include query(1060){width: 70%;}
        @include query(768){padding: 4em 3em 3em;}
        @include query(560){
            padding: 4em 2em 3em;
            border-radius: 1.5em;
        }
    }
    &__window{
        @include __window;
        &_ph{margin: 0 auto 1.5em;}
        &_txt{
            margin: 0 auto 1.25em;
            line-height: 1;
            font-size: 30/$baseSize+em;
            color: $keyColor_navy;
            @include query(560){font-size: 24/$baseSize+em;}
        }
        &_close{
            display: block;
            width: 38px;
            height: 38px;
            background-color: $keyColor_orng;
            border-radius: 25%;
            position: absolute;
            top: 5%;
            right: 4%;
            cursor: pointer;
            img{@include img_center;}
            @include query(1060){
                top: 4%;
                width: 32px;
                height: 32px;
            }
            @include query(768){
                width: 28px;
                height: 28px;
            }
            @include query(560){
                width: 24px;
                height: 24px;
            }
            @include query(375){
                top: 5%;
                right: 5%;
                width: 20px;
                height: 20px;
            }
        }
    }
}
