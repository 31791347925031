.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-daniwhere{
        position: relative;
        margin: 0 auto 140/1000*100+%;
        line-height: 1;
        @include query(768){display: none;}
        &__base{
            display: inline-block;
            width: 100%;
        }
        &__balloon{
            li{
                display: inline-block;
                position: absolute;
                width: 18.6%;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.600), margin-top 200ms ease 500ms;
                transform-origin: bottom center;
                &:nth-of-type(1){
                    top: 26.8%;
                    left: 8%;
                }
                &:nth-of-type(2){
                    top: 32%;
                    left: 33.9%;
                }
                &:nth-of-type(3){
                    top: 53.4%;
                    left: 58.8%;
                }
                &:nth-of-type(4){
                    width: 16.1%;
                    top: 24.5%;
                    left: 81.2%;
                }
                &.on{transform: scale(1);}
            }
        }
    }
    &-daniwhere-sp{
        max-width: 644px;
        position: relative;
        margin: 0 auto 60/1000*100+%;
        line-height: 1;
        display: none;
        @include query(768){display: block;}
        &__base{
            display: inline-block;
            width: 100%;
        }
        &__balloon_num{
            li{
                display: inline-block;
                position: absolute;
                width: 5.3%;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.600), margin-top 200ms ease 500ms;
                transform-origin: bottom center;
                &:nth-of-type(1){
                    top: 53.4%;
                    left: 18.2%;
                }
                &:nth-of-type(2){
                    top: 43.1%;
                    left: 40.8%;
                }
                &:nth-of-type(3){
                    top: 63.5%;
                    left: 65.5%;
                }
                &:nth-of-type(4){
                    top: 25%;
                    left: 86.9%;
                }
                &.on{transform: scale(1);}
            }
        }
        &__balloon{
            max-width: 644px;
            margin: 0 auto 80/1000*100+%;
            display: none;
            @include query(768){display: block;}
            &:after{content:"";clear:both;display:block;}
            li{
                float: left;
                width: 328/644*100+%;
                margin: 0 8/644*100+% 8/644*100+% 0;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.200), margin-top 200ms ease 500ms;
                img{width: 100%;}
                &:nth-of-type(2n){
                    width: 308/644*100+%;
                    margin-right: 0;
                }
                &.on{transform: scale(1);}
            }
        }
    }
}
