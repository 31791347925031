.conversion{
    color: #fff;
    letter-spacing: 0.125em;
    padding: 103/$baseSize+em 20px;
    background-color: $keyColor_navy;
    dt{
        font-size: 35/$baseSize+em;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: 0.75em;
        span{display: inline-block;}
    }
    &__txt{
        font-size: 16/$baseSize+em;
        font-weight: normal;
        margin-bottom: 16/$baseSize*2+em;
    }
}
