.formArea{
    /* mixin */
    @mixin formArea{
        color: #464646;
        *{color: inherit;}
        button{
            color: #fff;
            text-align: center;
        }
    }
    &-login{
        @include formArea;
        max-width: 430px;
        padding-top: 2.5em;
        margin: 0 auto 2.5em;
        position: relative;
        &.err{
            .formArea__err{display: block;}
        }
    }
    &-thanx{
        @include formArea;
        &_txt{
            &-large{
                font-size: 32/$baseSize+em;
                margin: 0 auto 1.5em;
                span{display: inline-block;}
            }
            &-small{
                font-size: 20/$baseSize+em;
                font-weight: normal;
                margin: 0 auto 7em;
                span{display: inline-block;}
                @include query(560){margin: 0 auto 5em;}
            }
        }

    }
    /* mixin */
    @mixin __item{
        @include formArea;
        text-align: left;
        margin-top: 1em;
        padding-top: 2.5em;
        position: relative;
        @include query(768){
            float: none;
            width: 100%;
        }
        &.err{
            .formArea__err{display: block;}
        }
    }
    @mixin require{
        // padding-top: 2.5em;
        >dt:after{
            content: "必須";
            font-size: 13/$baseSize+em;
            letter-spacing: 0;
            line-height: 1;
            color: #fff;
            background-color: $keyColor_orng;
            padding: 0.3em 0.8em;
            border-radius: 0.4em;
            margin-left: 1em;
        }
    }
    &__item{
        @include __item;
        &.require{@include require;}
        &-first{
            @include __item;
            margin-top: 0;
            &.require{@include require;}
        }
        &-fileup{
            @include __item;
            &.require{@include require;}
            @include query(768){display: none;}
        }
        &-half{
            @include __item;
            width: 47.5%;
            float: left;
            margin-right: 5%;
            &.require{@include require;}
            &-nomargin{
                @include __item;
                width: 47.5%;
                float: left;
                &.require{@include require;}
            }
        }
        &-quarter{
            @include __item;
            width: 21.25%;
            float: left;
            margin-right: 5%;
            @include query(768){width: 75%;}
            &.require{
                @include require;
                dt:after{
                    margin-left: 0.5em;
                    padding: .3em .5em;
                    @include query(768){
                        padding: 0.3em 0.8em;
                        margin-left: 1em;
                    }
                }
            }
            &-nomargin{
                @include __item;
                width: 21.25%;
                float: left;
                @include query(768){width: 75%;}
                &.require{
                    @include require;
                    dt:after{
                        margin-left: 0.5em;
                        padding: .3em .5em;
                        @include query(768){
                            padding: 0.3em 0.8em;
                            margin-left: 1em;
                        }
                    }
                }
            }
        }
    }
    /* mixin */
    @mixin __wrap{
        display: block;
        text-align: left;
        margin-bottom: 60px;
        @include query(768){margin-bottom: 40px;}
        @include query(560){margin-bottom: 30px;}
        @include query(375){margin-bottom: 20px;}
    }
    &__wrap{
        @include __wrap;
        &-login{
            @include __wrap;
            font-size: 0;
        }
    }
    /* mixin */
    @mixin __head{
        font-size: 22/$baseSize+em;
        line-height: 1;
        letter-spacing: 0;
        vertical-align: middle;
        margin-bottom: 1em;
        padding-bottom: 0.5em;
        border-bottom: 0.2em dotted #7d7d7d;
        small{
            font-weight: normal;
            letter-spacing: 0;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 30/$baseSize+em;
            height: 30/$baseSize+em;
            background-image: url(../svg/form_head.svg);
            background-size: contain;
            margin-right: 0.5em;
        }
    }
    &__head{
        @include __head;
        @include query(560){border-bottom: 0.15em dotted #7d7d7d;}
        &-noline{
            @include __head;
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    /* mixin */
    @mixin __txt{
        font-weight: normal;
        line-height: 1.5;
        font-size: 18/$baseSize+em;
        margin: auto;
    }
    &__txt{
        @include __txt;
        &-withunit{
            @include __txt;
            float: left;
            width: 80%;
        }
    }
    /* mixin */
    @mixin __label{
        font-size: 22px;
        small{font-size: 16/22+em;}
        @include query(768){font-size: 18px;}
        @include query(560){font-size: 15px;}
        @include query(375){font-size: 13px;}
    }
    &__label{
        @include __label;
        &-login{
            @include __label;
            display: inline-block;
            width: 14%;
            padding-left: 0.5em;
            letter-spacing: 0;
        }
        &-addrhospital{
            @include __label;
            display: inline-block;
            width: 15%;
            padding: .7em 0;
            margin-top: 1.5em;
            line-height: 1;
            @include query(450){width: 20%;}
            @include query(375){letter-spacing: 0;}
        }
    }
    /* mixin */
    @mixin __input{
        font-size: 22px;
        appearance: none;
        width: 100%;
        border: 2px solid #7d7d7d;
        border-radius: 10px;
        padding: 0.5em;
        line-height: 1;
        font-size: $baseSize+px;
        font-family: inherit;
        font-weight: bold;
        color: #464646;
        outline: none;
        &:focus{box-shadow:0px 0px 5px 1px $keyColor_orng;}
        @include query(768){font-size: 18px;}
        @include query(560){
            font-size: 15px;
            border-radius: 5px;
            border: 1px solid #7d7d7d;
        }
        @include query(375){font-size: 12px;}
        &[type="date"]{
            font-family: inherit;
            font-weight: bold;
            color: #464646;
            background-color: #fff;
            padding: 0.75em 0.5em;
        }
    }
    &__input{
        @include __input;
        &-withunit{
            @include __input;
            width: 80%;
        }
        &-login{
            @include __input;
            display: inline-block;
            width: 85%;
        }
        &-unit{
            width: 15%;
            padding-left: 4%;
            font-size: 18/$baseSize;
            vertical-align: bottom;
            letter-spacing: 0;
        }
        &-pos1{
            @include __input;
            width: 12.5%;
            @include query(768){width: 4em;}
        }
        &-pos2{
            @include __input;
            width: 20%;
            @include query(768){width: 5em;}
        }
        &-addrhospital{
            @include __input;
            width: 84.5%;
            margin-top: 1.5em;
            @include query(450){width: 79%;}
        }
    }
    &__notice{
      color: #ff2458;
      font-size: 16px;
      font-weight: bold;
      float: right;
      margin: 0 !important;
      display: block;
    }
    &__textarea{
        &-wrap{
            border: 2px solid #7d7d7d;
            border-radius: 10px;
            padding: 0.5em 0.5em 0.5em 1em;
            &.focus{box-shadow:0px 0px 5px 1px $keyColor_orng;}
            @include query(560){border: 1px solid #7d7d7d;}
        }
        width: 100%;
        color: #464646;
        outline: none;
        border: none;
        font-size: 16/$baseSize+em;
        resize: none;
        @include query(768){font-size: 18/$baseSize+em;}
        /*スクロールバー全体*/
        &::-webkit-scrollbar {
            width: 5px;
            margin-right: 5px;
        }
        /*スクロールバーの軌道*/
        &::-webkit-scrollbar-track {border-radius: 5px;}
        /*スクロールバーの動く部分*/
        &::-webkit-scrollbar-thumb {
            background-color: $keyColor_orng;
            border-radius: 5px;
        }

    }
    /* __check */
    @mixin __check{
        vertical-align: middle;
        ul:after{content:"";clear:both;display:block;}
        input{display: none;}
        label{
            display: block;
            float: left;
            text-align: left;
            // width: 100/4+%;
            line-height: 1;
            vertical-align: middle;
            padding-right: 2em;
            padding-left: 2.25em;
            text-indent: -2.25em;
            margin-bottom: 1em;
            cursor: pointer;
            position: relative;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            tap-highlight-color: rgba(0, 0, 0, 0);
            &.hover{color: $keyColor_orng;}
            @include query(768){
                width: 100/3+%;
                padding-right: 0;
            }
            @include query(560){
                width: 100/2+%;
                margin-bottom: 1.5em;
                padding-left: 1.75em;
                text-indent: -1.75em;
                padding-right: 0.25em;
            }
            @include query(450){
                &.parasiteLv-high{width: 100%;}
            }
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid #7d7d7d;
                border-radius: 3px;
                margin-right: 1em;
                @include query(768){border: 2px solid #7d7d7d;}
                @include query(560){margin-right: 0.5em;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.75em;
                height: 0.5em;
                border-bottom: 3px solid $keyColor_orng;
                border-left: 3px solid $keyColor_orng;
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(34%, 45%) rotate(-45deg) scale(0);
                @include query(768){
                    border-bottom: 2px solid $keyColor_orng;
                    border-left: 2px solid $keyColor_orng;
                }
            }
        }
        input[type=checkbox]+label{
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid #7d7d7d;
                border-radius: 3px;
                margin: -0.15em 0.5em 0 0;
                @include query(768){border: 2px solid #7d7d7d;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.75em;
                height: 0.5em;
                border-bottom: 3px solid $keyColor_orng;
                border-left: 3px solid $keyColor_orng;
                position: absolute;
                left: 0;
                top: 0;
                margin-top: -0.1em;
                transform: translate(34%, 45%) rotate(-45deg) scale(0);
                @include query(768){
                    border-bottom: 2px solid $keyColor_orng;
                    border-left: 2px solid $keyColor_orng;
                }
            }
        }
        input[type=radio]+label{
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid #7d7d7d;
                border-radius: 50%;
                margin: -0.15em 0.5em 0 0;
                @include query(768){border: 2px solid #7d7d7d;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.6em;
                height: 0.6em;
                background-color: $keyColor_orng;
                border-radius: 50%;
                position: absolute;
                left: 1.25/2+em;
                top: 1.25/2+em;
                margin-top: -0.1em;
                border: none;
                transform: translate(-0.3em, -0.3em) scale(0);
            }
        }
        input:checked + label{
            color: $keyColor_orng;
        }
        input[type=checkbox]:checked + label:after{
            transform: translate(34%, 45%) rotate(-45deg) scale(1);
        }
        input[type=radio]:checked + label:after{
            transform: translate(-0.3em, -0.3em) scale(1);
        }
        input[type=checkbox][disabled] + label{
            cursor: auto;
            opacity: .5;
        }
        input[type=checkbox][disabled]:checked + label{
            cursor: auto;
            color:inherit;
            opacity: 1;
        }
    }
    &__check{
        @include __check;
        &-col3{
            @include __check;
            @include query(560){
                label{width: 100/3+%;}
            }
        }
        &-fig-sp{
            @include __check;
            padding-left: 1em;
            @include query(700){
                label{
                    width: 50%;
                    margin-bottom: 1.5em;
                    padding-left: 1.75em;
                    text-indent: -1.75em;
                    padding-right: .25em;
                }
            }
            @include query(450){
                padding-left: 0;
                font-size: 18/$baseSize+em;
            }
            @include query(375){
                letter-spacing: 0;
            }
            &:after{content:"";clear:both;display:block;}
        }
        &-fig-pc{
            @include __check;
            @include query(768){display: none;}
            /* mixin */
            @mixin check_fig_pc{
                position: absolute;
                top: 0;
                left: 0;
                line-height: 1;
                letter-spacing: 0;
                display: inline-block;
                label{
                    &:before{
                        width: 1em;
                        height: 1em;
                        border: 2px solid #7d7d7d;
                        background-color: #fff;
                    }
                    padding-right: 0;
                    margin-bottom: 0;
                    font-size: 18px;
                    $fs-max: 18;
                    $mq-max: 1020;
                    $mq-min: 769 + 31;
                    $step: 5;
                    @for $i from 1 through $step{
                        @include query($mq-max - (($i - 1)*31)){
                            font-size: $fs-max - ($i - 1) + px;
                        }
                    }
                }
            }
            li:nth-child(1){ //耳の内側 (右)
                @include check_fig_pc;
                width: 23%;
                top: 8.3%;
                left: 10.6%;
            }
            li:nth-child(2){ //耳の内側 (左)
                @include check_fig_pc;
                width: 23%;
                top: 8.3%;
                left: 36.6%;
            }
            li:nth-child(3){ //おでこ
                @include check_fig_pc;
                width: 14%;
                top: 22.8%;
                left: 45%;
            }
            li:nth-child(4){ //右目周り
                @include check_fig_pc;
                width: 16%;
                top: 20.3%;
                left: 5%;
            }
            li:nth-child(5){ //左目周り
                @include check_fig_pc;
                width: 16%;
                top: 40.5%;
                left: 42.5%;
            }
            li:nth-child(6){ //鼻・口の周り
                @include check_fig_pc;
                width: 19%;
                top: 44.1%;
                left: 3.2%;
            }
            li:nth-child(7){ //右前足
                @include check_fig_pc;
                width: 13%;
                top: 64.6%;
                left: 3.9%;
            }
            li:nth-child(8){ //左前足
                @include check_fig_pc;
                width: 13%;
                top: 65.3%;
                left: 41.3%;
            }
            li:nth-child(9){ //お腹周り
                @include check_fig_pc;
                width: 16%;
                top: 75.3%;
                left: 42.7%;
            }
            li:nth-child(10){ //右後足
                @include check_fig_pc;
                width: 13%;
                top: 87.3%;
                left: 5.3%;
            }
            li:nth-child(11){ //左後足
                @include check_fig_pc;
                width: 13%;
                top: 86.3%;
                left: 41.4%;
            }
            li:nth-child(12){ //左耳
                @include check_fig_pc;
                width: 11%;
                top: 18%;
                left: 61.9%;
            }
            li:nth-child(13){ //右耳
                @include check_fig_pc;
                width: 11%;
                top: 42.3%;
                left: 81.4%;
            }
            li:nth-child(14){ //背中から首回り
                @include check_fig_pc;
                width: 23%;
                top: 65.7%;
                left: 76.2%;
            }
            li:nth-child(15){ //お尻・シッポ
                @include check_fig_pc;
                width: 20%;
                top: 85.7%;
                left: 77.2%;
            }
        }
    }
    &__fig{
        &-pc{
            position: relative;
            &_base{
                width: 100%;
                @include query(768){display: none;}
            }
        }
        &-sp{
            display: none;
            @include query(768){display: block;}
            &_base{
                width: 100%;
                margin-bottom: 1.5em;
            }
        }
    }
    /* mixin */
    @mixin select{
        select{
            width: 100%;
            outline:none;
            line-height: 1;
            font-family : inherit;
            color: #464646;
            font-weight: bold;
            background: none transparent;
            vertical-align: middle;
            font-size: 14/$baseSize+em;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 0.75em;
            border: 2px solid #7d7d7d;
            border-radius: 0.5em;
            &:focus{box-shadow:0px 0px 5px 1px $keyColor_orng;}
            @include query(768){
                font-size: 18/$baseSize+em;
                padding: 0.75em;
            }
            @include query(560){border: 1px solid #7d7d7d;}
        }
        select option{
            background-color: #fff;
            color: #333;
        }
        select::-ms-expand {
            display: none;
        }
        select:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #828c9a;
        }
    }
    /* mixin */
    @mixin select-wrap{
        position: relative;
        &:after{
            content: "";
            display: block;
            width: 5%;
            height: 55%;
            background-image: url(../svg/form_select_arrow.svg);
            background-size: contain;
            position: absolute;
            top: 50%;
            right: 3%;
            transform: translate(0,-50%);
            @include query(768){right: 1%;}
        }
    }
    &__select{
        @include select;
        &-wrap{
            @include select-wrap;
            width: 100%;
        }
        &-addrhospital{
            @include select;
            width: 30%;
            display: inline-block;
            margin-left: 2.5%;
            @include query(840){
                display: block;
                margin-left: 0;
                width: 75%;
                margin-top: 1.25em;
                select{
                    font-size: 18/$baseSize+em;
                    padding: 0.75em;
                }
            }
            &-wrap{
                @include select-wrap;
                width: 100%;
                &:after{
                    @include query(840){
                        width: 1em;
                        right: 2%;
                    }
                }
            }
        }
        &-col3{
            @include select;
            &:after{content:"";clear:both;display:block;}
            &-wrap{
                @include select-wrap;
                width: 31.3%;
                float: left;
                margin-right: 3%;
                &:nth-of-type(3n){margin-right: 0;}
                @include query(768){
                    width: 75%;
                    float: none;
                    margin-right: 0;
                    margin-bottom: 1em;
                }
            }
        }
    }
    &__err{
        text-align: left;
        line-height: 1;
        letter-spacing: 0;
        padding: 0.5em 1em;
        font-size: 16/$baseSize+em;
        color: #fff;
        background-color: #ff2458;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        &:after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 7px 0 7px;
            border-color: #ff2457 transparent transparent transparent;
            position: absolute;
            bottom: -12px;
            left: 0.6em;
        }
    }
}

.forget{
    margin: 1em auto 2em;
    display: inline-block;
    text-align: justify;
    color: #464646;
    line-height: 1.5;
    font-size: 16/$baseSize+em;
    font-weight: normal;
    .sp{display: none;}
    @include query(560){
        margin: 1em auto 0;
        letter-spacing: 0;
        br{display: none;}
        .sp{display: inline;}
    }
}
