.selectedArea{
    display: table;
    width: 100%;
    margin: 0 auto 6em;
    @include query(768){margin: 0 auto 2em;}
    dt{
        display: table-cell;
        background-color: $keyColor_orng;
        color: #fff;
        width: 30%;
        padding: 2em;
        border-radius: 2em 0 0 2em;
        vertical-align: middle;
        @include query(768){
            display: block;
            width: 100%;
            border-radius: 1.5em 1.5em 0 0;
            padding: 1em 2em;
            text-align: left;
            font-size: 1.25em;
            line-height: 1;
        }
    }
    dd{
        display: table-cell;
        background-color: #fff;
        width: 70%;
        padding: 2em 3em;
        border-radius: 0 2em 2em 0;
        vertical-align: middle;
        text-align: left;
        font-feature-settings: normal;
        @include query(768){
            display: block;
            width: 100%;
            border-radius: 0 0 1.5em 1.5em;
            padding: 1em 2em;
            text-align: left;
        }
    }
}
.hospital{
    margin-bottom: 2em;
    &__item{
        display: table;
        line-height: 1.4;
        width: 100%;
        height: 90px;
        padding: 0.75em 3em;
        margin: 0 auto 1.5em;
        background-color: #fff;
        border-radius: 2em;
        text-align: left;
        @include query(768){
            display: block;
            height: auto;
            padding: 0.75em 2em;
            border-radius: 1.5em;
        }
        &_name{
            display: table-cell;
            width: 25%;
            padding: 1em 1em 1em 0;
            vertical-align: middle;
            @include query(768){
                display: block;
                width: 100%;
                margin: auto;
                padding: 0.5em 1em 0.5em 0;
                font-size: 1.25em;
            }
        }
        /* mixin */
        @mixin _address_tel{
            display: table-cell;
            background-size: 2.25em 2.25em;
            background-position: 1.5em center;
            vertical-align: middle;
            @include query(768){
                display: block;
                width: 100%;
                padding: 1em 1em 1em 3em;
                margin: auto;
                background-position: 0 center;
            }
        }
        &_address{
            @include _address_tel;
            width: 40%;
            padding: 1em 1em 1em 4.5em;
            border-left: 3px dotted $keyColor_gray;
            border-right: 3px dotted $keyColor_gray;
            background-image: url(../svg/icon_map.svg);
            @include query(768){
                border-left: none;
                border-right: none;
                border-top: 2px dotted $keyColor_gray;
                border-bottom: 2px dotted $keyColor_gray;
            }
        }
        &_tel{
            @include _address_tel;
            width: 25%;
            padding: 1em 0 1em 4.5em;
            background-image: url(../svg/icon_tel.svg);
        }
    }
}
