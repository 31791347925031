#mapmodal_wrap{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    transition: all 200ms ease;
    display: none;
    &.bg{background-color: rgba(0,0,0,.5);}
}
.mapmodal{
    @mixin __window{
        width: 95%;
        max-width: 950px;
        padding: 130px 167px;
        background-color: #fff;
        border-radius: 2.5em;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 4;
        transform: translate(-50%, -50%);
        &:after{content:"";clear:both;display:block;}
        @include query(1060){
            width: 75%;
            padding: 130/1.5/950*100+% 167/2/950*100+%;
        }
        @include query(768){padding: 10% 10% 10%;}
        @include query(375){
            border-radius: 1.5em;
            padding: 10% 5% 7.5%;
        }
    }
    &__window{
        &-hkd-thk, &-knt, &-chb, &-kns, &-ch-sk, &-ky-okw{
            @include __window;
            display: none;
        }
        &_head{
            font-size: 30/$baseSize+em;
            margin-bottom: 2em;
            @include query(768){
                margin-bottom: 1.5em;
                line-height: 1.4;
                font-size: 26/$baseSize+em;
                padding: 0 1em;
                span{display: inline-block;}
            }
            @include query(375){
                margin-bottom: 1em;
                padding: 0 2em
            }
        }
        &_btn{
            width: 195px;
            height: 85px;
            display: table;
            margin: 0 15px 15px 0;
            float: left;
            &:nth-of-type(3n){margin-right: 0;}
            @include query(1060){
                width: 95/3+%;
                height: 65px;
                margin: 0 2.5% 2.5% 0;
            }
            @include query(768){
                width: 95/2+%;
                height: 45px;
                margin: 0 5% 5% 0;
                &:nth-of-type(3n){margin-right: 5%;}
                &:nth-of-type(2n){margin-right: 0;}
            }
            @include query(375){height: 35px;}
        }
        &_close{
            display: block;
            width: 38px;
            height: 38px;
            background-color: $keyColor_orng;
            border-radius: 25%;
            position: absolute;
            top: 7.5%;
            right: 5%;
            cursor: pointer;
            img{@include img_center;}
            @include query(768){top: 5%;}
            @include query(375){
                top: 5%;
                width: 28px;
                height: 28px;
            }
        }
    }
}
