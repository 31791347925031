.slider{
    &-wrap{
        max-width: 980px;
        margin: auto;
        padding: 0 50px;
        @include query(600){padding: 30px;}
    }
    /* mixin */
    @mixin slider{
        width: 100%;
        margin: auto;
        background-color: #fff;
        border-radius: 2.5em;
        position: relative;
        @include query(600){border-radius: 1.5em;}
    }
    @mixin slick-arrow{
        text-indent: -9999px;
        display: inline-block;
        background-color: $keyColor_orng;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 140px;
        background-position: center center;
        z-index: 2;
        @include query(600){
            width: 30px;
            background-size: 45%;
        }
        &.slick-next{
            background-image: url(../svg/slider_btn_next.svg);
            right: -50px;
            border-radius: 0 2em 2em 0 ;
            @include query(600){
                right: -30px;
                border-radius: 0 1em 1em 0 ;
            }
        }
        &.slick-prev{
            background-image: url(../svg/slider_btn_prev.svg);
            left: -50px;
            border-radius: 2em 0 0 2em ;
            @include query(600){
                left: -30px;
                border-radius: 1em 0 0 1em ;
            }
        }
    }
    @mixin slick-dots{
        position: absolute;
        line-height: 1;
        bottom: -40px;
        left: 0;
        width: 100%;
        text-align: center;
        @include query(600){bottom: -8%;}
        li{
            display: inline-block;
            text-indent: -9999px;
            width: 12px;
            height: 12px;
            margin: 0 22px;
            border-radius: 50%;
            background-color: $keyColor_navy;
            &.slick-active{background-color: $keyColor_orng;}
            @include query(600){
                width: 8px;
                height: 8px;
                margin: 0 5%;
            }
        }
    }
    &-madani{
        @include slider;
        .slick-arrow{
            @include slick-arrow;
        }
        .slick-dots{
            @include slick-dots;
        }
        &__slide{
            padding: 3em 4em;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            outline: none;
            @include query(600){padding: 1.5em;}
            dl:after{content:"";clear:both;display:block;}
            &_head{
                width: 60%;
                margin-bottom: 0.5em;
                float: right;
                color: $keyColor_orng;
                font-size: 24/$baseSize+em;
                @include query(768){
                    float: none;
                    width: 100%;
                    margin-bottom: 0;
                }
            }
            &_txt{
                width: 55%;
                text-align: justify;
                float: right;
                p{margin-bottom: 0.5em;}
                @include query(768){
                    float: none;
                    width: 100%;
                }
                @include query(600){
                    line-height: 1.5;
                    font-size: 18/$baseSize+em;
                }
            }
            &_img{
                width: 45%;
                float: left;
                text-align: center;
                img{
                    display: inline !important;
                    margin-left: -15%;
                    @include query(768){margin-left: 0;}
                }
                @include query(768){
                    float: none;
                    width: 50%;
                    margin: 1.5em auto 2em;
                }
                @include query(600){
                    width: 40%;
                    margin: 0.5em auto 1em;
                }
            }
        }
    }
}
