@charset "UTF-8";
/* font-family */
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}
body {
  font-family:"Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
}
/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

*,
*:before,
*:after{
	margin: 0;
	padding: 0;
	list-style: none;
	background-repeat: no-repeat;
	box-sizing:inherit;
	vertical-align:inherit;
}
html, body {
	height: 100%;
    &.menu-on{
        overflow: hidden;
    }
}
body{;
    text-align: center;
	font-size: $baseSize+px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.1em;
    color: $keyColor_gray;
    font-feature-settings : "palt";
    background-image: url(../img/bg.png);
    background-repeat: repeat;
    background-position: center 50px;
	-webkit-text-size-adjust: 100%;
	vertical-align: baseline;
	box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $keyColor_yel;
    @include query(1060){
        font-size: 16px;
        background-size: 150px;
        background-position: center 50px;
    }
    @include query(768){
        font-size: 14px;
    }
    @include query(560){
        background-position: center 18px;
    }
    @include query(375){
        font-size: 12px;
        background-size: 75px;
        background-position: center 34px;
    }
}
a,a img,button{
	outline: none;
	border: none;
	cursor: pointer;
    text-decoration: none;
    color: $keyColor_gray;
}
img{
  vertical-align: bottom;
  max-width:100%;
  height: auto;
}
a:hover,button:hover{
	outline: none;
}
a:disabled,button:disabled,input:disabled{pointer-events:none;}
