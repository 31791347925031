.header{
    background-color: #fff;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 100%;
        height: 32px;
        background-image: url(../img/head_bg.png);
        background-repeat: repeat-x;
        background-position: center center;
        position: absolute;
        left: 0;
        bottom: -32px;
        @include query(1060){
            background-size: 150px;
            height: 28px;
            bottom: -25px;
        }
        @include query(375){
            background-size: 75px;
            height: 15px;
            bottom: -13px;
        }
    }
    &__logo{
        text-align: left;
        max-width: 1000px;
        margin: auto;
        padding: 50px 0;
        position: relative;
        z-index: 1;
        @include query(1060){
            padding: 40px;
            img{width: 280px;}
        }
        @include query(560){
            padding: 25px;
            img{width: 200px;}
        }
        @include query(375){
            padding: 15px 15px 25px;
            img{width: 150px;}
        }
    }
}
