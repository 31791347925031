.paging{
    /* mixin */
    @mixin paging{
        margin: 8em auto 0;
        overflow: hidden;
        ul{
            float: left;
            left: 50%;
            position: relative;
            &:after{content:"";clear:both;display:block;}
            @include query(990){
                float: none;
                left: auto;
            }
        }
        li{
            float: left;
            left: -50%;
            position: relative;
            margin: 0 40/$baseSize+em;
            @include query(990){
                float: none;
                left: auto;
                margin: 0 40/$baseSize+em 40/$baseSize+em;
            }
        }
    }
    @include paging;
    &-form{
        @include paging;
        position: relative;
        margin: 6em -40/$baseSize+em 2em;
        @include query(560){
            margin: 4em auto 0;
        }
        ul{
            float: none;
            left: auto;
        }
        li{
            float: none;
            left: auto;
            display: inline-block;
            width: 330/870*100+%;
            @include query(768){width: 300/870*100+%;}
            @include query(560){
                width: 50%;
                margin: 0 auto 40/$baseSize+em;
            }
            @include query(470){width: 100%;}
        }
    }
}
