.map-madani{
    height: 750px;
    height: 70vh;
    border-radius: 50px;
    @include query(600){border-radius: 20px;}
    &__login{
        margin: 0 auto;
        line-height: 1.5;
        a{
            color: $keyColor_orng;
            text-decoration: underline;
        }
        .sp{display: none;}
        @include query(420){
            .sp{display: inline;}
        }
    }
}
/* infoBox */
.gmap {
    position: relative;
    &-info{
        font-size: $baseSize+px;
        width: 300px;
        padding: 25px 26px 25px 25px;
        border-radius: 30px;
        background-color: #fff;
        color: $keyColor_gray;
        text-align: justify;
        @include query(600){
            font-size: 15px;
            // width: 95% !important;
            width: 224px !important;
            border-radius: 20px;
        }
        &>img{
            position: absolute !important;
            top: 17px !important;
            right: 20px !important;
            margin: auto !important;

        }
        &:after{
            position: absolute;
            display: block;
            left: 0;
            bottom: -46px;
            width: 100%;
            height: 46px;
            background: url(../svg/map_balloon_tail.svg) no-repeat 0 0;
            background-position: center center;
            content: '';
        }
        &__block-wrap{
            height: auto;
            width: 100%;
            max-height: 300px;
            padding-right: 20px;
            overflow-y: auto;
            @include query(600){
                max-height: 200px;
                padding-right: 10px;
            }
            /*スクロールバー全体*/
            &::-webkit-scrollbar {
                width: 5px;
            }
            /*スクロールバーの軌道*/
            &::-webkit-scrollbar-track {
                border-radius: 5px;
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
            }
            /*スクロールバーの動く部分*/
            &::-webkit-scrollbar-thumb {
                background-color: #b3b3b3;
                border-radius: 5px;
            }
        }
        &__block{
            padding: 0.25em 0 1.25em;
            margin-bottom: 1em;
            border-bottom: 2px dotted $keyColor_gray;
            &:after{content:"";clear:both;display:block;}
            &:last-of-type{
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
            &-innerwrap{
                width: 100%;
                &:after{content:"";clear:both;display:block;}
            }
            &-txt{
                width: 100%;
                &-left{
                    width: 50%;
                    padding-right: 0.5em;
                    float: left;
                }
            }
            &-ph{
                float: right;
                width: 50%;
                padding-left: 0.5em;
            }
            &-fig{
                width: 100%;
                margin-top: 0.5em;
            }
        }
        &__title{
            color: $keyColor_orng;
            font-weight: bold;
            margin: 0 auto 1.5em;
        }
        &__head{
            color: $keyColor_orng;
            font-size: 12/$baseSize+em;
            font-weight: bold;
            margin: 0.5em auto 0;
            line-height: 1.2;
            &:first-of-type{margin: 0 auto;}
        }
        &__txt{
            font-size: 12/$baseSize+em;
            font-weight: bold;
            margin: auto;
        }
        &__ph{
            img{max-width: 100% !important;}
        }
        &__fig{
            /* __fig */
            @mixin __fig{
                margin: auto;
                position: relative;
                img{max-width: 100% !important;}
            }
            &-front{
                @include __fig;
                width: 50%;
                float: left;
            }
            &-back{
                @include __fig;
                width: 50%;
                float: left;
            }
            &-mark{
                /* mixin */
                @mixin -mark{
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: rgba(255,103,31,.4);
                    @include query(600){
                        width: 14px;
                        height: 14px;
                    }
                }
                &-part1{ //耳内側 (右)
                    @include -mark;
                    top: 8.5%;
                    left: 17%;
                }
                &-part2{ //耳内側 (左)
                    @include -mark;
                    top: 8.5%;
                    left: 62%;
                }
                &-part3{ //おでこ
                    @include -mark;
                    top: 18%;
                    left: 40%;
                }
                &-part4{ //右目周り
                    @include -mark;
                    top: 29%;
                    left: 29%;
                }
                &-part5{ //左目周り
                    @include -mark;
                    top: 29%;
                    left: 53%;
                }
                &-part6{ //鼻・口周り
                    @include -mark;
                    top: 39%;
                    left: 40.5%;
                }
                &-part7{ //右前足
                    @include -mark;
                    top: 40%;
                    left: 8%;
                }
                &-part8{ //左前足
                    @include -mark;
                    top: 40%;
                    left: 73%;
                }
                &-part9{ //お腹周り
                    @include -mark;
                    top: 62%;
                    left: 40.5%;
                }
                &-part10{ //右後足
                    @include -mark;
                    top: 80%;
                    left: 28%;
                }
                &-part11{ //左後足
                    @include -mark;
                    top: 80%;
                    left: 53%;
                }
                &-part12{ //左耳
                    @include -mark;
                    top: 8.5%;
                    left: 17%;
                }
                &-part13{ //右耳
                    @include -mark;
                    top: 8.5%;
                    left: 62%;
                }
                &-part14{ //背中から首回り
                    @include -mark;
                    top: 53%;
                    left: 40.5%;
                }
                &-part15{ //お尻・シッポ
                    @include -mark;
                    top: 69%;
                    left: 40.5%;
                }
            }
        }
    }
}
