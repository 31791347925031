.contents{
    max-width: 1000px;
    margin: 0 auto 6em;
    overflow: hidden;
    &-wrap{
        &-map{
            padding: 15px;
            background-color: #fff;
            border-radius: 50px;
            margin-top: 90px;
            @include query(1060){margin-top: 70px;}
            @include query(768){margin-top: 50px;}
            @include query(560){margin-top: 30px;}
            @include query(600){border-radius: 20px;}
        }
    }
    &__lead{
        @include query(560){
            .pc{display: none;}
            text-align: justify;
        }
        &-map{
            @include query(560){
                .pc{display: none;}
            }
        }
    }
    &__txt{
        @include query(560){
            .pc{display: none;}
            text-align: justify;
        }
        &-strong{
            font-size: 1.2em;
            letter-spacing: .2em;
            color: $keyColor_navy;
        }
    }
    &__animation{
        &-guard{
            @include query(1030){width: 640px; height: 240px; animation: guard2 1.2s steps(2) infinite;}
            @include query(700){width: 480px; height: 180px; animation: guard3 1.2s steps(2) infinite;}
            @include query(550){width: 240px; height: 90px; animation: guard4 1.2s steps(2) infinite;}
            width: 960px;
            height: 360px;
            margin: auto;
            background: url(../svg/dog_guard.svg) no-repeat;
            animation: guard 1.2s steps(2) infinite;
            background-position: 0 0;
            background-size: cover;
        }
        &-wash{
            @include query(768){width: 500px; height: 332px; animation: wash2 1.2s steps(2) infinite;}
            @include query(560){width: 260px; height: 172px; animation: wash3 1.2s steps(2) infinite;}
            width: 600px;
            height: 400px;
            margin: auto;
            background: url(../svg/dog_wash.svg) no-repeat;
            animation: wash 1.2s steps(2) infinite;
            background-position: 0 0;
            background-size: cover;
        }
        &-hospital{
            @include query(840){width: 520px; height: 270px; animation: hospital2 1.2s steps(2) infinite;}
            @include query(560){width: 290px; height: 150px; animation: hospital3 1.2s steps(2) infinite;}
            width: 774px;
            height: 400px;
            margin: auto;
            background: url(../svg/dog_hospital.svg) no-repeat;
            animation: hospital 1.2s steps(2) infinite;
            background-position: 0 0;
            background-size: cover;
        }
        &-medicine{
            @include query(768){width: 560px; height: 334px; animation: medicine2 1.2s steps(2) infinite;}
            @include query(560){width: 290px; height: 175px; animation: medicine3 1.2s steps(2) infinite;}
            width: 700px;
            height: 420px;
            margin: auto;
            background: url(../svg/dog_medicine.svg) no-repeat;
            animation: medicine 1.2s steps(2) infinite;
            background-position: 0 0;
            background-size: cover;
        }
    }
    &__bnr{
        transition: opacity 200ms ease;
        &:hover{opacity: .8;}
    }
}
@keyframes guard{
  to {background-position: 0 -720px;}
}
@keyframes guard2{
  to {background-position: 0 -480px;}
}
@keyframes guard3{
  to {background-position: 0 -360px;}
}
@keyframes guard4{
  to {background-position: 0 -180px;}
}
@keyframes wash{
  to {background-position: 0 -800px;}
}
@keyframes wash2{
  to {background-position: 0 -666px;}
}
@keyframes wash3{
  to {background-position: 0 -346px;}
}
@keyframes hospital{
  to {background-position: 0 -800px;}
}
@keyframes hospital2{
  to {background-position: 0 -538px;}
}
@keyframes hospital3{
  to {background-position: 0 -300px;}
}
@keyframes medicine{
  to {background-position: 0 -839px;}
}
@keyframes medicine2{
  to {background-position: 0 -671px;}
}
@keyframes medicine3{
  to {background-position: 0 -349px;}
}
