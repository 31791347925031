.cities{
    /* mixin */
    @mixin __head{
        font-size: 25/$baseSize+em;
        line-height: 1.2;
        color: #fff;
        padding: 1em;
        background-color: $keyColor_orng;
        border-radius: 1em 1em 0 0;
        width: 465/1000*100+%;
        margin: 110/1000*100+% auto 0;
        span{display: inline-block;}
        @include query(768){width: 70%;}
    }
    @mixin __area{
        padding: 7.5%;
        background-color: #fff;
        border-radius: 2.5em;
        position: relative;
        @include query(560){
            padding: 10%;
            border-radius: 1.5em;
        }
    }
    &__head{
        @include __head;
    }
    &__area{
        @include __area;
        @include query(768){font-size: 16px;}
        @include query(560){font-size: 14px;}
    }
    /* mxin */
    @mixin label{
        input{display: none;}
        label{
            display: block;
            float: left;
            text-align: left;
            width: 100/4+%;
            line-height: 1;
            vertical-align: middle;
            padding-right: 1em;
            padding-left: 2.25em;
            text-indent: -2.25em;
            margin-bottom: 1em;
            cursor: pointer;
            position: relative;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            tap-highlight-color: rgba(0, 0, 0, 0);
            &.hover{color: $keyColor_orng;}
            @include query(768){width: 100/3+%;}
            @include query(560){
                width: 100/2+%;
                margin-bottom: 1.5em;
                padding-left: 1.75em;
                text-indent: -1.75em;
            }
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid $keyColor_orng;
                border-radius: 3px;
                margin-right: 1em;
                @include query(768){border: 2px solid $keyColor_orng;}
                @include query(560){margin-right: 0.5em;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.75em;
                height: 0.5em;
                border-bottom: 3px solid $keyColor_gray;
                border-left: 3px solid $keyColor_gray;
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(34%, 45%) rotate(-45deg) scale(0);
                @include query(768){
                    border-bottom: 2px solid $keyColor_gray;
                    border-left: 2px solid $keyColor_gray;
                }
            }
        }
        input[type=checkbox]+label{
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid $keyColor_orng;
                border-radius: 3px;
                margin-right: 1em;
                @include query(768){border: 2px solid $keyColor_orng;}
                @include query(560){margin-right: 0.5em;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.75em;
                height: 0.5em;
                border-bottom: 3px solid $keyColor_gray;
                border-left: 3px solid $keyColor_gray;
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(34%, 45%) rotate(-45deg) scale(0);
                @include query(768){
                    border-bottom: 2px solid $keyColor_gray;
                    border-left: 2px solid $keyColor_gray;
                }
            }
        }
        input[type=radio]+label{
            &:before{
                content: "";
                display: inline-block;
                width: 1.25em;
                height: 1.25em;
                border: 3px solid $keyColor_orng;
                border-radius: 50%;
                margin-right: 1em;
                @include query(768){border: 2px solid $keyColor_orng;}
                @include query(560){margin-right: 0.5em;}
            }
            &:after{
                content: "";
                display: block;
                width: 0.6em;
                height: 0.6em;
                background-color: $keyColor_gray;
                border-radius: 50%;
                position: absolute;
                left: 1.25/2+em;
                top: 1.25/2+em;
                border: none;
                transform: translate(-0.3em, -0.3em) scale(0);
            }
        }
        .disable{
            color: #c9c9c9 !important;
            &:before{
                display: none !important;
            }
            &:after{
              display: none !important;
            }
        }

        input:checked + label{
            color: $keyColor_orng;
        }
        input[type=checkbox]:checked + label:after{
            transform: translate(34%, 45%) rotate(-45deg) scale(1);
        }
        input[type=radio]:checked + label:after{
            transform: translate(-0.3em, -0.3em) scale(1);
        }
    }
    &__note{
        text-align: right;
        font-size: 15/$baseSize+em;
        margin-bottom: 1em;
    }
    &__allCheck{
        margin-bottom: 1em;
        @include label;
        input[type=checkbox]+label{
            float: none;
            @include query(560){width: 75%;}
        }
    }
    &__areaDetail{
        &:after{content:"";clear:both;display:block;}
        li{
            @include label;
            display: inline;
        }
    }
}
