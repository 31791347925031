#lifemodal_wrap{
    /* mixin */
    @mixin lifemodal_wrap{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        transition: all 200ms ease;
        display: none;
        &.bg{background-color: rgba(0,0,0,.5);}
    }
    @include lifemodal_wrap;
}
.lifemodal{
    @mixin __window{
        width: 95%;
        max-width: 575px;
        padding: 4em;
        background-color: #fff;
        border-radius: 2.5em;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 4;
        transform: translate(-50%, -50%);
        &:after{content:"";clear:both;display:block;}
        @include query(1060){width: 55%;}
        @include query(768){
            width: 70%;
            padding: 3em;
        }
        @include query(560){
            padding: 2em;
            border-radius: 1.5em;
        }
    }
    &__window{
        &-seichu, &-sanagi, &-youchu, &-tamago{
            @include __window;
            display: none;
        }
        &-houketsu, &-wakadani{
            @include __window;
            display: none;
        }
        &_head{
            font-size: 34/$baseSize+em;
            margin-bottom: 0.75em;
            line-height: 1;
            color: $keyColor_orng;
            text-align: left;
            padding-left: 40%;
            // width: 60%;
            @include query(560){
                // text-align: left;
                width: 100%;
                float: none;
                text-align: center;
                padding-left: 0;
                margin: 0 auto 1em;
            }
        }
        &_detail{
            display: table;
            vertical-align: middle;
        }
        &_txt{
            font-size: 17/$baseSize+em;
            width: 60%;
            text-align: justify;
            float: right;
            @include query(560){
                font-size: 17/$baseSize+em;
                width: 100%;
                float: none;
            }
        }
        &_img{
            width: 35%;
            float: left;
            @include query(768){
                img{max-width: 70%;}
            }
            @include query(560){
                width: 45%;
                margin: 0 auto 1em;
                float: none;
            }
            @include query(375){width: 60%;}
        }
        &_close{
            display: block;
            width: 38px;
            height: 38px;
            background-color: $keyColor_orng;
            border-radius: 25%;
            position: absolute;
            top: 7.5%;
            right: 5%;
            cursor: pointer;
            img{@include img_center;}
            @include query(560){
                top: 5%;
                width: 28px;
                height: 28px;
            }
            @include query(375){
                top: 4%;
                width: 20px;
                height: 20px;
            }
        }
    }
}
