.fig{
    /* mixin */
    @mixin fig{
        max-width: 1000px;
        margin: auto;
    }
    &-nomiwhere{
        position: relative;
        margin: 0 auto 140/1000*100+%;
        line-height: 1;
        @include query(768){display: none;}
        &__base{
            display: inline-block;
            width: 100%;
        }
        &__balloon{
            li{
                display: inline-block;
                position: absolute;
                width: 19.7%;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.600), margin-top 200ms ease 500ms;
                transform-origin: bottom center;
                &:nth-of-type(1){
                    top: 43.8%;
                    left: 13.6%;
                }
                &:nth-of-type(2){
                    top: 44.8%;
                    left: 49.4%;
                }
                &:nth-of-type(3){
                    top: 56.4%;
                    left: 70.8%;
                }
                &:nth-of-type(4){
                    transform-origin: top center;
                    top: 100.5%;
                    left: 17.5%;
                }
                &:nth-of-type(5){
                    transform-origin: top center;
                    top: 100.5%;
                    left: 38.7%;
                }
                &.on{transform: scale(1);}
            }
        }
    }
    &-nomiwhere-sp{
        max-width: 644px;
        position: relative;
        margin: 0 auto 60/1000*100+%;
        line-height: 1;
        display: none;
        @include query(768){display: block;}
        .tmp{
            opacity: .5;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__base{
            display: inline-block;
            width: 100%;
        }
        &__balloon_num{
            li{
                display: inline-block;
                position: absolute;
                width: 5.3%;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.600), margin-top 200ms ease 500ms;
                transform-origin: bottom center;
                &:nth-of-type(1){
                    top: 57.4%;
                    left: 17.9%;
                }
                &:nth-of-type(2){
                    top: 61.1%;
                    left: 31.8%;
                }
                &:nth-of-type(3){
                    top: 79.5%;
                    left: 47.3%;
                }
                &:nth-of-type(4){
                    top: 53%;
                    left: 63.7%;
                }
                &:nth-of-type(5){
                    top: 69.5%;
                    left: 73.4%;
                }
                &.on{transform: scale(1);}
            }
        }
        &__balloon{
            max-width: 644px;
            margin: 0 auto 80/1000*100+%;
            display: none;
            @include query(768){display: block;}
            &:after{content:"";clear:both;display:block;}
            li{
                float: left;
                width: 328/644*100+%;
                margin: 0 8/644*100+% 8/644*100+% 0;
                transform: scale(0);
                transition: transform 500ms cubic-bezier(1.000, 0.000, 0.700, 1.200), margin-top 200ms ease 500ms;
                img{width: 100%;}
                &:nth-of-type(2n){
                    width: 308/644*100+%;
                    margin-right: 0;
                }
                &.on{transform: scale(1);}
            }
        }
    }
}
